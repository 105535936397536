import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { DocumentFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchDocumentQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchDocumentQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | {
              __typename: 'Document';
              id: string;
              workspaceId: string;
              projectId?: string | null;
              name: string;
              createdAt: string;
              updatedAt: string;
              messagedAt?: string | null;
              unreads: number;
              hasMentions: boolean;
          }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Task' }
        | { __typename: 'Topic' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchDocumentsQueryVariables = Types.Exact<{
    filter: Types.IAPIDocumentsFilter;
    sort?: Types.InputMaybe<Array<Types.IAPIDocumentsSort> | Types.IAPIDocumentsSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchDocumentsQuery = {
    __typename: 'Query';
    documents: {
        __typename: 'Documents';
        allIds?: Array<number> | null;
        documents: Array<{
            __typename: 'Document';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        }>;
    };
};

export type IAPICreateDocumentMutationVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    document?: Types.InputMaybe<Types.IAPIDocumentInput>;
}>;

export type IAPICreateDocumentMutation = {
    __typename: 'Mutation';
    createDocument: {
        __typename: 'DocumentMutationResult';
        clientId: string;
        workspaceId: string;
        document: {
            __typename: 'Document';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    };
};

export type IAPIUpdateDocumentMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
    document?: Types.InputMaybe<Types.IAPIDocumentInput>;
}>;

export type IAPIUpdateDocumentMutation = {
    __typename: 'Mutation';
    updateDocument: {
        __typename: 'DocumentMutationResult';
        clientId: string;
        workspaceId: string;
        document: {
            __typename: 'Document';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    };
};

export type IAPIRemoveDocumentMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveDocumentMutation = {
    __typename: 'Mutation';
    removeDocument: {
        __typename: 'DocumentMutationResult';
        clientId: string;
        workspaceId: string;
        document: {
            __typename: 'Document';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    };
};

export type IAPIDocumentCreatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPIDocumentCreatedSubscription = {
    __typename: 'Subscription';
    documentCreated?: {
        __typename: 'DocumentMutationResult';
        clientId: string;
        document: {
            __typename: 'Document';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    } | null;
};

export type IAPIDocumentUpdatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPIDocumentUpdatedSubscription = {
    __typename: 'Subscription';
    documentUpdated?: {
        __typename: 'DocumentMutationResult';
        clientId: string;
        document: {
            __typename: 'Document';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    } | null;
};

export type IAPIDocumentRemovedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPIDocumentRemovedSubscription = {
    __typename: 'Subscription';
    documentRemoved?: {
        __typename: 'DocumentMutationResult';
        clientId: string;
        document: {
            __typename: 'Document';
            id: string;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    } | null;
};

export const FetchDocumentDocument = gql`
    query FetchDocument($id: ID!) {
        node(id: $id) {
            ...DocumentFields
        }
    }
    ${DocumentFieldsFragmentDoc}
`;

/**
 * __useFetchDocumentQuery__
 *
 * To run a query within a React component, call `useFetchDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchDocumentQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchDocumentQuery, IAPIFetchDocumentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchDocumentDocument, options);
    return Apollo.useQuery<IAPIFetchDocumentQuery, IAPIFetchDocumentQueryVariables>(
        FetchDocumentDocument,
        options
    );
}
export function useFetchDocumentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchDocumentQuery,
        IAPIFetchDocumentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchDocumentQuery, IAPIFetchDocumentQueryVariables>(
        FetchDocumentDocument,
        options
    );
}
export type FetchDocumentQueryHookResult = ReturnType<typeof useFetchDocumentQuery>;
export type FetchDocumentLazyQueryHookResult = ReturnType<typeof useFetchDocumentLazyQuery>;
export type FetchDocumentQueryResult = Apollo.QueryResult<
    IAPIFetchDocumentQuery,
    IAPIFetchDocumentQueryVariables
>;
export const FetchDocumentsDocument = gql`
    query FetchDocuments(
        $filter: DocumentsFilter!
        $sort: [DocumentsSort!]
        $pagination: Pagination
    ) {
        documents(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            documents {
                ...DocumentFields
            }
        }
    }
    ${DocumentFieldsFragmentDoc}
`;

/**
 * __useFetchDocumentsQuery__
 *
 * To run a query within a React component, call `useFetchDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDocumentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchDocumentsQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchDocumentsQuery, IAPIFetchDocumentsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchDocumentsDocument, options);
    return Apollo.useQuery<IAPIFetchDocumentsQuery, IAPIFetchDocumentsQueryVariables>(
        FetchDocumentsDocument,
        options
    );
}
export function useFetchDocumentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchDocumentsQuery,
        IAPIFetchDocumentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchDocumentsQuery, IAPIFetchDocumentsQueryVariables>(
        FetchDocumentsDocument,
        options
    );
}
export type FetchDocumentsQueryHookResult = ReturnType<typeof useFetchDocumentsQuery>;
export type FetchDocumentsLazyQueryHookResult = ReturnType<typeof useFetchDocumentsLazyQuery>;
export type FetchDocumentsQueryResult = Apollo.QueryResult<
    IAPIFetchDocumentsQuery,
    IAPIFetchDocumentsQueryVariables
>;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($workspaceId: ID!, $document: DocumentInput) {
        createDocument(workspaceId: $workspaceId, document: $document) {
            clientId
            workspaceId
            document {
                ...DocumentFields
            }
        }
    }
    ${DocumentFieldsFragmentDoc}
`;
export type IAPICreateDocumentMutationFn = Apollo.MutationFunction<
    IAPICreateDocumentMutation,
    IAPICreateDocumentMutationVariables
>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      document: // value for 'document'
 *   },
 * });
 */
export function useCreateDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateDocumentMutation,
        IAPICreateDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateDocumentMutation, IAPICreateDocumentMutationVariables>(
        CreateDocumentDocument,
        options
    );
}
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<IAPICreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateDocumentMutation,
    IAPICreateDocumentMutationVariables
>;
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($id: ID!, $document: DocumentInput) {
        updateDocument(id: $id, document: $document) {
            clientId
            workspaceId
            document {
                ...DocumentFields
            }
        }
    }
    ${DocumentFieldsFragmentDoc}
`;
export type IAPIUpdateDocumentMutationFn = Apollo.MutationFunction<
    IAPIUpdateDocumentMutation,
    IAPIUpdateDocumentMutationVariables
>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      document: // value for 'document'
 *   },
 * });
 */
export function useUpdateDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateDocumentMutation,
        IAPIUpdateDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateDocumentMutation, IAPIUpdateDocumentMutationVariables>(
        UpdateDocumentDocument,
        options
    );
}
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<IAPIUpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateDocumentMutation,
    IAPIUpdateDocumentMutationVariables
>;
export const RemoveDocumentDocument = gql`
    mutation RemoveDocument($id: ID!) {
        removeDocument(id: $id, action: DELETE) {
            clientId
            workspaceId
            document {
                ...DocumentFields
            }
        }
    }
    ${DocumentFieldsFragmentDoc}
`;
export type IAPIRemoveDocumentMutationFn = Apollo.MutationFunction<
    IAPIRemoveDocumentMutation,
    IAPIRemoveDocumentMutationVariables
>;

/**
 * __useRemoveDocumentMutation__
 *
 * To run a mutation, you first call `useRemoveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocumentMutation, { data, loading, error }] = useRemoveDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveDocumentMutation,
        IAPIRemoveDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveDocumentMutation, IAPIRemoveDocumentMutationVariables>(
        RemoveDocumentDocument,
        options
    );
}
export type RemoveDocumentMutationHookResult = ReturnType<typeof useRemoveDocumentMutation>;
export type RemoveDocumentMutationResult = Apollo.MutationResult<IAPIRemoveDocumentMutation>;
export type RemoveDocumentMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveDocumentMutation,
    IAPIRemoveDocumentMutationVariables
>;
export const DocumentCreatedDocument = gql`
    subscription DocumentCreated($workspaceId: ID!) {
        documentCreated(workspaceId: $workspaceId) {
            clientId
            document {
                ...DocumentFields
            }
        }
    }
    ${DocumentFieldsFragmentDoc}
`;

/**
 * __useDocumentCreatedSubscription__
 *
 * To run a query within a React component, call `useDocumentCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDocumentCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentCreatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDocumentCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIDocumentCreatedSubscription,
        IAPIDocumentCreatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(DocumentCreatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIDocumentCreatedSubscription,
            IAPIDocumentCreatedSubscriptionVariables
        >(DocumentCreatedDocument, options),
        connected,
    };
}
export type DocumentCreatedSubscriptionHookResult = ReturnType<
    typeof useDocumentCreatedSubscription
>;
export type DocumentCreatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIDocumentCreatedSubscription>;
export const DocumentUpdatedDocument = gql`
    subscription DocumentUpdated($workspaceId: ID!) {
        documentUpdated(workspaceId: $workspaceId) {
            clientId
            document {
                ...DocumentFields
            }
        }
    }
    ${DocumentFieldsFragmentDoc}
`;

/**
 * __useDocumentUpdatedSubscription__
 *
 * To run a query within a React component, call `useDocumentUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDocumentUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentUpdatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDocumentUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIDocumentUpdatedSubscription,
        IAPIDocumentUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(DocumentUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIDocumentUpdatedSubscription,
            IAPIDocumentUpdatedSubscriptionVariables
        >(DocumentUpdatedDocument, options),
        connected,
    };
}
export type DocumentUpdatedSubscriptionHookResult = ReturnType<
    typeof useDocumentUpdatedSubscription
>;
export type DocumentUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIDocumentUpdatedSubscription>;
export const DocumentRemovedDocument = gql`
    subscription DocumentRemoved($workspaceId: ID!) {
        documentRemoved(workspaceId: $workspaceId) {
            clientId
            document {
                ...DocumentFields
            }
        }
    }
    ${DocumentFieldsFragmentDoc}
`;

/**
 * __useDocumentRemovedSubscription__
 *
 * To run a query within a React component, call `useDocumentRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDocumentRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentRemovedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDocumentRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIDocumentRemovedSubscription,
        IAPIDocumentRemovedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(DocumentRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIDocumentRemovedSubscription,
            IAPIDocumentRemovedSubscriptionVariables
        >(DocumentRemovedDocument, options),
        connected,
    };
}
export type DocumentRemovedSubscriptionHookResult = ReturnType<
    typeof useDocumentRemovedSubscription
>;
export type DocumentRemovedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIDocumentRemovedSubscription>;
