import * as Types from '.';

export interface IDBRowData {
    constructor: {
        name: 'RowDataPacket';
    };
}
export type enum_dsh_type = 'TOPICS' | 'DOCUMENTS' | 'PROJECTS' | 'PEOPLE' | 'TASKS';
export type enum_ep_offer = '$300 for Digital Agencies';
export type enum_ep_source = 'AB-TASK' | 'LinkedHelper';
export type enum_r_name = 'OWNER' | 'ADMIN' | 'MEMBER' | 'AFFILIATE';
export type enum_tl_link_type = 'BLOCKS' | 'RELATES' | 'CAUSES';
export type enum_trn_method = 'CARD' | 'PAYPAL' | 'BALANCE';
export type enum_tsk_priority = 'LOW' | 'MEDIUM' | 'HIGH' | 'CRITICAL';
export type enum_tsk_status =
    | 'TRIAGE'
    | 'UP_NEXT'
    | 'IN_PROGRESS'
    | 'IN_REVIEW'
    | 'DONE'
    | 'CANCELLED';
export type enum_u_status = 'INITIAL' | 'INVITED' | 'REGISTERED' | 'BANNED';
export type enum_ua_status = 'INITIAL' | 'INVITED' | 'REGISTERED' | 'BANNED';

/** Table: access */
export declare namespace accessFields {
    export type a_group_id = number | null;
    export type a_id = number;
    export type a_project_id = number | null;
    export type a_role_id = number;
    export type a_user_id = number | null;
    export type a_workspace_id = number;
    export type aggr_group_user_project = string;
}

export interface IDBAccess extends IDBRowData {
    a_group_id: accessFields.a_group_id;
    a_id: accessFields.a_id;
    a_project_id: accessFields.a_project_id;
    a_role_id: accessFields.a_role_id;
    a_user_id: accessFields.a_user_id;
    a_workspace_id: accessFields.a_workspace_id;
    aggr_group_user_project: accessFields.aggr_group_user_project;
}

/** Table: attachments */
export declare namespace attachmentsFields {
    export type att_content_type = string;
    export type att_created_at = Date;
    export type att_filename = string;
    export type att_message_id = number;
    export type att_s3_key = string;
    export type att_workspace_id = number;
}

export interface IDBAttachment extends IDBRowData {
    att_content_type: attachmentsFields.att_content_type;
    att_created_at: attachmentsFields.att_created_at;
    att_filename: attachmentsFields.att_filename;
    att_message_id: attachmentsFields.att_message_id;
    att_s3_key: attachmentsFields.att_s3_key;
    att_workspace_id: attachmentsFields.att_workspace_id;
}

/** Table: bills */
export declare namespace billsFields {
    export type b_amount_to_charge = number | null;
    export type b_duration = number;
    export type b_id = number;
    export type b_month = number;
    export type b_paddle_modifier_id = number | null;
    export type b_requests_count = number;
    export type b_storage_data_transfer = number;
    export type b_storage_other_requests_count = number;
    export type b_storage_pcpl_requests_count = number;
    export type b_storage_usage = number;
    export type b_total = number;
    export type b_workspace_id = number;
    export type b_year = number;
}

export interface IDBBill extends IDBRowData {
    b_amount_to_charge: billsFields.b_amount_to_charge;
    b_duration: billsFields.b_duration;
    b_id: billsFields.b_id;
    b_month: billsFields.b_month;
    b_paddle_modifier_id: billsFields.b_paddle_modifier_id;
    b_requests_count: billsFields.b_requests_count;
    b_storage_data_transfer: billsFields.b_storage_data_transfer;
    b_storage_other_requests_count: billsFields.b_storage_other_requests_count;
    b_storage_pcpl_requests_count: billsFields.b_storage_pcpl_requests_count;
    b_storage_usage: billsFields.b_storage_usage;
    b_total: billsFields.b_total;
    b_workspace_id: billsFields.b_workspace_id;
    b_year: billsFields.b_year;
}

/** Table: dashboards */
export declare namespace dashboardsFields {
    export type dsh_config = string | null;
    export type dsh_favorite = boolean;
    export type dsh_id = number;
    export type dsh_name = string | null;
    export type dsh_order = number;
    export type dsh_owner_id = number;
    export type dsh_type = enum_dsh_type;
    export type dsh_workspace_id = number;
}

export interface IDBDashboard extends IDBRowData {
    dsh_config: dashboardsFields.dsh_config;
    dsh_favorite: dashboardsFields.dsh_favorite;
    dsh_id: dashboardsFields.dsh_id;
    dsh_name: dashboardsFields.dsh_name;
    dsh_order: dashboardsFields.dsh_order;
    dsh_owner_id: dashboardsFields.dsh_owner_id;
    dsh_type: dashboardsFields.dsh_type;
    dsh_workspace_id: dashboardsFields.dsh_workspace_id;
    dsh_unreads: number | undefined;
    dsh_has_mentions: boolean | undefined;
}

/** Table: documents */
export declare namespace documentsFields {
    export type d_acl = Object | null;
    export type d_created_at = Date;
    export type d_creator_id = number;
    export type d_id = number;
    export type d_name = string;
    export type d_project_id = number | null;
    export type d_updated_at = Date;
    export type d_workspace_id = number;
}

export interface IDBDocument extends IDBRowData {
    d_acl: documentsFields.d_acl;
    d_created_at: documentsFields.d_created_at;
    d_creator_id: documentsFields.d_creator_id;
    d_id: documentsFields.d_id;
    d_name: documentsFields.d_name;
    d_project_id: documentsFields.d_project_id;
    d_updated_at: documentsFields.d_updated_at;
    d_workspace_id: documentsFields.d_workspace_id;
    d_messaged_at: Date | null;
    d_unreads: number;
    d_has_mentions: boolean;
}

/** Table: epics */
export declare namespace epicsFields {
    export type e_created_at = Date;
    export type e_id = number;
    export type e_milestone_id = number | null;
    export type e_name = string;
    export type e_project_id = number;
    export type e_updated_at = Date;
}

export interface IDBEpic extends IDBRowData {
    e_created_at: epicsFields.e_created_at;
    e_id: epicsFields.e_id;
    e_milestone_id: epicsFields.e_milestone_id;
    e_name: epicsFields.e_name;
    e_project_id: epicsFields.e_project_id;
    e_updated_at: epicsFields.e_updated_at;
    workspaceId: number;
}

/** Table: groups */
export declare namespace groupsFields {
    export type g_created_at = Date;
    export type g_description = string | null;
    export type g_id = number;
    export type g_name = string;
    export type g_updated_at = Date;
    export type g_workspace_id = number;
}

export interface IDBGroup extends IDBRowData {
    g_created_at: groupsFields.g_created_at;
    g_description: groupsFields.g_description;
    g_id: groupsFields.g_id;
    g_name: groupsFields.g_name;
    g_updated_at: groupsFields.g_updated_at;
    g_workspace_id: groupsFields.g_workspace_id;
    g_member_ids: string;
    g_messaged_at: Date | null;
    g_unreads: number;
    g_has_mentions: boolean;
}

/** Table: invites */
export declare namespace invitesFields {
    export type i_access_list = Types.IInvite['accessList'] | null;
    export type i_claimed_at = Date | null;
    export type i_code = string;
    export type i_id = number;
    export type i_inviter_id = number | null;
    export type i_user_id = number;
    export type i_valid_until = Date;
    export type i_workspace_id = number | null;
}

export interface IDBInvite extends IDBRowData {
    i_access_list: invitesFields.i_access_list;
    i_claimed_at: invitesFields.i_claimed_at;
    i_code: invitesFields.i_code;
    i_id: invitesFields.i_id;
    i_inviter_id: invitesFields.i_inviter_id;
    i_user_id: invitesFields.i_user_id;
    i_valid_until: invitesFields.i_valid_until;
    i_workspace_id: invitesFields.i_workspace_id;
}

/** Table: messages */
export declare namespace messagesFields {
    export type m_content = string | null;
    export type m_created_at = Date;
    export type m_created_by = number;
    export type m_document_id = number | null;
    export type m_epic_id = number | null;
    export type m_first = boolean;
    export type m_group_id = number | null;
    export type m_id = number;
    export type m_milestone_id = number | null;
    export type m_position = boolean;
    export type m_project_id = number | null;
    export type m_task_id = number | null;
    export type m_topic_id = number | null;
    export type m_updated_at = Date;
    export type m_version = number;
    export type m_workspace_id = number | null;
}

export interface IDBMessage extends IDBRowData {
    m_content: messagesFields.m_content;
    m_created_at: messagesFields.m_created_at;
    m_created_by: messagesFields.m_created_by;
    m_document_id: messagesFields.m_document_id;
    m_epic_id: messagesFields.m_epic_id;
    m_first: messagesFields.m_first;
    m_group_id: messagesFields.m_group_id;
    m_id: messagesFields.m_id;
    m_milestone_id: messagesFields.m_milestone_id;
    m_position: messagesFields.m_position;
    m_project_id: messagesFields.m_project_id;
    m_task_id: messagesFields.m_task_id;
    m_topic_id: messagesFields.m_topic_id;
    m_updated_at: messagesFields.m_updated_at;
    m_version: messagesFields.m_version;
    m_workspace_id: messagesFields.m_workspace_id;
    m_last: boolean;
    m_author_ids: string;
}

/** Table: milestones */
export declare namespace milestonesFields {
    export type ml_id = number;
    export type ml_name = string;
    export type ml_project_id = number;
}

export interface IDBMilestone extends IDBRowData {
    ml_id: milestonesFields.ml_id;
    ml_name: milestonesFields.ml_name;
    ml_project_id: milestonesFields.ml_project_id;
}

/** Table: projects */
export declare namespace projectsFields {
    export type p_acl = Object | null;
    export type p_created_at = Date;
    export type p_creator_id = number;
    export type p_id = number;
    export type p_name = string;
    export type p_updated_at = Date;
    export type p_workspace_id = number;
}

export interface IDBProject extends IDBRowData {
    p_acl: projectsFields.p_acl;
    p_created_at: projectsFields.p_created_at;
    p_creator_id: projectsFields.p_creator_id;
    p_id: projectsFields.p_id;
    p_name: projectsFields.p_name;
    p_updated_at: projectsFields.p_updated_at;
    p_workspace_id: projectsFields.p_workspace_id;
    p_messaged_at: Date | null;
    p_unreads: number;
    p_has_mentions: boolean;
    p_status: Types.DBProjectStatus;
}

/** Table: roles */
export declare namespace rolesFields {
    export type r_id = number;
    export type r_name = enum_r_name;
    export type r_policy = Object;
}

export interface IDBRole extends IDBRowData {
    r_id: rolesFields.r_id;
    r_name: rolesFields.r_name;
    r_policy: rolesFields.r_policy;
}

/** Table: sessions */
export declare namespace sessionsFields {
    export type s_created_at = Date;
    export type s_id = number;
    export type s_secret = string;
    export type s_user_id = number;
}

export interface IDBSession extends IDBRowData {
    s_created_at: sessionsFields.s_created_at;
    s_id: sessionsFields.s_id;
    s_secret: sessionsFields.s_secret;
    s_user_id: sessionsFields.s_user_id;
}

/** Table: suspensions */
export declare namespace suspensionsFields {
    export type ssp_initiator_id = number;
    export type ssp_suspended_at = Date;
    export type ssp_user_id = number;
    export type ssp_workspace_id = number;
}

export interface IDBSuspension extends IDBRowData {
    ssp_initiator_id: suspensionsFields.ssp_initiator_id;
    ssp_suspended_at: suspensionsFields.ssp_suspended_at;
    ssp_user_id: suspensionsFields.ssp_user_id;
    ssp_workspace_id: suspensionsFields.ssp_workspace_id;
}

/** Table: task_links */
export declare namespace task_linksFields {
    export type tl_link_type = enum_tl_link_type;
    export type tl_task_from_id = number;
    export type tl_task_to_id = number;
}

export interface IDBTaskLink extends IDBRowData {
    tl_link_type: task_linksFields.tl_link_type;
    tl_task_from_id: task_linksFields.tl_task_from_id;
    tl_task_to_id: task_linksFields.tl_task_to_id;
    tl_linked_task_name: IDBTask['tsk_name'];
    tl_linked_task_status: IDBTask['tsk_status'];
}

/** Table: tasks */
export declare namespace tasksFields {
    export type tsk_acl = Types.TAcl<'task'> | null;
    export type tsk_created_at = Date;
    export type tsk_creator_id = number;
    export type tsk_epic_id = number | null;
    export type tsk_estimate = number | null;
    export type tsk_id = number;
    export type tsk_milestone_id = number | null;
    export type tsk_name = string;
    export type tsk_owner_id = number | null;
    export type tsk_priority = enum_tsk_priority | null;
    export type tsk_project_id = number | null;
    export type tsk_status = enum_tsk_status;
    export type tsk_updated_at = Date;
    export type tsk_workspace_id = number;
}

export interface IDBTask extends IDBRowData {
    tsk_acl: tasksFields.tsk_acl;
    tsk_created_at: tasksFields.tsk_created_at;
    tsk_creator_id: tasksFields.tsk_creator_id;
    tsk_epic_id: tasksFields.tsk_epic_id;
    tsk_estimate: tasksFields.tsk_estimate;
    tsk_id: tasksFields.tsk_id;
    tsk_milestone_id: tasksFields.tsk_milestone_id;
    tsk_name: tasksFields.tsk_name;
    tsk_owner_id: tasksFields.tsk_owner_id;
    tsk_priority: tasksFields.tsk_priority;
    tsk_project_id: tasksFields.tsk_project_id;
    tsk_status: tasksFields.tsk_status;
    tsk_updated_at: tasksFields.tsk_updated_at;
    tsk_workspace_id: tasksFields.tsk_workspace_id;
    tsk_messaged_at: Date | null;
    tsk_unreads: number;
    tsk_has_mentions: boolean;
    tsk_links: string | null;
}

/** Table: topics */
export declare namespace topicsFields {
    export type t_acl = Object | null;
    export type t_created_at = Date;
    export type t_id = number;
    export type t_name = string;
    export type t_project_id = number | null;
    export type t_updated_at = Date;
    export type t_workspace_id = number;
}

export interface IDBTopic extends IDBRowData {
    t_acl: topicsFields.t_acl;
    t_created_at: topicsFields.t_created_at;
    t_id: topicsFields.t_id;
    t_name: topicsFields.t_name;
    t_project_id: topicsFields.t_project_id;
    t_updated_at: topicsFields.t_updated_at;
    t_workspace_id: topicsFields.t_workspace_id;
    t_messaged_at: Date | null;
    t_unreads: number;
    t_has_mentions: boolean;
}

/** Table: transactions */
export declare namespace transactionsFields {
    export type trn_id = number;
    export type trn_method = enum_trn_method;
    export type trn_paddle_payment_id = number;
    export type trn_processed_at = Date;
    export type trn_receipt_url = string;
    export type trn_total = number;
    export type trn_user_id = number;
}

export interface IDBTransaction extends IDBRowData {
    trn_id: transactionsFields.trn_id;
    trn_method: transactionsFields.trn_method;
    trn_paddle_payment_id: transactionsFields.trn_paddle_payment_id;
    trn_processed_at: transactionsFields.trn_processed_at;
    trn_receipt_url: transactionsFields.trn_receipt_url;
    trn_total: transactionsFields.trn_total;
    trn_user_id: transactionsFields.trn_user_id;
}

/** Table: users */
export declare namespace usersFields {
    export type u_avatar_s3_key = string | null;
    export type u_created_at = Date;
    export type u_display_name = string;
    export type u_email = string;
    export type u_firstname = string | null;
    export type u_id = number;
    export type u_lastname = string | null;
    export type u_paddle_subscription_id = number | null;
    export type u_password = string | null;
    export type u_status = enum_u_status;
    export type u_timezone = string;
    export type u_updated_at = Date;
}

export interface IDBUser extends IDBRowData {
    u_avatar_s3_key: usersFields.u_avatar_s3_key;
    u_created_at: usersFields.u_created_at;
    u_display_name: usersFields.u_display_name;
    u_email: usersFields.u_email;
    u_firstname: usersFields.u_firstname;
    u_id: usersFields.u_id;
    u_lastname: usersFields.u_lastname;
    u_paddle_subscription_id: usersFields.u_paddle_subscription_id;
    u_password: usersFields.u_password;
    u_status: usersFields.u_status;
    u_timezone: usersFields.u_timezone;
    u_updated_at: usersFields.u_updated_at;
    u_calculated_name: string;
    u_workspace_access_lists: Array<[number, number | null, number]> | null;
    u_workspace_suspensions: Array<Types.IDBSuspensionJson | null> | null;
    u_invited_to_workspace_ids: string | null;
}

/** Table: users_activity */
export declare namespace users_activityFields {
    export type ua_calculated_name = string;
    export type ua_created_at = Date | null;
    export type ua_days_since_last_activity = number | null;
    export type ua_email = string | null;
    export type ua_number_of_messages = number | null;
    export type ua_number_of_workspaces = number | null;
    export type ua_status = enum_ua_status | null;
}

export interface IDBUserActivity extends IDBRowData {
    ua_calculated_name: users_activityFields.ua_calculated_name;
    ua_created_at: users_activityFields.ua_created_at;
    ua_days_since_last_activity: users_activityFields.ua_days_since_last_activity;
    ua_email: users_activityFields.ua_email;
    ua_number_of_messages: users_activityFields.ua_number_of_messages;
    ua_number_of_workspaces: users_activityFields.ua_number_of_workspaces;
    ua_status: users_activityFields.ua_status;
}

/** Table: workspaces */
export declare namespace workspacesFields {
    export type w_created_at = Date;
    export type w_id = number;
    export type w_motto = string | null;
    export type w_name = string | null;
    export type w_updated_at = Date;
}

export interface IDBWorkspace extends IDBRowData {
    w_created_at: workspacesFields.w_created_at;
    w_id: workspacesFields.w_id;
    w_motto: workspacesFields.w_motto;
    w_name: workspacesFields.w_name;
    w_updated_at: workspacesFields.w_updated_at;
    w_owner_id: number;
    w_unread_counters: Array<Omit<Types.IAPIUnreadCounter, '__typename'>> | undefined;
}

/** Table: unreads */
export declare namespace unreadsFields {}

export interface IDBUnread extends IDBRowData {
    ur_message_id: number;
    ur_message_created_at: Date;
    ur_message_updated_at: Date;
    ur_was_mentioned_since_watched: boolean;
}
