import { IAPIAclEntities } from './graphql.gen';
import { TMBID } from './models';
import { RecursiveReplace } from './utilities';

export interface IPolicy {
    isRoot?: boolean;

    workspace?: IUpdate & {
        canAddUsers?: boolean;
        canToggleUserActiveState?: boolean;
    };

    group?: IUpdate & { canManageGroupMembers?: boolean };

    user?: { canUpdateUserRole?: boolean };

    project?: TCRVU;

    milestone?: TCRVU;

    epic?: TCRVU;

    task?: TCRVU & {
        canUpdateProject?: boolean;
        canUpdatePriority?: boolean;
        canUpdateStatus?: boolean;
        canUpdateOwner?: boolean;
        canUpdateEstimate?: boolean;
        canUpdateLinks?: boolean;
        canToggleAffiliateVisibility?: boolean;
    };

    talk?: TCRVU & {
        canToggleAffiliateVisibility?: boolean;
    };

    document?: TCRVU & {
        canToggleAffiliateVisibility?: boolean;
    };

    message?: {
        canToggleAffiliateVisibility?: boolean;
    };
}

interface ICreate {
    create?: boolean;
}

interface IRemove {
    remove?: boolean;
}

interface IView {
    view?: boolean;
}

interface IUpdate {
    update?: boolean;
}

type TCreateRemove = ICreate & IRemove;
type TViewUpdate = IView & IUpdate;

export type TCRVU = TCreateRemove & TViewUpdate;

export type TAcl<MODEL extends keyof Omit<IPolicy, 'isRoot'> = keyof Omit<IPolicy, 'isRoot'>> =
    RecursiveReplace<IPolicy[MODEL], IAclEntities> & {
        exclusiveAccess?: true;
    };

export interface IAclEntities {
    roleIds?: number[];
    userIds?: number[];
    groupIds?: number[];
}

export interface IAclEntity {
    userId?: TMBID;
    roleId?: TMBID;
    groupId?: TMBID;
}

export function isAclEntities(value: any): value is IAclEntities {
    return (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value) &&
        (value.hasOwnProperty('roleIds') ||
            value.hasOwnProperty('userIds') ||
            value.hasOwnProperty('groupIds'))
    );
}

export function isAPIAclEntities(value: any): value is IAPIAclEntities {
    return (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value) &&
        value['__typename'] === 'AclEntities' &&
        (value.hasOwnProperty('roleIdsCsv') ||
            value.hasOwnProperty('userIdsCsv') ||
            value.hasOwnProperty('groupIdsCsv'))
    );
}
