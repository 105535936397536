import { IDBEmoji, IEmoji, IAPIEmoji, ETables } from '@ab-task/types';
import { GUID2HASH, HASH2GUID } from './core';

export function emojiDB2JS(dbEmoji: IDBEmoji): IEmoji {
    return {
        id: dbEmoji.emj_id,
        code: dbEmoji.emj_code,
        emoji: dbEmoji.emj_emoji,
        name: dbEmoji.emj_name,
        category: dbEmoji.emj_category,
        subcategory: dbEmoji.emj_subcategory,
        imageUrl: `/emojis/${dbEmoji.emj_id}.webp`,
    };
}

export function emojiDB2API(dbEmoji: IDBEmoji): IAPIEmoji {
    return {
        __typename: 'Emoji',
        id: HASH2GUID(ETables.emojis, dbEmoji.emj_id),
        code: dbEmoji.emj_code,
        emoji: dbEmoji.emj_emoji,
        name: dbEmoji.emj_name,
        category: dbEmoji.emj_category,
        subcategory: dbEmoji.emj_subcategory,
    };
}

export function emojiAPI2JS(apiEmoji: IAPIEmoji): IEmoji {
    const hash = GUID2HASH(apiEmoji.id)[1];

    return {
        id: hash,
        code: apiEmoji.code,
        emoji: apiEmoji.emoji,
        name: apiEmoji.name,
        category: apiEmoji.category,
        subcategory: apiEmoji.subcategory,
        imageUrl: `/emojis/${hash}.webp`,
    };
}

export function emojiJS2API(emoji: IEmoji): IAPIEmoji {
    return {
        __typename: 'Emoji',
        id: HASH2GUID(ETables.emojis, emoji.id),
        code: emoji.code,
        emoji: emoji.emoji,
        name: emoji.name,
        category: emoji.category,
        subcategory: emoji.subcategory,
    };
}
