/* eslint-disable */
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { EmojiFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchEmojiQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchEmojiQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | {
              __typename: 'Emoji';
              id: string;
              code: Array<string>;
              emoji: string;
              name: string;
              category: string;
              subcategory: string;
          }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Talk' }
        | { __typename: 'Task' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchEmojisQueryVariables = Types.Exact<{
    limit: Types.Scalars['Int']['input'];
}>;

export type IAPIFetchEmojisQuery = {
    __typename: 'Query';
    emojis: Array<{
        __typename: 'Emoji';
        id: string;
        code: Array<string>;
        emoji: string;
        name: string;
        category: string;
        subcategory: string;
    }>;
    recentEmojis: Array<{
        __typename: 'Emoji';
        id: string;
        code: Array<string>;
        emoji: string;
        name: string;
        category: string;
        subcategory: string;
    }>;
};

export const FetchEmojiDocument = gql`
    query FetchEmoji($id: ID!) {
        node(id: $id) {
            ...EmojiFields
        }
    }
    ${EmojiFieldsFragmentDoc}
`;

/**
 * __useFetchEmojiQuery__
 *
 * To run a query within a React component, call `useFetchEmojiQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEmojiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEmojiQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchEmojiQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchEmojiQuery, IAPIFetchEmojiQueryVariables> &
        ({ variables: IAPIFetchEmojiQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchEmojiDocument, options);
    return Apollo.useQuery<IAPIFetchEmojiQuery, IAPIFetchEmojiQueryVariables>(
        FetchEmojiDocument,
        options
    );
}
export function useFetchEmojiLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchEmojiQuery, IAPIFetchEmojiQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchEmojiQuery, IAPIFetchEmojiQueryVariables>(
        FetchEmojiDocument,
        options
    );
}
export function useFetchEmojiSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchEmojiQuery, IAPIFetchEmojiQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchEmojiQuery, IAPIFetchEmojiQueryVariables>(
        FetchEmojiDocument,
        options
    );
}
export type FetchEmojiQueryHookResult = ReturnType<typeof useFetchEmojiQuery>;
export type FetchEmojiLazyQueryHookResult = ReturnType<typeof useFetchEmojiLazyQuery>;
export type FetchEmojiSuspenseQueryHookResult = ReturnType<typeof useFetchEmojiSuspenseQuery>;
export type FetchEmojiQueryResult = Apollo.QueryResult<
    IAPIFetchEmojiQuery,
    IAPIFetchEmojiQueryVariables
>;
export const FetchEmojisDocument = gql`
    query FetchEmojis($limit: Int!) {
        emojis {
            ...EmojiFields
        }
        recentEmojis(limit: $limit) {
            ...EmojiFields
        }
    }
    ${EmojiFieldsFragmentDoc}
`;

/**
 * __useFetchEmojisQuery__
 *
 * To run a query within a React component, call `useFetchEmojisQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEmojisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEmojisQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFetchEmojisQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchEmojisQuery, IAPIFetchEmojisQueryVariables> &
        ({ variables: IAPIFetchEmojisQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchEmojisDocument, options);
    return Apollo.useQuery<IAPIFetchEmojisQuery, IAPIFetchEmojisQueryVariables>(
        FetchEmojisDocument,
        options
    );
}
export function useFetchEmojisLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchEmojisQuery, IAPIFetchEmojisQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchEmojisQuery, IAPIFetchEmojisQueryVariables>(
        FetchEmojisDocument,
        options
    );
}
export function useFetchEmojisSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchEmojisQuery,
        IAPIFetchEmojisQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchEmojisQuery, IAPIFetchEmojisQueryVariables>(
        FetchEmojisDocument,
        options
    );
}
export type FetchEmojisQueryHookResult = ReturnType<typeof useFetchEmojisQuery>;
export type FetchEmojisLazyQueryHookResult = ReturnType<typeof useFetchEmojisLazyQuery>;
export type FetchEmojisSuspenseQueryHookResult = ReturnType<typeof useFetchEmojisSuspenseQuery>;
export type FetchEmojisQueryResult = Apollo.QueryResult<
    IAPIFetchEmojisQuery,
    IAPIFetchEmojisQueryVariables
>;
