/* eslint-disable */
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
export type IAPIUserFieldsFragment = {
    __typename: 'User';
    id: string;
    invitedToWorkspaceIds: Array<string>;
    email: string;
    firstname?: string | null;
    lastname?: string | null;
    displayName: string;
    calculatedName: string;
    timezone: string;
    status: Types.UserStatus;
    avatarS3Key?: string | null;
    paddleSubscriptionId?: number | null;
    createdAt: string;
    updatedAt: string;
    workspaceData: Array<{
        __typename: 'WorkspaceData';
        workspaceId: string;
        suspension?: {
            __typename: 'Suspension';
            id: string;
            initiatorId: string;
            suspendedAt: string;
        } | null;
        accessList: Array<{
            __typename: 'AccessListItem';
            projectId?: string | null;
            roleId: string;
        }>;
        onboarding?: {
            __typename: 'Onboarding';
            name: Types.OnboardingName;
            step: number;
            updatedAt: string;
        } | null;
    }>;
    globalOnboarding?: {
        __typename: 'Onboarding';
        name: Types.OnboardingName;
        step: number;
        updatedAt: string;
    } | null;
};

export type IAPIRoleFieldsFragment = {
    __typename: 'Role';
    id: string;
    name: Types.UserRole;
    policy: string;
};

export type IAPIGroupFieldsFragment = {
    __typename: 'Group';
    id: string;
    workspaceId: string;
    name: string;
    description?: string | null;
    memberIds?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    messagedAt?: string | null;
    unreads: number;
    hasMentions: boolean;
    creatorAlignments?: {
        __typename: 'CreatorAlignments';
        left: Array<string>;
        right: Array<string>;
    } | null;
};

export type IAPIBillFieldsFragment = {
    __typename: 'Bill';
    id: string;
    workspaceId: string;
    year: number;
    month: number;
    total: number;
    duration: number;
    requestsCount: number;
    storageUsage: number;
    storagePcplRequestsCount: number;
    storageOtherRequestsCount: number;
    storageDataTransfer: number;
};

export type IAPIBillPayableFieldsFragment = {
    __typename: 'BillPayable';
    id: string;
    billIds: Array<string>;
    year: number;
    month: number;
    total: number;
    duration: number;
    requestsCount: number;
    storageUsage: number;
    storagePcplRequestsCount: number;
    storageOtherRequestsCount: number;
    storageDataTransfer: number;
};

export type IAPITransactionFieldsFragment = {
    __typename: 'Transaction';
    id: string;
    userId: string;
    method: Types.TransactionMethod;
    receiptUrl: string;
    total: number;
    processedAt: string;
};

export type IAPIWorkspaceFieldsFragment = {
    __typename: 'Workspace';
    id: string;
    name: string;
    motto?: string | null;
    ownerId: string;
    createdAt: string;
    updatedAt: string;
};

export type IAPITalkFieldsFragment = {
    __typename: 'Talk';
    id: string;
    order: number;
    workspaceId: string;
    projectId?: string | null;
    name: string;
    createdAt: string;
    updatedAt: string;
    messagedAt?: string | null;
    unreads: number;
    hasMentions: boolean;
    creatorAlignments?: {
        __typename: 'CreatorAlignments';
        left: Array<string>;
        right: Array<string>;
    } | null;
    triggerMessage?: {
        __typename: 'TriggerMessage';
        messageId: string;
        anchor?: number | null;
        head?: number | null;
    } | null;
};

export type IAPIDocumentFieldsFragment = {
    __typename: 'Document';
    id: string;
    order: number;
    workspaceId: string;
    projectId?: string | null;
    name: string;
    createdAt: string;
    updatedAt: string;
    messagedAt?: string | null;
    unreads: number;
    hasMentions: boolean;
    triggerMessage?: {
        __typename: 'TriggerMessage';
        messageId: string;
        anchor?: number | null;
        head?: number | null;
    } | null;
};

export type IAPIProjectFieldsFragment = {
    __typename: 'Project';
    id: string;
    order: number;
    workspaceId: string;
    name: string;
    status: Types.ProjectStatus;
    creatorId: string;
    createdAt: string;
    updatedAt: string;
    messagedAt?: string | null;
    unreads: number;
    hasMentions: boolean;
    creatorAlignments?: {
        __typename: 'CreatorAlignments';
        left: Array<string>;
        right: Array<string>;
    } | null;
    triggerMessage?: {
        __typename: 'TriggerMessage';
        messageId: string;
        anchor?: number | null;
        head?: number | null;
    } | null;
};

export type IAPIEpicFieldsFragment = {
    __typename: 'Epic';
    id: string;
    workspaceId: string;
    projectId: string;
    milestoneId?: string | null;
    name: string;
    createdAt: string;
    updatedAt: string;
};

export type IAPITaskFieldsFragment = {
    __typename: 'Task';
    id: string;
    order: number;
    workspaceId: string;
    projectId?: string | null;
    milestoneId?: string | null;
    epicId?: string | null;
    name: string;
    status: Types.TaskStatus;
    estimate?: number | null;
    priority?: Types.TaskPriority | null;
    creatorId: string;
    createdAt: string;
    updatedAt: string;
    messagedAt?: string | null;
    ownerId?: string | null;
    unreads: number;
    hasMentions: boolean;
    links: Array<{
        __typename: 'TaskLink';
        id: string;
        linkType: Types.TaskLinkType;
        fromId: string;
        toId: string;
        name: string;
        status: Types.TaskStatus;
    }>;
    creatorAlignments?: {
        __typename: 'CreatorAlignments';
        left: Array<string>;
        right: Array<string>;
    } | null;
    triggerMessage?: {
        __typename: 'TriggerMessage';
        messageId: string;
        anchor?: number | null;
        head?: number | null;
    } | null;
    acl?: {
        __typename: 'TaskAcl';
        exclusiveAccess?: boolean | null;
        create?: {
            __typename: 'AclEntities';
            roleIdsCsv?: string | null;
            userIdsCsv?: string | null;
            groupIdsCsv?: string | null;
        } | null;
        view?: {
            __typename: 'AclEntities';
            roleIdsCsv?: string | null;
            userIdsCsv?: string | null;
            groupIdsCsv?: string | null;
        } | null;
        update?: {
            __typename: 'AclEntities';
            roleIdsCsv?: string | null;
            userIdsCsv?: string | null;
            groupIdsCsv?: string | null;
        } | null;
        remove?: {
            __typename: 'AclEntities';
            roleIdsCsv?: string | null;
            userIdsCsv?: string | null;
            groupIdsCsv?: string | null;
        } | null;
        canUpdateOwner?: {
            __typename: 'AclEntities';
            roleIdsCsv?: string | null;
            userIdsCsv?: string | null;
            groupIdsCsv?: string | null;
        } | null;
        canUpdateLinks?: {
            __typename: 'AclEntities';
            roleIdsCsv?: string | null;
            userIdsCsv?: string | null;
            groupIdsCsv?: string | null;
        } | null;
        canToggleAffiliateVisibility?: {
            __typename: 'AclEntities';
            roleIdsCsv?: string | null;
            userIdsCsv?: string | null;
            groupIdsCsv?: string | null;
        } | null;
    } | null;
};

export type IAPIAclEntitiesFieldsFragment = {
    __typename: 'AclEntities';
    roleIdsCsv?: string | null;
    userIdsCsv?: string | null;
    groupIdsCsv?: string | null;
};

export type IAPIMessageFieldsFragment = {
    __typename: 'Message';
    isIncoming?: boolean | null;
    isReadonly?: boolean | null;
    isBig?: boolean | null;
    attachmentS3Keys?: Array<string> | null;
    id: string;
    creatorId: string;
    workspaceId?: string | null;
    groupId?: string | null;
    talkId?: string | null;
    documentId?: string | null;
    projectId?: string | null;
    milestoneId?: string | null;
    epicId?: string | null;
    taskId?: string | null;
    authorIds: Array<string>;
    isFirst: boolean;
    isLast: boolean;
    createdAt: string;
    updatedAt: string;
    content?: string | null;
    version: number;
    watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
    reactions: Array<{
        __typename: 'MessageReaction';
        userId: string;
        emojiId: string;
        reactedAt: string;
    }>;
    followUps: Array<{
        __typename: 'FollowUp';
        itemGuid: string;
        anchor?: number | null;
        head?: number | null;
    }>;
};

export type IAPIMessageServerFieldsFragment = {
    __typename: 'Message';
    id: string;
    creatorId: string;
    workspaceId?: string | null;
    groupId?: string | null;
    talkId?: string | null;
    documentId?: string | null;
    projectId?: string | null;
    milestoneId?: string | null;
    epicId?: string | null;
    taskId?: string | null;
    authorIds: Array<string>;
    isFirst: boolean;
    isLast: boolean;
    createdAt: string;
    updatedAt: string;
    content?: string | null;
    version: number;
    watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
    reactions: Array<{
        __typename: 'MessageReaction';
        userId: string;
        emojiId: string;
        reactedAt: string;
    }>;
    followUps: Array<{
        __typename: 'FollowUp';
        itemGuid: string;
        anchor?: number | null;
        head?: number | null;
    }>;
};

export type IAPIMessageServerBasicFieldsFragment = {
    __typename: 'Message';
    id: string;
    creatorId: string;
    workspaceId?: string | null;
    groupId?: string | null;
    talkId?: string | null;
    documentId?: string | null;
    projectId?: string | null;
    milestoneId?: string | null;
    epicId?: string | null;
    taskId?: string | null;
    authorIds: Array<string>;
    isFirst: boolean;
    isLast: boolean;
    createdAt: string;
    updatedAt: string;
    watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
    reactions: Array<{
        __typename: 'MessageReaction';
        userId: string;
        emojiId: string;
        reactedAt: string;
    }>;
    followUps: Array<{
        __typename: 'FollowUp';
        itemGuid: string;
        anchor?: number | null;
        head?: number | null;
    }>;
};

export type IAPIMessageServerContentFieldsFragment = {
    __typename: 'Message';
    content?: string | null;
    version: number;
};

export type IAPIMessageClientFieldsFragment = {
    __typename: 'Message';
    isIncoming?: boolean | null;
    isReadonly?: boolean | null;
    isBig?: boolean | null;
    attachmentS3Keys?: Array<string> | null;
};

export type IAPIMessageIsBigFieldFragment = { __typename: 'Message'; isBig?: boolean | null };

export type IAPIUnreadFieldsFragment = {
    __typename: 'Unread';
    id: string;
    messageId: string;
    messageCreatedAt: string;
    messageUpdatedAt: string;
    wasMentionedSinceWatched: boolean;
};

export type IAPIAttachmentFieldsFragment = {
    __typename: 'Attachment';
    messageId: string;
    filename: string;
    contentType: string;
    s3Key: string;
    createdAt: string;
    progress?: number | null;
};

export type IAPIAttachmentServerFieldsFragment = {
    __typename: 'Attachment';
    messageId: string;
    filename: string;
    contentType: string;
    s3Key: string;
    createdAt: string;
};

export type IAPIAttachmentClientFieldsFragment = {
    __typename: 'Attachment';
    progress?: number | null;
};

export type IAPISelectionFieldsFragment = {
    __typename: 'Selection';
    userId: string;
    userDisplayName: string;
    messageId?: string | null;
    messageVersion?: number | null;
    type?: Types.SelectionType | null;
    time?: number | null;
    anchor?: number | null;
    head?: number | null;
};

export type IAPIDashboardFieldsFragment = {
    __typename: 'Dashboard';
    id: string;
    key?: string | null;
    workspaceId: string;
    ownerId: string;
    type: Types.DashboardType;
    name?: string | null;
    config?: string | null;
    favorite?: boolean | null;
    order: number;
    unreads: number;
    hasMentions: boolean;
    expanded?: boolean | null;
};

export type IAPIEmojiFieldsFragment = {
    __typename: 'Emoji';
    id: string;
    code: Array<string>;
    emoji: string;
    name: string;
    category: string;
    subcategory: string;
};

export type IAPIMessageWatchFieldsFragment = {
    __typename: 'MessageWatch';
    userId: string;
    watchedAt: string;
};

export type IAPIMessageReactionFieldsFragment = {
    __typename: 'MessageReaction';
    userId: string;
    emojiId: string;
    reactedAt: string;
};

export type IAPIMessageFollowUpFieldsFragment = {
    __typename: 'FollowUp';
    itemGuid: string;
    anchor?: number | null;
    head?: number | null;
};

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
        id
        workspaceData {
            workspaceId
            suspension {
                id
                initiatorId
                suspendedAt
            }
            accessList {
                projectId
                roleId
            }
            onboarding {
                name
                step
                updatedAt
            }
        }
        globalOnboarding {
            name
            step
            updatedAt
        }
        invitedToWorkspaceIds
        email
        firstname
        lastname
        displayName
        calculatedName
        timezone
        status
        avatarS3Key
        paddleSubscriptionId
        createdAt
        updatedAt
    }
`;
export const RoleFieldsFragmentDoc = gql`
    fragment RoleFields on Role {
        id
        name
        policy
    }
`;
export const GroupFieldsFragmentDoc = gql`
    fragment GroupFields on Group {
        id
        workspaceId
        name
        description
        memberIds
        createdAt
        updatedAt
        messagedAt
        creatorAlignments {
            left
            right
        }
        unreads
        hasMentions
    }
`;
export const BillFieldsFragmentDoc = gql`
    fragment BillFields on Bill {
        id
        workspaceId
        year
        month
        total
        duration
        requestsCount
        storageUsage
        storagePcplRequestsCount
        storageOtherRequestsCount
        storageDataTransfer
    }
`;
export const BillPayableFieldsFragmentDoc = gql`
    fragment BillPayableFields on BillPayable {
        id
        billIds
        year
        month
        total
        duration
        requestsCount
        storageUsage
        storagePcplRequestsCount
        storageOtherRequestsCount
        storageDataTransfer
    }
`;
export const TransactionFieldsFragmentDoc = gql`
    fragment TransactionFields on Transaction {
        id
        userId
        method
        receiptUrl
        total
        processedAt
    }
`;
export const WorkspaceFieldsFragmentDoc = gql`
    fragment WorkspaceFields on Workspace {
        id
        name
        motto
        ownerId
        createdAt
        updatedAt
    }
`;
export const TalkFieldsFragmentDoc = gql`
    fragment TalkFields on Talk {
        id
        order
        workspaceId
        projectId
        name
        createdAt
        updatedAt
        creatorAlignments {
            left
            right
        }
        messagedAt
        unreads
        hasMentions
        triggerMessage {
            messageId
            anchor
            head
        }
    }
`;
export const DocumentFieldsFragmentDoc = gql`
    fragment DocumentFields on Document {
        id
        order
        workspaceId
        projectId
        name
        createdAt
        updatedAt
        messagedAt
        unreads
        hasMentions
        triggerMessage {
            messageId
            anchor
            head
        }
    }
`;
export const ProjectFieldsFragmentDoc = gql`
    fragment ProjectFields on Project {
        id
        order
        workspaceId
        name
        status
        creatorId
        createdAt
        updatedAt
        creatorAlignments {
            left
            right
        }
        messagedAt
        unreads
        hasMentions
        triggerMessage {
            messageId
            anchor
            head
        }
    }
`;
export const EpicFieldsFragmentDoc = gql`
    fragment EpicFields on Epic {
        id
        workspaceId
        projectId
        milestoneId
        name
        createdAt
        updatedAt
    }
`;
export const AclEntitiesFieldsFragmentDoc = gql`
    fragment AclEntitiesFields on AclEntities {
        roleIdsCsv
        userIdsCsv
        groupIdsCsv
    }
`;
export const TaskFieldsFragmentDoc = gql`
    fragment TaskFields on Task {
        id
        order
        workspaceId
        projectId
        milestoneId
        epicId
        name
        status
        estimate
        priority
        links {
            id
            linkType
            fromId
            toId
            name
            status
        }
        creatorId
        createdAt
        updatedAt
        creatorAlignments {
            left
            right
        }
        messagedAt
        ownerId
        unreads
        hasMentions
        triggerMessage {
            messageId
            anchor
            head
        }
        acl {
            exclusiveAccess
            create {
                ...AclEntitiesFields
            }
            view {
                ...AclEntitiesFields
            }
            update {
                ...AclEntitiesFields
            }
            remove {
                ...AclEntitiesFields
            }
            canUpdateOwner {
                ...AclEntitiesFields
            }
            canUpdateLinks {
                ...AclEntitiesFields
            }
            canToggleAffiliateVisibility {
                ...AclEntitiesFields
            }
        }
    }
    ${AclEntitiesFieldsFragmentDoc}
`;
export const MessageWatchFieldsFragmentDoc = gql`
    fragment MessageWatchFields on MessageWatch {
        userId
        watchedAt
    }
`;
export const MessageReactionFieldsFragmentDoc = gql`
    fragment MessageReactionFields on MessageReaction {
        userId
        emojiId
        reactedAt
    }
`;
export const MessageFollowUpFieldsFragmentDoc = gql`
    fragment MessageFollowUpFields on FollowUp {
        itemGuid
        anchor
        head
    }
`;
export const MessageServerBasicFieldsFragmentDoc = gql`
    fragment MessageServerBasicFields on Message {
        id
        creatorId
        workspaceId
        groupId
        talkId
        documentId
        projectId
        milestoneId
        epicId
        taskId
        authorIds
        isFirst
        isLast
        createdAt
        updatedAt
        watches {
            ...MessageWatchFields
        }
        reactions {
            ...MessageReactionFields
        }
        followUps {
            ...MessageFollowUpFields
        }
    }
    ${MessageWatchFieldsFragmentDoc}
    ${MessageReactionFieldsFragmentDoc}
    ${MessageFollowUpFieldsFragmentDoc}
`;
export const MessageServerContentFieldsFragmentDoc = gql`
    fragment MessageServerContentFields on Message {
        content
        version
    }
`;
export const MessageServerFieldsFragmentDoc = gql`
    fragment MessageServerFields on Message {
        ...MessageServerBasicFields
        ...MessageServerContentFields
    }
    ${MessageServerBasicFieldsFragmentDoc}
    ${MessageServerContentFieldsFragmentDoc}
`;
export const MessageClientFieldsFragmentDoc = gql`
    fragment MessageClientFields on Message {
        isIncoming @client
        isReadonly @client
        isBig @client
        attachmentS3Keys @client
    }
`;
export const MessageFieldsFragmentDoc = gql`
    fragment MessageFields on Message {
        ...MessageServerFields
        ...MessageClientFields
    }
    ${MessageServerFieldsFragmentDoc}
    ${MessageClientFieldsFragmentDoc}
`;
export const MessageIsBigFieldFragmentDoc = gql`
    fragment MessageIsBigField on Message {
        isBig @client
    }
`;
export const UnreadFieldsFragmentDoc = gql`
    fragment UnreadFields on Unread {
        id
        messageId
        messageCreatedAt
        messageUpdatedAt
        wasMentionedSinceWatched
    }
`;
export const AttachmentServerFieldsFragmentDoc = gql`
    fragment AttachmentServerFields on Attachment {
        messageId
        filename
        contentType
        s3Key
        createdAt
    }
`;
export const AttachmentClientFieldsFragmentDoc = gql`
    fragment AttachmentClientFields on Attachment {
        progress @client
    }
`;
export const AttachmentFieldsFragmentDoc = gql`
    fragment AttachmentFields on Attachment {
        ...AttachmentServerFields
        ...AttachmentClientFields
    }
    ${AttachmentServerFieldsFragmentDoc}
    ${AttachmentClientFieldsFragmentDoc}
`;
export const SelectionFieldsFragmentDoc = gql`
    fragment SelectionFields on Selection {
        userId
        userDisplayName
        messageId
        messageVersion
        type
        time
        anchor
        head
    }
`;
export const DashboardFieldsFragmentDoc = gql`
    fragment DashboardFields on Dashboard {
        id
        key
        workspaceId
        ownerId
        type
        name
        config
        favorite
        order
        unreads
        hasMentions
        expanded @client
    }
`;
export const EmojiFieldsFragmentDoc = gql`
    fragment EmojiFields on Emoji {
        id
        code
        emoji
        name
        category
        subcategory
    }
`;
