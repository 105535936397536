import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { BillPayableFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchBillsPayableQueryVariables = Types.Exact<{
    filter: Types.IAPIBillsPayableFilter;
    sort?: Types.InputMaybe<Array<Types.IAPIBillsPayableSort> | Types.IAPIBillsPayableSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchBillsPayableQuery = {
    __typename: 'Query';
    billsPayable: {
        __typename: 'BillsPayable';
        allIds?: Array<number> | null;
        billsPayable: Array<{
            __typename: 'BillPayable';
            id: string;
            billIds: Array<string>;
            year: number;
            month: number;
            total: number;
            duration: number;
            requestsCount: number;
            storageUsage: number;
            storagePcplRequestsCount: number;
            storageOtherRequestsCount: number;
            storageDataTransfer: number;
        }>;
    };
};

export const FetchBillsPayableDocument = gql`
    query FetchBillsPayable(
        $filter: BillsPayableFilter!
        $sort: [BillsPayableSort!]
        $pagination: Pagination
    ) {
        billsPayable(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            billsPayable {
                ...BillPayableFields
            }
        }
    }
    ${BillPayableFieldsFragmentDoc}
`;

/**
 * __useFetchBillsPayableQuery__
 *
 * To run a query within a React component, call `useFetchBillsPayableQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBillsPayableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBillsPayableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchBillsPayableQuery(
    baseOptions: Apollo.QueryHookOptions<
        IAPIFetchBillsPayableQuery,
        IAPIFetchBillsPayableQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchBillsPayableDocument, options);
    return Apollo.useQuery<IAPIFetchBillsPayableQuery, IAPIFetchBillsPayableQueryVariables>(
        FetchBillsPayableDocument,
        options
    );
}
export function useFetchBillsPayableLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchBillsPayableQuery,
        IAPIFetchBillsPayableQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchBillsPayableQuery, IAPIFetchBillsPayableQueryVariables>(
        FetchBillsPayableDocument,
        options
    );
}
export type FetchBillsPayableQueryHookResult = ReturnType<typeof useFetchBillsPayableQuery>;
export type FetchBillsPayableLazyQueryHookResult = ReturnType<typeof useFetchBillsPayableLazyQuery>;
export type FetchBillsPayableQueryResult = Apollo.QueryResult<
    IAPIFetchBillsPayableQuery,
    IAPIFetchBillsPayableQueryVariables
>;
