import { BackendError } from '@ab-task/errors';

// Only for browsers
export default function getTimeZones() {
    const intl = Intl as { supportedValuesOf?: (key: string) => string[] };
    // TODO: this check is not needed on nodev18
    if (!intl.supportedValuesOf) {
        throw BackendError.INTERNAL({ info: 'Intl.supportedValuesOf not supported' });
    }
    return intl.supportedValuesOf('timeZone');
}
