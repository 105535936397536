import {
    IAPIMutationUpdateUserArgs,
    TaskPriority,
    TaskStatus,
    ProjectStatus,
} from '@ab-task/types';

export const TASK_STATUSES: TaskStatus[] = [
    'TRIAGE',
    'UP_NEXT',
    'IN_PROGRESS',
    'IN_REVIEW',
    'DONE',
    'CANCELLED',
];

export const TASK_PRIORITIES: TaskPriority[] = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];

export const USER_SELF_UPDATE_ONLY_FIELDS: Array<keyof IAPIMutationUpdateUserArgs['user']> = [
    'firstname',
    'lastname',
    'displayName',
    'password',
    'timezone',
    'avatarS3Key',
];
export const PROJECT_STATUSES: ProjectStatus[] = ['ACTIVE', 'COMPLETED'];
