import {
    IAPICreatorAlignmentsBroadcast,
    ICreatorAlignments,
    ETables,
    IAPICreatorAlignments,
    isApiCreatorAlignments,
    Maybe,
    TID,
} from '@ab-task/types';
import { GUID2ID, ID2GUID } from './core';
import { getModelID } from '@ab-task/utils';

export function creatorAlignmentsJS2API(
    creatorAlignments: ICreatorAlignments
): IAPICreatorAlignments {
    return {
        __typename: 'CreatorAlignments',
        left: creatorAlignments.left.map(userId => ID2GUID(ETables.users, userId)),
        right: creatorAlignments.right.map(userId => ID2GUID(ETables.users, userId)),
    };
}

export function creatorAlignmentsAPI2Broadcast(
    apiCreatorAlignments: IAPICreatorAlignments
): IAPICreatorAlignmentsBroadcast {
    return {
        left: apiCreatorAlignments.left,
        right: apiCreatorAlignments.right,
    };
}

export function creatorAlignmentsAPI2JS(
    apiCreatorAlignments: IAPICreatorAlignments
): ICreatorAlignments {
    return {
        left: apiCreatorAlignments.left.map(userGuid => GUID2ID(userGuid)[1]),
        right: apiCreatorAlignments.right.map(userGuid => GUID2ID(userGuid)[1]),
    };
}

export function calcNewCreatorAlignments(
    creatorAlignments?: Maybe<ICreatorAlignments> | Maybe<IAPICreatorAlignments>,
    latestMessageCreatorId?: Maybe<TID>
) {
    const creatorId = getModelID(latestMessageCreatorId, ETables.users);
    if (typeof creatorId !== 'number') return;

    let newCreatorAlignments: ICreatorAlignments | undefined;
    if (creatorAlignments) {
        creatorAlignments = isApiCreatorAlignments(creatorAlignments)
            ? creatorAlignmentsAPI2JS(creatorAlignments)
            : creatorAlignments;

        if (
            !creatorAlignments.left.includes(creatorId) &&
            !creatorAlignments.right.includes(creatorId)
        ) {
            newCreatorAlignments = {
                left:
                    creatorAlignments.left.length <= creatorAlignments.right.length
                        ? creatorAlignments.left.concat([creatorId])
                        : creatorAlignments.left,
                right:
                    creatorAlignments.right.length < creatorAlignments.left.length
                        ? creatorAlignments.right.concat([creatorId])
                        : creatorAlignments.right,
            };
        }
    } else {
        newCreatorAlignments = { left: [creatorId], right: [] };
    }

    return newCreatorAlignments;
}
