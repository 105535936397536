import { Maybe } from '@ab-task/types';

export function parseStoreFieldName<RESULT extends Maybe<Record<string, any>> = null>(
    storeFieldName: string
): RESULT | null {
    try {
        let argsStr: string | undefined;

        {
            const match = storeFieldName.match(/^[^(]+\((\{.*\})\)$/);
            if (match) {
                argsStr = match[1];
            }
        }

        if (!argsStr) {
            const colonIndex = storeFieldName.indexOf(':');

            if (colonIndex >= 0) {
                argsStr = storeFieldName.slice(colonIndex + 1).trim();
            }
        }

        return argsStr ? JSON.parse(argsStr) : null;
    } catch (error) {
        console.error(`Failed to parse storeFieldName "${storeFieldName}"`, error);
        return null;
    }
}
