/**
 * Specification of the toString() method
 * https://tc39.es/ecma262/multipage/fundamental-objects.html#sec-object.prototype.tostring
 */

type TypeName =
    | 'Undefined'
    | 'Null'
    | 'Object'
    | 'Array'
    | 'Arguments'
    | 'Function'
    | 'Error'
    | 'Boolean'
    | 'Number'
    | 'String'
    | 'Date'
    | 'RegExp';

export function typeOf(value: any) {
    const typeDescription = Object.prototype.toString.call(value);
    const match = typeDescription.match(/^\[object ([a-zA-Z]+)\]$/);

    if (!match) {
        throw new Error(
            `Unknown type! typeof is ${typeof value} and Object.prototype.toString returns ${typeDescription}`
        );
    }

    const typeName = match[1] as TypeName;

    return typeName;
}

export const isObject = (value: any): value is Object => typeOf(value) === 'Object';

export const isError = (value: any): value is Error => typeOf(value) === 'Error';

export function getIsNaturalNumber(value: any) {
    return (
        typeof value === 'number' && value >= 0 && Math.floor(value) === value && value !== Infinity
    );
}
