import { IDBDocument, IDocument, ETables, IAPIDocument, IAPIDocumentInput } from '@ab-task/types';
import { ID2GUID, ifValue, GUID2ID, ifValueNormalized, normalize } from './core';
import { triggerMessageAPI2JS } from './triggerMessage';

export function documentDB2JS(dbDocument: IDBDocument): IDocument {
    return {
        id: dbDocument.d_id,
        workspaceId: dbDocument.d_workspace_id,
        order: dbDocument.d_order,
        name: dbDocument.d_name,
        createdAt: dbDocument.d_created_at,
        updatedAt: dbDocument.d_updated_at,
        messagedAt: normalize(dbDocument.d_messaged_at),
        unreads: dbDocument.d_unreads,
        hasMentions: dbDocument.d_has_mentions,
        triggerMessage:
            typeof dbDocument.d_trigger_message_id === 'number'
                ? {
                      messageId: dbDocument.d_trigger_message_id,
                      head: normalize(dbDocument.d_trigger_message_head),
                      anchor: normalize(dbDocument.d_trigger_message_anchor),
                  }
                : undefined,
    };
}

export function documentDB2API(dbDocument: IDBDocument): IAPIDocument {
    return {
        __typename: 'Document',
        id: ID2GUID(ETables.documents, dbDocument.d_id),
        workspaceId: ID2GUID(ETables.workspaces, dbDocument.d_workspace_id),
        order: dbDocument.d_order,
        projectId: ifValue(dbDocument.d_project_id, v => ID2GUID(ETables.projects, v)),
        name: dbDocument.d_name,
        createdAt: dbDocument.d_created_at.toISOString(),
        updatedAt: dbDocument.d_updated_at.toISOString(),
        messagedAt: dbDocument.d_messaged_at ? dbDocument.d_messaged_at.toISOString() : null,
        unreads: dbDocument.d_unreads,
        hasMentions: dbDocument.d_has_mentions,
        triggerMessage:
            typeof dbDocument.d_trigger_message_id === 'number'
                ? {
                      __typename: 'TriggerMessage',
                      messageId: ID2GUID(ETables.messages, dbDocument.d_trigger_message_id),
                      head: normalize(dbDocument.d_trigger_message_head),
                      anchor: normalize(dbDocument.d_trigger_message_anchor),
                  }
                : null,
    };
}

export function documentAPI2JS(apiDocument: IAPIDocument): IDocument {
    return {
        id: GUID2ID(apiDocument.id)[1],
        workspaceId: GUID2ID(apiDocument.workspaceId)[1],
        order: apiDocument.order,
        projectId: ifValueNormalized(apiDocument.projectId, v => (v ? GUID2ID(v)[1] : undefined)),
        name: apiDocument.name,
        createdAt: new Date(apiDocument.createdAt),
        updatedAt: new Date(apiDocument.updatedAt),
        messagedAt: apiDocument.messagedAt ? new Date(apiDocument.messagedAt) : undefined,
        unreads: apiDocument.unreads,
        hasMentions: apiDocument.hasMentions,
        triggerMessage: apiDocument.triggerMessage
            ? triggerMessageAPI2JS(apiDocument.triggerMessage)
            : undefined,
    };
}

export function documentJS2APIInput(document: IDocument): IAPIDocumentInput {
    return {
        name: document.name,
        projectId: document.projectId ? ID2GUID(ETables.documents, document.projectId) : undefined,
    };
}
