import { IDBTopic, ITopic, ETables, IAPITopic, IAPITopicInput } from '@ab-task/types';
import { ID2GUID, ifValue, GUID2ID, ifValueNormalized, normalize } from './core';

export function topicDB2JS(dbTopic: IDBTopic): ITopic {
    return {
        id: dbTopic.t_id,
        workspaceId: dbTopic.t_workspace_id,
        name: dbTopic.t_name,
        createdAt: dbTopic.t_created_at,
        updatedAt: dbTopic.t_updated_at,
        messagedAt: normalize(dbTopic.t_messaged_at),
        unreads: dbTopic.t_unreads,
        hasMentions: dbTopic.t_has_mentions,
    };
}

export function topicDB2API(dbTopic: IDBTopic): IAPITopic {
    return {
        __typename: 'Topic',
        id: ID2GUID(ETables.topics, dbTopic.t_id),
        workspaceId: ID2GUID(ETables.workspaces, dbTopic.t_workspace_id),
        projectId: ifValue(dbTopic.t_project_id, v => ID2GUID(ETables.projects, v)),
        name: dbTopic.t_name,
        createdAt: dbTopic.t_created_at.toISOString(),
        updatedAt: dbTopic.t_updated_at.toISOString(),
        messagedAt: dbTopic.t_messaged_at ? dbTopic.t_messaged_at.toISOString() : null,
        unreads: dbTopic.t_unreads,
        hasMentions: dbTopic.t_has_mentions,
    };
}

export function topicAPI2JS(apiTopic: IAPITopic): ITopic {
    return {
        id: GUID2ID(apiTopic.id)[1],
        workspaceId: GUID2ID(apiTopic.workspaceId)[1],
        projectId: ifValueNormalized(apiTopic.projectId, v => (v ? GUID2ID(v)[1] : undefined)),
        name: apiTopic.name,
        createdAt: new Date(apiTopic.createdAt),
        updatedAt: new Date(apiTopic.updatedAt),
        messagedAt: apiTopic.messagedAt ? new Date(apiTopic.messagedAt) : undefined,
        unreads: apiTopic.unreads,
        hasMentions: apiTopic.hasMentions,
    };
}

export function topicJS2APIInput(topic: ITopic): IAPITopicInput {
    return {
        name: topic.name,
        projectId: topic.projectId ? ID2GUID(ETables.topics, topic.projectId) : undefined,
    };
}
