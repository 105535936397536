/* eslint-disable */
import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { DashboardFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchDashboardQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchDashboardQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | {
              __typename: 'Dashboard';
              id: string;
              key?: string | null;
              workspaceId: string;
              ownerId: string;
              type: Types.DashboardType;
              name?: string | null;
              config?: string | null;
              favorite?: boolean | null;
              order: number;
              unreads: number;
              hasMentions: boolean;
              expanded?: boolean | null;
          }
        | { __typename: 'Document' }
        | { __typename: 'Emoji' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Talk' }
        | { __typename: 'Task' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchDashboardsQueryVariables = Types.Exact<{
    filter: Types.IAPIDashboardsFilter;
    sort?: Types.InputMaybe<Array<Types.IAPIDashboardsSort> | Types.IAPIDashboardsSort>;
}>;

export type IAPIFetchDashboardsQuery = {
    __typename: 'Query';
    dashboards: Array<{
        __typename: 'Dashboard';
        id: string;
        key?: string | null;
        workspaceId: string;
        ownerId: string;
        type: Types.DashboardType;
        name?: string | null;
        config?: string | null;
        favorite?: boolean | null;
        order: number;
        unreads: number;
        hasMentions: boolean;
        expanded?: boolean | null;
    }>;
};

export type IAPICreateDashboardMutationVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    dashboard: Types.IAPIDashboardCreateInput;
    internal?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type IAPICreateDashboardMutation = {
    __typename: 'Mutation';
    createDashboard: {
        __typename: 'DashboardMutationResult';
        clientId: string;
        workspaceId: string;
        ownerId: string;
        internal?: boolean | null;
        dashboard: {
            __typename: 'Dashboard';
            id: string;
            key?: string | null;
            workspaceId: string;
            ownerId: string;
            type: Types.DashboardType;
            name?: string | null;
            config?: string | null;
            favorite?: boolean | null;
            order: number;
            unreads: number;
            hasMentions: boolean;
            expanded?: boolean | null;
        };
    };
};

export type IAPIUpdateDashboardMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
    dashboard: Types.IAPIDashboardUpdateInput;
}>;

export type IAPIUpdateDashboardMutation = {
    __typename: 'Mutation';
    updateDashboard: {
        __typename: 'DashboardMutationResult';
        clientId: string;
        workspaceId: string;
        ownerId: string;
        dashboard: {
            __typename: 'Dashboard';
            id: string;
            key?: string | null;
            workspaceId: string;
            ownerId: string;
            type: Types.DashboardType;
            name?: string | null;
            config?: string | null;
            favorite?: boolean | null;
            order: number;
            unreads: number;
            hasMentions: boolean;
            expanded?: boolean | null;
        };
    };
};

export type IAPIRemoveDashboardMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveDashboardMutation = {
    __typename: 'Mutation';
    removeDashboard: {
        __typename: 'DashboardMutationResult';
        clientId: string;
        workspaceId: string;
        ownerId: string;
        dashboard: {
            __typename: 'Dashboard';
            id: string;
            key?: string | null;
            workspaceId: string;
            ownerId: string;
            type: Types.DashboardType;
            name?: string | null;
            config?: string | null;
            favorite?: boolean | null;
            order: number;
            unreads: number;
            hasMentions: boolean;
            expanded?: boolean | null;
        };
    };
};

export type IAPIDashboardCreatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    ownerId: Types.Scalars['ID']['input'];
}>;

export type IAPIDashboardCreatedSubscription = {
    __typename: 'Subscription';
    dashboardCreated?: {
        __typename: 'DashboardMutationResult';
        clientId: string;
        workspaceId: string;
        ownerId: string;
        internal?: boolean | null;
        dashboard: {
            __typename: 'Dashboard';
            id: string;
            key?: string | null;
            workspaceId: string;
            ownerId: string;
            type: Types.DashboardType;
            name?: string | null;
            config?: string | null;
            favorite?: boolean | null;
            order: number;
            unreads: number;
            hasMentions: boolean;
            expanded?: boolean | null;
        };
    } | null;
};

export type IAPIDashboardUpdatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    ownerId: Types.Scalars['ID']['input'];
}>;

export type IAPIDashboardUpdatedSubscription = {
    __typename: 'Subscription';
    dashboardUpdated?: {
        __typename: 'DashboardMutationResult';
        clientId: string;
        workspaceId: string;
        ownerId: string;
        dashboard: {
            __typename: 'Dashboard';
            id: string;
            key?: string | null;
            workspaceId: string;
            ownerId: string;
            type: Types.DashboardType;
            name?: string | null;
            config?: string | null;
            favorite?: boolean | null;
            order: number;
            unreads: number;
            hasMentions: boolean;
            expanded?: boolean | null;
        };
    } | null;
};

export type IAPIDashboardRemovedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    ownerId: Types.Scalars['ID']['input'];
}>;

export type IAPIDashboardRemovedSubscription = {
    __typename: 'Subscription';
    dashboardRemoved?: {
        __typename: 'DashboardMutationResult';
        clientId: string;
        workspaceId: string;
        ownerId: string;
        dashboard: {
            __typename: 'Dashboard';
            id: string;
            key?: string | null;
            workspaceId: string;
            ownerId: string;
            type: Types.DashboardType;
            name?: string | null;
            config?: string | null;
            favorite?: boolean | null;
            order: number;
            unreads: number;
            hasMentions: boolean;
            expanded?: boolean | null;
        };
    } | null;
};

export const FetchDashboardDocument = gql`
    query FetchDashboard($id: ID!) {
        node(id: $id) {
            ...DashboardFields
        }
    }
    ${DashboardFieldsFragmentDoc}
`;

/**
 * __useFetchDashboardQuery__
 *
 * To run a query within a React component, call `useFetchDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchDashboardQuery(
    baseOptions: Apollo.QueryHookOptions<
        IAPIFetchDashboardQuery,
        IAPIFetchDashboardQueryVariables
    > &
        ({ variables: IAPIFetchDashboardQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchDashboardDocument, options);
    return Apollo.useQuery<IAPIFetchDashboardQuery, IAPIFetchDashboardQueryVariables>(
        FetchDashboardDocument,
        options
    );
}
export function useFetchDashboardLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchDashboardQuery,
        IAPIFetchDashboardQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchDashboardQuery, IAPIFetchDashboardQueryVariables>(
        FetchDashboardDocument,
        options
    );
}
export function useFetchDashboardSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchDashboardQuery,
        IAPIFetchDashboardQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchDashboardQuery, IAPIFetchDashboardQueryVariables>(
        FetchDashboardDocument,
        options
    );
}
export type FetchDashboardQueryHookResult = ReturnType<typeof useFetchDashboardQuery>;
export type FetchDashboardLazyQueryHookResult = ReturnType<typeof useFetchDashboardLazyQuery>;
export type FetchDashboardSuspenseQueryHookResult = ReturnType<
    typeof useFetchDashboardSuspenseQuery
>;
export type FetchDashboardQueryResult = Apollo.QueryResult<
    IAPIFetchDashboardQuery,
    IAPIFetchDashboardQueryVariables
>;
export const FetchDashboardsDocument = gql`
    query FetchDashboards($filter: DashboardsFilter!, $sort: [DashboardsSort!]) {
        dashboards(filter: $filter, sort: $sort) {
            ...DashboardFields
        }
    }
    ${DashboardFieldsFragmentDoc}
`;

/**
 * __useFetchDashboardsQuery__
 *
 * To run a query within a React component, call `useFetchDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFetchDashboardsQuery(
    baseOptions: Apollo.QueryHookOptions<
        IAPIFetchDashboardsQuery,
        IAPIFetchDashboardsQueryVariables
    > &
        ({ variables: IAPIFetchDashboardsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchDashboardsDocument, options);
    return Apollo.useQuery<IAPIFetchDashboardsQuery, IAPIFetchDashboardsQueryVariables>(
        FetchDashboardsDocument,
        options
    );
}
export function useFetchDashboardsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchDashboardsQuery,
        IAPIFetchDashboardsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchDashboardsQuery, IAPIFetchDashboardsQueryVariables>(
        FetchDashboardsDocument,
        options
    );
}
export function useFetchDashboardsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchDashboardsQuery,
        IAPIFetchDashboardsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchDashboardsQuery, IAPIFetchDashboardsQueryVariables>(
        FetchDashboardsDocument,
        options
    );
}
export type FetchDashboardsQueryHookResult = ReturnType<typeof useFetchDashboardsQuery>;
export type FetchDashboardsLazyQueryHookResult = ReturnType<typeof useFetchDashboardsLazyQuery>;
export type FetchDashboardsSuspenseQueryHookResult = ReturnType<
    typeof useFetchDashboardsSuspenseQuery
>;
export type FetchDashboardsQueryResult = Apollo.QueryResult<
    IAPIFetchDashboardsQuery,
    IAPIFetchDashboardsQueryVariables
>;
export const CreateDashboardDocument = gql`
    mutation CreateDashboard(
        $workspaceId: ID!
        $dashboard: DashboardCreateInput!
        $internal: Boolean
    ) {
        createDashboard(workspaceId: $workspaceId, dashboard: $dashboard, internal: $internal) {
            clientId
            workspaceId
            ownerId
            internal
            dashboard {
                ...DashboardFields
            }
        }
    }
    ${DashboardFieldsFragmentDoc}
`;
export type IAPICreateDashboardMutationFn = Apollo.MutationFunction<
    IAPICreateDashboardMutation,
    IAPICreateDashboardMutationVariables
>;

/**
 * __useCreateDashboardMutation__
 *
 * To run a mutation, you first call `useCreateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardMutation, { data, loading, error }] = useCreateDashboardMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      dashboard: // value for 'dashboard'
 *      internal: // value for 'internal'
 *   },
 * });
 */
export function useCreateDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateDashboardMutation,
        IAPICreateDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateDashboardMutation, IAPICreateDashboardMutationVariables>(
        CreateDashboardDocument,
        options
    );
}
export type CreateDashboardMutationHookResult = ReturnType<typeof useCreateDashboardMutation>;
export type CreateDashboardMutationResult = Apollo.MutationResult<IAPICreateDashboardMutation>;
export type CreateDashboardMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateDashboardMutation,
    IAPICreateDashboardMutationVariables
>;
export const UpdateDashboardDocument = gql`
    mutation UpdateDashboard($id: ID!, $dashboard: DashboardUpdateInput!) {
        updateDashboard(id: $id, dashboard: $dashboard) {
            clientId
            workspaceId
            ownerId
            dashboard {
                ...DashboardFields
            }
        }
    }
    ${DashboardFieldsFragmentDoc}
`;
export type IAPIUpdateDashboardMutationFn = Apollo.MutationFunction<
    IAPIUpdateDashboardMutation,
    IAPIUpdateDashboardMutationVariables
>;

/**
 * __useUpdateDashboardMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardMutation, { data, loading, error }] = useUpdateDashboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dashboard: // value for 'dashboard'
 *   },
 * });
 */
export function useUpdateDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateDashboardMutation,
        IAPIUpdateDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateDashboardMutation, IAPIUpdateDashboardMutationVariables>(
        UpdateDashboardDocument,
        options
    );
}
export type UpdateDashboardMutationHookResult = ReturnType<typeof useUpdateDashboardMutation>;
export type UpdateDashboardMutationResult = Apollo.MutationResult<IAPIUpdateDashboardMutation>;
export type UpdateDashboardMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateDashboardMutation,
    IAPIUpdateDashboardMutationVariables
>;
export const RemoveDashboardDocument = gql`
    mutation RemoveDashboard($id: ID!) {
        removeDashboard(id: $id, action: DELETE) {
            clientId
            workspaceId
            ownerId
            dashboard {
                ...DashboardFields
            }
        }
    }
    ${DashboardFieldsFragmentDoc}
`;
export type IAPIRemoveDashboardMutationFn = Apollo.MutationFunction<
    IAPIRemoveDashboardMutation,
    IAPIRemoveDashboardMutationVariables
>;

/**
 * __useRemoveDashboardMutation__
 *
 * To run a mutation, you first call `useRemoveDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDashboardMutation, { data, loading, error }] = useRemoveDashboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveDashboardMutation,
        IAPIRemoveDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveDashboardMutation, IAPIRemoveDashboardMutationVariables>(
        RemoveDashboardDocument,
        options
    );
}
export type RemoveDashboardMutationHookResult = ReturnType<typeof useRemoveDashboardMutation>;
export type RemoveDashboardMutationResult = Apollo.MutationResult<IAPIRemoveDashboardMutation>;
export type RemoveDashboardMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveDashboardMutation,
    IAPIRemoveDashboardMutationVariables
>;
export const DashboardCreatedDocument = gql`
    subscription DashboardCreated($workspaceId: ID!, $ownerId: ID!) {
        dashboardCreated(workspaceId: $workspaceId, ownerId: $ownerId) {
            clientId
            workspaceId
            ownerId
            internal
            dashboard {
                ...DashboardFields
            }
        }
    }
    ${DashboardFieldsFragmentDoc}
`;

/**
 * __useDashboardCreatedSubscription__
 *
 * To run a query within a React component, call `useDashboardCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCreatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useDashboardCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIDashboardCreatedSubscription,
        IAPIDashboardCreatedSubscriptionVariables
    > &
        (
            | { variables: IAPIDashboardCreatedSubscriptionVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(DashboardCreatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIDashboardCreatedSubscription,
            IAPIDashboardCreatedSubscriptionVariables
        >(DashboardCreatedDocument, options),
        connected,
    };
}
export type DashboardCreatedSubscriptionHookResult = ReturnType<
    typeof useDashboardCreatedSubscription
>;
export type DashboardCreatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIDashboardCreatedSubscription>;
export const DashboardUpdatedDocument = gql`
    subscription DashboardUpdated($workspaceId: ID!, $ownerId: ID!) {
        dashboardUpdated(workspaceId: $workspaceId, ownerId: $ownerId) {
            clientId
            workspaceId
            ownerId
            dashboard {
                ...DashboardFields
            }
        }
    }
    ${DashboardFieldsFragmentDoc}
`;

/**
 * __useDashboardUpdatedSubscription__
 *
 * To run a query within a React component, call `useDashboardUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDashboardUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardUpdatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useDashboardUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIDashboardUpdatedSubscription,
        IAPIDashboardUpdatedSubscriptionVariables
    > &
        (
            | { variables: IAPIDashboardUpdatedSubscriptionVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(DashboardUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIDashboardUpdatedSubscription,
            IAPIDashboardUpdatedSubscriptionVariables
        >(DashboardUpdatedDocument, options),
        connected,
    };
}
export type DashboardUpdatedSubscriptionHookResult = ReturnType<
    typeof useDashboardUpdatedSubscription
>;
export type DashboardUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIDashboardUpdatedSubscription>;
export const DashboardRemovedDocument = gql`
    subscription DashboardRemoved($workspaceId: ID!, $ownerId: ID!) {
        dashboardRemoved(workspaceId: $workspaceId, ownerId: $ownerId) {
            clientId
            workspaceId
            ownerId
            dashboard {
                ...DashboardFields
            }
        }
    }
    ${DashboardFieldsFragmentDoc}
`;

/**
 * __useDashboardRemovedSubscription__
 *
 * To run a query within a React component, call `useDashboardRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDashboardRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardRemovedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useDashboardRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIDashboardRemovedSubscription,
        IAPIDashboardRemovedSubscriptionVariables
    > &
        (
            | { variables: IAPIDashboardRemovedSubscriptionVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(DashboardRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIDashboardRemovedSubscription,
            IAPIDashboardRemovedSubscriptionVariables
        >(DashboardRemovedDocument, options),
        connected,
    };
}
export type DashboardRemovedSubscriptionHookResult = ReturnType<
    typeof useDashboardRemovedSubscription
>;
export type DashboardRemovedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIDashboardRemovedSubscription>;
