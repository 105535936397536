import {
    IDBTask,
    ITask,
    ITaskLink,
    ETables,
    IAPITask,
    IAPITaskLink,
    IAPITaskInput,
    IAdaptorWithCache,
    IAPITasksFilter,
    TFilterKey,
    TaskStatus,
    TaskPriority,
    IDBTaskLink,
    IAPITaskUpdateBroadcast,
    IAPITaskLinkBroadcast,
    TaskLinkType,
} from '@ab-task/types';
import { normalize, ID2GUID, GUID2ID } from './core';
import { TASK_PRIORITIES, TASK_STATUSES } from '@ab-task/data';
import { T } from '@ab-task/internationalization';
import { CoreError } from '@ab-task/errors';
import { taskAclAPI2Broadcast, taskAclAPI2JS, taskAclJS2API } from './acl';

// Task
export function taskDB2JS(dbTask: IDBTask): ITask {
    const { tsk_links } = dbTask;

    const links = tsk_links === null ? [] : (JSON.parse(tsk_links) as IDBTaskLink[]);

    return {
        id: dbTask.tsk_id,
        workspaceId: dbTask.tsk_workspace_id,
        projectId: normalize(dbTask.tsk_project_id),
        milestoneId: normalize(dbTask.tsk_milestone_id),
        epicId: normalize(dbTask.tsk_epic_id),
        name: dbTask.tsk_name,
        status: dbTask.tsk_status,
        estimate: normalize(dbTask.tsk_estimate),
        priority: normalize(dbTask.tsk_priority),
        links: links.map(taskLinkDB2JS),
        creatorId: dbTask.tsk_creator_id,
        createdAt: dbTask.tsk_created_at,
        updatedAt: dbTask.tsk_updated_at,
        messagedAt: normalize(dbTask.tsk_messaged_at),
        ownerId: normalize(dbTask.tsk_owner_id),
        unreads: dbTask.tsk_unreads,
        hasMentions: dbTask.tsk_has_mentions,
        acl: dbTask.tsk_acl || undefined,
    };
}

export function taskDB2API(dbTask: IDBTask): IAPITask {
    const { tsk_links } = dbTask;

    const links = tsk_links === null ? [] : (JSON.parse(tsk_links) as IDBTaskLink[]);

    return {
        __typename: 'Task',
        id: ID2GUID(ETables.tasks, dbTask.tsk_id),
        workspaceId: ID2GUID(ETables.workspaces, dbTask.tsk_workspace_id),
        projectId: dbTask.tsk_project_id ? ID2GUID(ETables.projects, dbTask.tsk_project_id) : null,
        milestoneId: dbTask.tsk_milestone_id
            ? ID2GUID(ETables.milestones, dbTask.tsk_milestone_id)
            : null,
        epicId: dbTask.tsk_epic_id ? ID2GUID(ETables.epics, dbTask.tsk_epic_id) : null,
        name: dbTask.tsk_name,
        status: dbTask.tsk_status,
        estimate: dbTask.tsk_estimate,
        priority: dbTask.tsk_priority,
        links: links.map(link => taskLinkDB2API(dbTask.tsk_id, link)),
        creatorId: ID2GUID(ETables.users, dbTask.tsk_creator_id),
        createdAt: dbTask.tsk_created_at.toISOString(),
        updatedAt: dbTask.tsk_updated_at.toISOString(),
        messagedAt: dbTask.tsk_messaged_at ? dbTask.tsk_messaged_at.toISOString() : null,
        ownerId: dbTask.tsk_owner_id ? ID2GUID(ETables.users, dbTask.tsk_owner_id) : null,
        unreads: dbTask.tsk_unreads,
        hasMentions: dbTask.tsk_has_mentions,
        acl: taskAclJS2API(dbTask.tsk_acl),
    };
}

export function taskAPI2JS(apiTask: IAPITask): ITask {
    return {
        id: GUID2ID(apiTask.id)[1],
        workspaceId: GUID2ID(apiTask.workspaceId)[1],
        projectId: apiTask.projectId ? GUID2ID(apiTask.projectId)[1] : undefined,
        milestoneId: apiTask.milestoneId ? GUID2ID(apiTask.milestoneId)[1] : undefined,
        epicId: apiTask.epicId ? GUID2ID(apiTask.epicId)[1] : undefined,
        name: apiTask.name,
        status: apiTask.status,
        estimate: normalize(apiTask.estimate),
        priority: normalize(apiTask.priority),
        links: apiTask.links.map(taskLinkAPI2JS),
        creatorId: GUID2ID(apiTask.creatorId)[1],
        createdAt: new Date(apiTask.createdAt),
        updatedAt: new Date(apiTask.updatedAt),
        messagedAt: apiTask.messagedAt ? new Date(apiTask.messagedAt) : undefined,
        ownerId: apiTask.ownerId ? GUID2ID(apiTask.ownerId)[1] : undefined,
        unreads: apiTask.unreads,
        hasMentions: apiTask.hasMentions,
        acl: taskAclAPI2JS(apiTask.acl),
    };
}

export function taskAPI2Broadcast(apiTask: IAPITask): IAPITaskUpdateBroadcast {
    return {
        id: apiTask.id,
        workspaceId: apiTask.workspaceId,
        projectId: apiTask.projectId,
        milestoneId: apiTask.milestoneId,
        epicId: apiTask.epicId,
        name: apiTask.name,
        status: apiTask.status,
        estimate: apiTask.estimate,
        priority: apiTask.priority,
        links: apiTask.links.map(taskLinkAPI2Broadcast),
        creatorId: apiTask.creatorId,
        createdAt: apiTask.createdAt,
        updatedAt: apiTask.updatedAt,
        messagedAt: apiTask.messagedAt,
        ownerId: apiTask.ownerId,
        unreads: apiTask.unreads,
        hasMentions: apiTask.hasMentions,
        acl: apiTask.acl ? taskAclAPI2Broadcast(apiTask.acl) : undefined,
    };
}

export function taskJS2APIInput(task: ITask): IAPITaskInput {
    return {
        name: task.name,
        status: task.status,
        estimate: task.estimate === undefined ? null : task.estimate,
        priority: task.priority === undefined ? null : task.priority,
        ownerId: task.ownerId ? ID2GUID(ETables.users, task.ownerId) : null,
    };
}

// Task links
export function taskLinkDB2API(taskId: number, dbTaskLink: IDBTaskLink): IAPITaskLink {
    return {
        __typename: 'TaskLink',
        id: ID2GUID('tasks', taskId) + `:${dbTaskLink.tl_task_from_id}-${dbTaskLink.tl_task_to_id}`,
        toId: ID2GUID('tasks', dbTaskLink.tl_task_to_id),
        fromId: ID2GUID('tasks', dbTaskLink.tl_task_from_id),
        linkType: dbTaskLink.tl_link_type,
        name: dbTaskLink.tl_linked_task_name,
        status: dbTaskLink.tl_linked_task_status,
    };
}

export function taskLinkDB2JS(dbTaskLink: IDBTaskLink): ITaskLink {
    return {
        fromId: dbTaskLink.tl_task_from_id,
        toId: dbTaskLink.tl_task_to_id,
        linkType: dbTaskLink.tl_link_type,
        name: dbTaskLink.tl_linked_task_name,
        status: dbTaskLink.tl_linked_task_status,
        resolved: getIsTaskLinkResolved(dbTaskLink.tl_link_type, dbTaskLink.tl_linked_task_status),
    };
}

export function taskLinkAPI2JS(apiTaskLink: IAPITaskLink): ITaskLink {
    return {
        fromId: GUID2ID(apiTaskLink.fromId)[1],
        toId: GUID2ID(apiTaskLink.toId)[1],
        linkType: apiTaskLink.linkType,
        name: apiTaskLink.name,
        status: apiTaskLink.status,
        resolved: getIsTaskLinkResolved(apiTaskLink.linkType, apiTaskLink.status),
    };
}

export function taskLinkAPI2Broadcast(apiTaskLink: IAPITaskLink): IAPITaskLinkBroadcast {
    return {
        id: apiTaskLink.id,
        fromId: apiTaskLink.fromId,
        toId: apiTaskLink.toId,
        linkType: apiTaskLink.linkType,
        name: apiTaskLink.name,
        status: apiTaskLink.status,
    };
}

// Utils

/**
 * Returns true if this link can be ignored.
 * If task that blocks other task is done or cancelled, link can be ignored.
 */
export function getIsTaskLinkResolved(linkType: TaskLinkType, taskStatus: TaskStatus): boolean {
    return linkType === 'BLOCKS' && (taskStatus === 'DONE' || taskStatus === 'CANCELLED');
}

export const taskFilterKey2Human: IAdaptorWithCache<
    TFilterKey<IAPITasksFilter>,
    string
> = filterKey => {
    if (!taskFilterKey2Human.cache) {
        taskFilterKey2Human.cache = new Map([
            ['id', 'ID'],
            ['name', T('name')],
            ['creatorId', T('creator')],
            ['ownerId', T('owner')],
            ['projectId', T('project')],
            ['status', T('status')],
            ['estimate', T('estimate')],
            ['priority', T('priority')],
            ['unreads', T('unreads')],
        ]);
    }

    const labelsMap = taskFilterKey2Human.cache;
    const label = labelsMap.get(filterKey);

    if (label === undefined) {
        throw CoreError.TYPE_ADAPTOR_FAILED({
            info: `Expected label for filterKey "${filterKey}" but got undefined`,
        });
    }

    return label;
};

export const taskStatus2Human: IAdaptorWithCache<TaskStatus, string> = status => {
    if (!taskStatus2Human.cache) {
        taskStatus2Human.cache = new Map(
            TASK_STATUSES.map(status => [status, T(`status:${status}`)])
        );
    }

    const labelsMap = taskStatus2Human.cache;
    const label = labelsMap.get(status);

    if (label === undefined) {
        throw CoreError.TYPE_ADAPTOR_FAILED({
            info: `Expected label for status "${status}" but got undefined`,
        });
    }

    return label;
};

export const taskPriority2Human: IAdaptorWithCache<TaskPriority, string> = priority => {
    if (!taskPriority2Human.cache) {
        taskPriority2Human.cache = new Map(
            TASK_PRIORITIES.map(priority => [priority, T(`priority:${priority}`)])
        );
    }

    const labelsMap = taskPriority2Human.cache;
    const label = labelsMap.get(priority);

    if (label === undefined) {
        throw CoreError.TYPE_ADAPTOR_FAILED({
            info: `Expected label for priority "${priority}" but got undefined`,
        });
    }

    return label;
};
