/* eslint-disable */
import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { TaskFieldsFragmentDoc, MessageServerFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchTaskQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
    withTriggerMessage: Types.Scalars['Boolean']['input'];
}>;

export type IAPIFetchTaskQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Emoji' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Talk' }
        | {
              __typename: 'Task';
              id: string;
              order: number;
              workspaceId: string;
              projectId?: string | null;
              milestoneId?: string | null;
              epicId?: string | null;
              name: string;
              status: Types.TaskStatus;
              estimate?: number | null;
              priority?: Types.TaskPriority | null;
              creatorId: string;
              createdAt: string;
              updatedAt: string;
              messagedAt?: string | null;
              ownerId?: string | null;
              unreads: number;
              hasMentions: boolean;
              triggerMessage?: {
                  __typename: 'TriggerMessage';
                  messageId: string;
                  anchor?: number | null;
                  head?: number | null;
                  message?: {
                      __typename: 'Message';
                      id: string;
                      creatorId: string;
                      workspaceId?: string | null;
                      groupId?: string | null;
                      talkId?: string | null;
                      documentId?: string | null;
                      projectId?: string | null;
                      milestoneId?: string | null;
                      epicId?: string | null;
                      taskId?: string | null;
                      authorIds: Array<string>;
                      isFirst: boolean;
                      isLast: boolean;
                      createdAt: string;
                      updatedAt: string;
                      content?: string | null;
                      version: number;
                      watches: Array<{
                          __typename: 'MessageWatch';
                          userId: string;
                          watchedAt: string;
                      }>;
                      reactions: Array<{
                          __typename: 'MessageReaction';
                          userId: string;
                          emojiId: string;
                          reactedAt: string;
                      }>;
                      followUps: Array<{
                          __typename: 'FollowUp';
                          itemGuid: string;
                          anchor?: number | null;
                          head?: number | null;
                      }>;
                  } | null;
              } | null;
              links: Array<{
                  __typename: 'TaskLink';
                  id: string;
                  linkType: Types.TaskLinkType;
                  fromId: string;
                  toId: string;
                  name: string;
                  status: Types.TaskStatus;
              }>;
              creatorAlignments?: {
                  __typename: 'CreatorAlignments';
                  left: Array<string>;
                  right: Array<string>;
              } | null;
              acl?: {
                  __typename: 'TaskAcl';
                  exclusiveAccess?: boolean | null;
                  create?: {
                      __typename: 'AclEntities';
                      roleIdsCsv?: string | null;
                      userIdsCsv?: string | null;
                      groupIdsCsv?: string | null;
                  } | null;
                  view?: {
                      __typename: 'AclEntities';
                      roleIdsCsv?: string | null;
                      userIdsCsv?: string | null;
                      groupIdsCsv?: string | null;
                  } | null;
                  update?: {
                      __typename: 'AclEntities';
                      roleIdsCsv?: string | null;
                      userIdsCsv?: string | null;
                      groupIdsCsv?: string | null;
                  } | null;
                  remove?: {
                      __typename: 'AclEntities';
                      roleIdsCsv?: string | null;
                      userIdsCsv?: string | null;
                      groupIdsCsv?: string | null;
                  } | null;
                  canUpdateOwner?: {
                      __typename: 'AclEntities';
                      roleIdsCsv?: string | null;
                      userIdsCsv?: string | null;
                      groupIdsCsv?: string | null;
                  } | null;
                  canUpdateLinks?: {
                      __typename: 'AclEntities';
                      roleIdsCsv?: string | null;
                      userIdsCsv?: string | null;
                      groupIdsCsv?: string | null;
                  } | null;
                  canToggleAffiliateVisibility?: {
                      __typename: 'AclEntities';
                      roleIdsCsv?: string | null;
                      userIdsCsv?: string | null;
                      groupIdsCsv?: string | null;
                  } | null;
              } | null;
          }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchTasksQueryVariables = Types.Exact<{
    filter: Types.IAPITasksFilter;
    sort?: Types.InputMaybe<Array<Types.IAPITasksSort> | Types.IAPITasksSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchTasksQuery = {
    __typename: 'Query';
    tasks: {
        __typename: 'Tasks';
        allIds?: Array<number> | null;
        tasks: Array<{
            __typename: 'Task';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            milestoneId?: string | null;
            epicId?: string | null;
            name: string;
            status: Types.TaskStatus;
            estimate?: number | null;
            priority?: Types.TaskPriority | null;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            ownerId?: string | null;
            unreads: number;
            hasMentions: boolean;
            links: Array<{
                __typename: 'TaskLink';
                id: string;
                linkType: Types.TaskLinkType;
                fromId: string;
                toId: string;
                name: string;
                status: Types.TaskStatus;
            }>;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
            acl?: {
                __typename: 'TaskAcl';
                exclusiveAccess?: boolean | null;
                create?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                view?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                update?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                remove?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateOwner?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateLinks?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canToggleAffiliateVisibility?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
            } | null;
        }>;
    };
};

export type IAPICreateTaskMutationVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    task?: Types.InputMaybe<Types.IAPITaskInput>;
}>;

export type IAPICreateTaskMutation = {
    __typename: 'Mutation';
    createTask: {
        __typename: 'TaskMutationResult';
        clientId: string;
        task: {
            __typename: 'Task';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            milestoneId?: string | null;
            epicId?: string | null;
            name: string;
            status: Types.TaskStatus;
            estimate?: number | null;
            priority?: Types.TaskPriority | null;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            ownerId?: string | null;
            unreads: number;
            hasMentions: boolean;
            links: Array<{
                __typename: 'TaskLink';
                id: string;
                linkType: Types.TaskLinkType;
                fromId: string;
                toId: string;
                name: string;
                status: Types.TaskStatus;
            }>;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
            acl?: {
                __typename: 'TaskAcl';
                exclusiveAccess?: boolean | null;
                create?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                view?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                update?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                remove?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateOwner?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateLinks?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canToggleAffiliateVisibility?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
            } | null;
        };
    };
};

export type IAPIUpdateTaskMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
    task?: Types.InputMaybe<Types.IAPITaskInput>;
}>;

export type IAPIUpdateTaskMutation = {
    __typename: 'Mutation';
    updateTask: {
        __typename: 'TaskMutationResult';
        clientId: string;
        task: {
            __typename: 'Task';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            milestoneId?: string | null;
            epicId?: string | null;
            name: string;
            status: Types.TaskStatus;
            estimate?: number | null;
            priority?: Types.TaskPriority | null;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            ownerId?: string | null;
            unreads: number;
            hasMentions: boolean;
            links: Array<{
                __typename: 'TaskLink';
                id: string;
                linkType: Types.TaskLinkType;
                fromId: string;
                toId: string;
                name: string;
                status: Types.TaskStatus;
            }>;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
            acl?: {
                __typename: 'TaskAcl';
                exclusiveAccess?: boolean | null;
                create?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                view?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                update?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                remove?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateOwner?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateLinks?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canToggleAffiliateVisibility?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
            } | null;
        };
    };
};

export type IAPIBulkUpdateTasksMutationVariables = Types.Exact<{
    ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
    task?: Types.InputMaybe<Types.IAPITaskBulkInput>;
}>;

export type IAPIBulkUpdateTasksMutation = { __typename: 'Mutation'; bulkUpdateTasks: boolean };

export type IAPIRemoveTaskMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveTaskMutation = {
    __typename: 'Mutation';
    removeTask: {
        __typename: 'TaskMutationResult';
        clientId: string;
        task: {
            __typename: 'Task';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            milestoneId?: string | null;
            epicId?: string | null;
            name: string;
            status: Types.TaskStatus;
            estimate?: number | null;
            priority?: Types.TaskPriority | null;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            ownerId?: string | null;
            unreads: number;
            hasMentions: boolean;
            links: Array<{
                __typename: 'TaskLink';
                id: string;
                linkType: Types.TaskLinkType;
                fromId: string;
                toId: string;
                name: string;
                status: Types.TaskStatus;
            }>;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
            acl?: {
                __typename: 'TaskAcl';
                exclusiveAccess?: boolean | null;
                create?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                view?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                update?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                remove?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateOwner?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateLinks?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canToggleAffiliateVisibility?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
            } | null;
        };
    };
};

export type IAPIBulkRemoveTasksMutationVariables = Types.Exact<{
    ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type IAPIBulkRemoveTasksMutation = { __typename: 'Mutation'; bulkRemoveTasks: boolean };

export type IAPIUpdateTaskLinkMutationVariables = Types.Exact<{
    fromId: Types.Scalars['ID']['input'];
    toId: Types.Scalars['ID']['input'];
    linkType: Types.TaskLinkType;
}>;

export type IAPIUpdateTaskLinkMutation = {
    __typename: 'Mutation';
    updateTaskLink?: boolean | null;
};

export type IAPIRemoveTaskLinkMutationVariables = Types.Exact<{
    fromId: Types.Scalars['ID']['input'];
    toId: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveTaskLinkMutation = {
    __typename: 'Mutation';
    removeTaskLink?: boolean | null;
};

export type IAPIBroadcastTaskUpdateMutationVariables = Types.Exact<{
    clientId: Types.Scalars['ID']['input'];
    task: Types.IAPITaskBroadcast;
}>;

export type IAPIBroadcastTaskUpdateMutation = {
    __typename: 'Mutation';
    broadcastTaskUpdate: {
        __typename: 'TaskMutationResult';
        clientId: string;
        task: {
            __typename: 'Task';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            milestoneId?: string | null;
            epicId?: string | null;
            name: string;
            status: Types.TaskStatus;
            estimate?: number | null;
            priority?: Types.TaskPriority | null;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            ownerId?: string | null;
            unreads: number;
            hasMentions: boolean;
            links: Array<{
                __typename: 'TaskLink';
                id: string;
                linkType: Types.TaskLinkType;
                fromId: string;
                toId: string;
                name: string;
                status: Types.TaskStatus;
            }>;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
            acl?: {
                __typename: 'TaskAcl';
                exclusiveAccess?: boolean | null;
                create?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                view?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                update?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                remove?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateOwner?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateLinks?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canToggleAffiliateVisibility?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
            } | null;
        };
    };
};

export type IAPIBroadcastTaskRemoveMutationVariables = Types.Exact<{
    clientId: Types.Scalars['ID']['input'];
    task: Types.IAPITaskBroadcast;
}>;

export type IAPIBroadcastTaskRemoveMutation = {
    __typename: 'Mutation';
    broadcastTaskRemove: {
        __typename: 'TaskMutationResult';
        clientId: string;
        task: {
            __typename: 'Task';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            milestoneId?: string | null;
            epicId?: string | null;
            name: string;
            status: Types.TaskStatus;
            estimate?: number | null;
            priority?: Types.TaskPriority | null;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            ownerId?: string | null;
            unreads: number;
            hasMentions: boolean;
            links: Array<{
                __typename: 'TaskLink';
                id: string;
                linkType: Types.TaskLinkType;
                fromId: string;
                toId: string;
                name: string;
                status: Types.TaskStatus;
            }>;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
            acl?: {
                __typename: 'TaskAcl';
                exclusiveAccess?: boolean | null;
                create?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                view?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                update?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                remove?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateOwner?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateLinks?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canToggleAffiliateVisibility?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
            } | null;
        };
    };
};

export type IAPITaskCreatedSubscriptionVariables = Types.Exact<{
    filter: Types.IAPISubscriptionFilter;
}>;

export type IAPITaskCreatedSubscription = {
    __typename: 'Subscription';
    taskCreated?: {
        __typename: 'TaskMutationResult';
        clientId: string;
        task: {
            __typename: 'Task';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            milestoneId?: string | null;
            epicId?: string | null;
            name: string;
            status: Types.TaskStatus;
            estimate?: number | null;
            priority?: Types.TaskPriority | null;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            ownerId?: string | null;
            unreads: number;
            hasMentions: boolean;
            links: Array<{
                __typename: 'TaskLink';
                id: string;
                linkType: Types.TaskLinkType;
                fromId: string;
                toId: string;
                name: string;
                status: Types.TaskStatus;
            }>;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
            acl?: {
                __typename: 'TaskAcl';
                exclusiveAccess?: boolean | null;
                create?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                view?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                update?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                remove?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateOwner?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateLinks?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canToggleAffiliateVisibility?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
            } | null;
        };
    } | null;
};

export type IAPITaskUpdatedSubscriptionVariables = Types.Exact<{
    filter: Types.IAPISubscriptionFilter;
}>;

export type IAPITaskUpdatedSubscription = {
    __typename: 'Subscription';
    taskUpdated?: {
        __typename: 'TaskMutationResult';
        clientId: string;
        task: {
            __typename: 'Task';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            milestoneId?: string | null;
            epicId?: string | null;
            name: string;
            status: Types.TaskStatus;
            estimate?: number | null;
            priority?: Types.TaskPriority | null;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            ownerId?: string | null;
            unreads: number;
            hasMentions: boolean;
            links: Array<{
                __typename: 'TaskLink';
                id: string;
                linkType: Types.TaskLinkType;
                fromId: string;
                toId: string;
                name: string;
                status: Types.TaskStatus;
            }>;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
            acl?: {
                __typename: 'TaskAcl';
                exclusiveAccess?: boolean | null;
                create?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                view?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                update?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                remove?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateOwner?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateLinks?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canToggleAffiliateVisibility?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
            } | null;
        };
    } | null;
};

export type IAPITaskRemovedSubscriptionVariables = Types.Exact<{
    filter: Types.IAPISubscriptionFilter;
}>;

export type IAPITaskRemovedSubscription = {
    __typename: 'Subscription';
    taskRemoved?: {
        __typename: 'TaskMutationResult';
        clientId: string;
        task: {
            __typename: 'Task';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            milestoneId?: string | null;
            epicId?: string | null;
            name: string;
            status: Types.TaskStatus;
            estimate?: number | null;
            priority?: Types.TaskPriority | null;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            ownerId?: string | null;
            unreads: number;
            hasMentions: boolean;
            links: Array<{
                __typename: 'TaskLink';
                id: string;
                linkType: Types.TaskLinkType;
                fromId: string;
                toId: string;
                name: string;
                status: Types.TaskStatus;
            }>;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
            acl?: {
                __typename: 'TaskAcl';
                exclusiveAccess?: boolean | null;
                create?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                view?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                update?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                remove?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateOwner?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canUpdateLinks?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
                canToggleAffiliateVisibility?: {
                    __typename: 'AclEntities';
                    roleIdsCsv?: string | null;
                    userIdsCsv?: string | null;
                    groupIdsCsv?: string | null;
                } | null;
            } | null;
        };
    } | null;
};

export const FetchTaskDocument = gql`
    query FetchTask($id: ID!, $withTriggerMessage: Boolean!) {
        node(id: $id) {
            ... on Task {
                ...TaskFields
                triggerMessage {
                    messageId
                    message @include(if: $withTriggerMessage) {
                        ...MessageServerFields
                    }
                    anchor
                    head
                }
            }
        }
    }
    ${TaskFieldsFragmentDoc}
    ${MessageServerFieldsFragmentDoc}
`;

/**
 * __useFetchTaskQuery__
 *
 * To run a query within a React component, call `useFetchTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withTriggerMessage: // value for 'withTriggerMessage'
 *   },
 * });
 */
export function useFetchTaskQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchTaskQuery, IAPIFetchTaskQueryVariables> &
        ({ variables: IAPIFetchTaskQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchTaskDocument, options);
    return Apollo.useQuery<IAPIFetchTaskQuery, IAPIFetchTaskQueryVariables>(
        FetchTaskDocument,
        options
    );
}
export function useFetchTaskLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchTaskQuery, IAPIFetchTaskQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchTaskQuery, IAPIFetchTaskQueryVariables>(
        FetchTaskDocument,
        options
    );
}
export function useFetchTaskSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchTaskQuery, IAPIFetchTaskQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchTaskQuery, IAPIFetchTaskQueryVariables>(
        FetchTaskDocument,
        options
    );
}
export type FetchTaskQueryHookResult = ReturnType<typeof useFetchTaskQuery>;
export type FetchTaskLazyQueryHookResult = ReturnType<typeof useFetchTaskLazyQuery>;
export type FetchTaskSuspenseQueryHookResult = ReturnType<typeof useFetchTaskSuspenseQuery>;
export type FetchTaskQueryResult = Apollo.QueryResult<
    IAPIFetchTaskQuery,
    IAPIFetchTaskQueryVariables
>;
export const FetchTasksDocument = gql`
    query FetchTasks($filter: TasksFilter!, $sort: [TasksSort!], $pagination: Pagination) {
        tasks(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            tasks {
                ...TaskFields
            }
        }
    }
    ${TaskFieldsFragmentDoc}
`;

/**
 * __useFetchTasksQuery__
 *
 * To run a query within a React component, call `useFetchTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTasksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchTasksQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchTasksQuery, IAPIFetchTasksQueryVariables> &
        ({ variables: IAPIFetchTasksQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchTasksDocument, options);
    return Apollo.useQuery<IAPIFetchTasksQuery, IAPIFetchTasksQueryVariables>(
        FetchTasksDocument,
        options
    );
}
export function useFetchTasksLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchTasksQuery, IAPIFetchTasksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchTasksQuery, IAPIFetchTasksQueryVariables>(
        FetchTasksDocument,
        options
    );
}
export function useFetchTasksSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchTasksQuery, IAPIFetchTasksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchTasksQuery, IAPIFetchTasksQueryVariables>(
        FetchTasksDocument,
        options
    );
}
export type FetchTasksQueryHookResult = ReturnType<typeof useFetchTasksQuery>;
export type FetchTasksLazyQueryHookResult = ReturnType<typeof useFetchTasksLazyQuery>;
export type FetchTasksSuspenseQueryHookResult = ReturnType<typeof useFetchTasksSuspenseQuery>;
export type FetchTasksQueryResult = Apollo.QueryResult<
    IAPIFetchTasksQuery,
    IAPIFetchTasksQueryVariables
>;
export const CreateTaskDocument = gql`
    mutation CreateTask($workspaceId: ID!, $task: TaskInput) {
        createTask(workspaceId: $workspaceId, task: $task) {
            clientId
            task {
                ...TaskFields
            }
        }
    }
    ${TaskFieldsFragmentDoc}
`;
export type IAPICreateTaskMutationFn = Apollo.MutationFunction<
    IAPICreateTaskMutation,
    IAPICreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      task: // value for 'task'
 *   },
 * });
 */
export function useCreateTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateTaskMutation,
        IAPICreateTaskMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateTaskMutation, IAPICreateTaskMutationVariables>(
        CreateTaskDocument,
        options
    );
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<IAPICreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateTaskMutation,
    IAPICreateTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($id: ID!, $task: TaskInput) {
        updateTask(id: $id, task: $task) {
            clientId
            task {
                ...TaskFields
            }
        }
    }
    ${TaskFieldsFragmentDoc}
`;
export type IAPIUpdateTaskMutationFn = Apollo.MutationFunction<
    IAPIUpdateTaskMutation,
    IAPIUpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      task: // value for 'task'
 *   },
 * });
 */
export function useUpdateTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateTaskMutation,
        IAPIUpdateTaskMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateTaskMutation, IAPIUpdateTaskMutationVariables>(
        UpdateTaskDocument,
        options
    );
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<IAPIUpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateTaskMutation,
    IAPIUpdateTaskMutationVariables
>;
export const BulkUpdateTasksDocument = gql`
    mutation BulkUpdateTasks($ids: [ID!]!, $task: TaskBulkInput) {
        bulkUpdateTasks(ids: $ids, task: $task)
    }
`;
export type IAPIBulkUpdateTasksMutationFn = Apollo.MutationFunction<
    IAPIBulkUpdateTasksMutation,
    IAPIBulkUpdateTasksMutationVariables
>;

/**
 * __useBulkUpdateTasksMutation__
 *
 * To run a mutation, you first call `useBulkUpdateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateTasksMutation, { data, loading, error }] = useBulkUpdateTasksMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      task: // value for 'task'
 *   },
 * });
 */
export function useBulkUpdateTasksMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIBulkUpdateTasksMutation,
        IAPIBulkUpdateTasksMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIBulkUpdateTasksMutation, IAPIBulkUpdateTasksMutationVariables>(
        BulkUpdateTasksDocument,
        options
    );
}
export type BulkUpdateTasksMutationHookResult = ReturnType<typeof useBulkUpdateTasksMutation>;
export type BulkUpdateTasksMutationResult = Apollo.MutationResult<IAPIBulkUpdateTasksMutation>;
export type BulkUpdateTasksMutationOptions = Apollo.BaseMutationOptions<
    IAPIBulkUpdateTasksMutation,
    IAPIBulkUpdateTasksMutationVariables
>;
export const RemoveTaskDocument = gql`
    mutation RemoveTask($id: ID!) {
        removeTask(id: $id, action: DELETE) {
            clientId
            task {
                ...TaskFields
            }
        }
    }
    ${TaskFieldsFragmentDoc}
`;
export type IAPIRemoveTaskMutationFn = Apollo.MutationFunction<
    IAPIRemoveTaskMutation,
    IAPIRemoveTaskMutationVariables
>;

/**
 * __useRemoveTaskMutation__
 *
 * To run a mutation, you first call `useRemoveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskMutation, { data, loading, error }] = useRemoveTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveTaskMutation,
        IAPIRemoveTaskMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveTaskMutation, IAPIRemoveTaskMutationVariables>(
        RemoveTaskDocument,
        options
    );
}
export type RemoveTaskMutationHookResult = ReturnType<typeof useRemoveTaskMutation>;
export type RemoveTaskMutationResult = Apollo.MutationResult<IAPIRemoveTaskMutation>;
export type RemoveTaskMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveTaskMutation,
    IAPIRemoveTaskMutationVariables
>;
export const BulkRemoveTasksDocument = gql`
    mutation BulkRemoveTasks($ids: [ID!]!) {
        bulkRemoveTasks(ids: $ids, action: DELETE)
    }
`;
export type IAPIBulkRemoveTasksMutationFn = Apollo.MutationFunction<
    IAPIBulkRemoveTasksMutation,
    IAPIBulkRemoveTasksMutationVariables
>;

/**
 * __useBulkRemoveTasksMutation__
 *
 * To run a mutation, you first call `useBulkRemoveTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkRemoveTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkRemoveTasksMutation, { data, loading, error }] = useBulkRemoveTasksMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkRemoveTasksMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIBulkRemoveTasksMutation,
        IAPIBulkRemoveTasksMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIBulkRemoveTasksMutation, IAPIBulkRemoveTasksMutationVariables>(
        BulkRemoveTasksDocument,
        options
    );
}
export type BulkRemoveTasksMutationHookResult = ReturnType<typeof useBulkRemoveTasksMutation>;
export type BulkRemoveTasksMutationResult = Apollo.MutationResult<IAPIBulkRemoveTasksMutation>;
export type BulkRemoveTasksMutationOptions = Apollo.BaseMutationOptions<
    IAPIBulkRemoveTasksMutation,
    IAPIBulkRemoveTasksMutationVariables
>;
export const UpdateTaskLinkDocument = gql`
    mutation UpdateTaskLink($fromId: ID!, $toId: ID!, $linkType: TaskLinkType!) {
        updateTaskLink(fromId: $fromId, toId: $toId, linkType: $linkType)
    }
`;
export type IAPIUpdateTaskLinkMutationFn = Apollo.MutationFunction<
    IAPIUpdateTaskLinkMutation,
    IAPIUpdateTaskLinkMutationVariables
>;

/**
 * __useUpdateTaskLinkMutation__
 *
 * To run a mutation, you first call `useUpdateTaskLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskLinkMutation, { data, loading, error }] = useUpdateTaskLinkMutation({
 *   variables: {
 *      fromId: // value for 'fromId'
 *      toId: // value for 'toId'
 *      linkType: // value for 'linkType'
 *   },
 * });
 */
export function useUpdateTaskLinkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateTaskLinkMutation,
        IAPIUpdateTaskLinkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateTaskLinkMutation, IAPIUpdateTaskLinkMutationVariables>(
        UpdateTaskLinkDocument,
        options
    );
}
export type UpdateTaskLinkMutationHookResult = ReturnType<typeof useUpdateTaskLinkMutation>;
export type UpdateTaskLinkMutationResult = Apollo.MutationResult<IAPIUpdateTaskLinkMutation>;
export type UpdateTaskLinkMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateTaskLinkMutation,
    IAPIUpdateTaskLinkMutationVariables
>;
export const RemoveTaskLinkDocument = gql`
    mutation RemoveTaskLink($fromId: ID!, $toId: ID!) {
        removeTaskLink(fromId: $fromId, toId: $toId)
    }
`;
export type IAPIRemoveTaskLinkMutationFn = Apollo.MutationFunction<
    IAPIRemoveTaskLinkMutation,
    IAPIRemoveTaskLinkMutationVariables
>;

/**
 * __useRemoveTaskLinkMutation__
 *
 * To run a mutation, you first call `useRemoveTaskLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskLinkMutation, { data, loading, error }] = useRemoveTaskLinkMutation({
 *   variables: {
 *      fromId: // value for 'fromId'
 *      toId: // value for 'toId'
 *   },
 * });
 */
export function useRemoveTaskLinkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveTaskLinkMutation,
        IAPIRemoveTaskLinkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveTaskLinkMutation, IAPIRemoveTaskLinkMutationVariables>(
        RemoveTaskLinkDocument,
        options
    );
}
export type RemoveTaskLinkMutationHookResult = ReturnType<typeof useRemoveTaskLinkMutation>;
export type RemoveTaskLinkMutationResult = Apollo.MutationResult<IAPIRemoveTaskLinkMutation>;
export type RemoveTaskLinkMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveTaskLinkMutation,
    IAPIRemoveTaskLinkMutationVariables
>;
export const BroadcastTaskUpdateDocument = gql`
    mutation BroadcastTaskUpdate($clientId: ID!, $task: TaskBroadcast!) {
        broadcastTaskUpdate(clientId: $clientId, task: $task) {
            clientId
            task {
                ...TaskFields
            }
        }
    }
    ${TaskFieldsFragmentDoc}
`;
export type IAPIBroadcastTaskUpdateMutationFn = Apollo.MutationFunction<
    IAPIBroadcastTaskUpdateMutation,
    IAPIBroadcastTaskUpdateMutationVariables
>;

/**
 * __useBroadcastTaskUpdateMutation__
 *
 * To run a mutation, you first call `useBroadcastTaskUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastTaskUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastTaskUpdateMutation, { data, loading, error }] = useBroadcastTaskUpdateMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      task: // value for 'task'
 *   },
 * });
 */
export function useBroadcastTaskUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIBroadcastTaskUpdateMutation,
        IAPIBroadcastTaskUpdateMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPIBroadcastTaskUpdateMutation,
        IAPIBroadcastTaskUpdateMutationVariables
    >(BroadcastTaskUpdateDocument, options);
}
export type BroadcastTaskUpdateMutationHookResult = ReturnType<
    typeof useBroadcastTaskUpdateMutation
>;
export type BroadcastTaskUpdateMutationResult =
    Apollo.MutationResult<IAPIBroadcastTaskUpdateMutation>;
export type BroadcastTaskUpdateMutationOptions = Apollo.BaseMutationOptions<
    IAPIBroadcastTaskUpdateMutation,
    IAPIBroadcastTaskUpdateMutationVariables
>;
export const BroadcastTaskRemoveDocument = gql`
    mutation BroadcastTaskRemove($clientId: ID!, $task: TaskBroadcast!) {
        broadcastTaskRemove(clientId: $clientId, task: $task) {
            clientId
            task {
                ...TaskFields
            }
        }
    }
    ${TaskFieldsFragmentDoc}
`;
export type IAPIBroadcastTaskRemoveMutationFn = Apollo.MutationFunction<
    IAPIBroadcastTaskRemoveMutation,
    IAPIBroadcastTaskRemoveMutationVariables
>;

/**
 * __useBroadcastTaskRemoveMutation__
 *
 * To run a mutation, you first call `useBroadcastTaskRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastTaskRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastTaskRemoveMutation, { data, loading, error }] = useBroadcastTaskRemoveMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      task: // value for 'task'
 *   },
 * });
 */
export function useBroadcastTaskRemoveMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIBroadcastTaskRemoveMutation,
        IAPIBroadcastTaskRemoveMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPIBroadcastTaskRemoveMutation,
        IAPIBroadcastTaskRemoveMutationVariables
    >(BroadcastTaskRemoveDocument, options);
}
export type BroadcastTaskRemoveMutationHookResult = ReturnType<
    typeof useBroadcastTaskRemoveMutation
>;
export type BroadcastTaskRemoveMutationResult =
    Apollo.MutationResult<IAPIBroadcastTaskRemoveMutation>;
export type BroadcastTaskRemoveMutationOptions = Apollo.BaseMutationOptions<
    IAPIBroadcastTaskRemoveMutation,
    IAPIBroadcastTaskRemoveMutationVariables
>;
export const TaskCreatedDocument = gql`
    subscription TaskCreated($filter: SubscriptionFilter!) {
        taskCreated(filter: $filter) {
            clientId
            task {
                ...TaskFields
            }
        }
    }
    ${TaskFieldsFragmentDoc}
`;

/**
 * __useTaskCreatedSubscription__
 *
 * To run a query within a React component, call `useTaskCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCreatedSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPITaskCreatedSubscription,
        IAPITaskCreatedSubscriptionVariables
    > &
        ({ variables: IAPITaskCreatedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(TaskCreatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPITaskCreatedSubscription,
            IAPITaskCreatedSubscriptionVariables
        >(TaskCreatedDocument, options),
        connected,
    };
}
export type TaskCreatedSubscriptionHookResult = ReturnType<typeof useTaskCreatedSubscription>;
export type TaskCreatedSubscriptionResult = Apollo.SubscriptionResult<IAPITaskCreatedSubscription>;
export const TaskUpdatedDocument = gql`
    subscription TaskUpdated($filter: SubscriptionFilter!) {
        taskUpdated(filter: $filter) {
            clientId
            task {
                ...TaskFields
            }
        }
    }
    ${TaskFieldsFragmentDoc}
`;

/**
 * __useTaskUpdatedSubscription__
 *
 * To run a query within a React component, call `useTaskUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskUpdatedSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPITaskUpdatedSubscription,
        IAPITaskUpdatedSubscriptionVariables
    > &
        ({ variables: IAPITaskUpdatedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(TaskUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPITaskUpdatedSubscription,
            IAPITaskUpdatedSubscriptionVariables
        >(TaskUpdatedDocument, options),
        connected,
    };
}
export type TaskUpdatedSubscriptionHookResult = ReturnType<typeof useTaskUpdatedSubscription>;
export type TaskUpdatedSubscriptionResult = Apollo.SubscriptionResult<IAPITaskUpdatedSubscription>;
export const TaskRemovedDocument = gql`
    subscription TaskRemoved($filter: SubscriptionFilter!) {
        taskRemoved(filter: $filter) {
            clientId
            task {
                ...TaskFields
            }
        }
    }
    ${TaskFieldsFragmentDoc}
`;

/**
 * __useTaskRemovedSubscription__
 *
 * To run a query within a React component, call `useTaskRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskRemovedSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPITaskRemovedSubscription,
        IAPITaskRemovedSubscriptionVariables
    > &
        ({ variables: IAPITaskRemovedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(TaskRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPITaskRemovedSubscription,
            IAPITaskRemovedSubscriptionVariables
        >(TaskRemovedDocument, options),
        connected,
    };
}
export type TaskRemovedSubscriptionHookResult = ReturnType<typeof useTaskRemovedSubscription>;
export type TaskRemovedSubscriptionResult = Apollo.SubscriptionResult<IAPITaskRemovedSubscription>;
