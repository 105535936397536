/* eslint-disable */
import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { EpicFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchEpicQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchEpicQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Emoji' }
        | {
              __typename: 'Epic';
              id: string;
              workspaceId: string;
              projectId: string;
              milestoneId?: string | null;
              name: string;
              createdAt: string;
              updatedAt: string;
          }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Talk' }
        | { __typename: 'Task' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchEpicsQueryVariables = Types.Exact<{
    filter: Types.IAPIEpicsFilter;
}>;

export type IAPIFetchEpicsQuery = {
    __typename: 'Query';
    epics: Array<{
        __typename: 'Epic';
        id: string;
        workspaceId: string;
        projectId: string;
        milestoneId?: string | null;
        name: string;
        createdAt: string;
        updatedAt: string;
    }>;
};

export type IAPICreateEpicMutationVariables = Types.Exact<{
    epic: Types.IAPIEpicInput;
}>;

export type IAPICreateEpicMutation = {
    __typename: 'Mutation';
    updateEpic: {
        __typename: 'Epic';
        id: string;
        workspaceId: string;
        projectId: string;
        milestoneId?: string | null;
        name: string;
        createdAt: string;
        updatedAt: string;
    };
};

export type IAPIRemoveEpicMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveEpicMutation = {
    __typename: 'Mutation';
    removeEpic: {
        __typename: 'Epic';
        id: string;
        workspaceId: string;
        projectId: string;
        milestoneId?: string | null;
        name: string;
        createdAt: string;
        updatedAt: string;
    };
};

export type IAPIUpdateEpicMutationVariables = Types.Exact<{
    epic: Types.IAPIEpicInput;
}>;

export type IAPIUpdateEpicMutation = {
    __typename: 'Mutation';
    updateEpic: {
        __typename: 'Epic';
        id: string;
        workspaceId: string;
        projectId: string;
        milestoneId?: string | null;
        name: string;
        createdAt: string;
        updatedAt: string;
    };
};

export type IAPIEpicRemovedSubscriptionVariables = Types.Exact<{
    workspaceId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type IAPIEpicRemovedSubscription = {
    __typename: 'Subscription';
    epicRemoved?: {
        __typename: 'Epic';
        id: string;
        workspaceId: string;
        projectId: string;
        milestoneId?: string | null;
        name: string;
        createdAt: string;
        updatedAt: string;
    } | null;
};

export type IAPIEpicUpdatedSubscriptionVariables = Types.Exact<{
    workspaceId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type IAPIEpicUpdatedSubscription = {
    __typename: 'Subscription';
    epicUpdated?: {
        __typename: 'Epic';
        id: string;
        workspaceId: string;
        projectId: string;
        milestoneId?: string | null;
        name: string;
        createdAt: string;
        updatedAt: string;
    } | null;
};

export const FetchEpicDocument = gql`
    query FetchEpic($id: ID!) {
        node(id: $id) {
            ...EpicFields
        }
    }
    ${EpicFieldsFragmentDoc}
`;

/**
 * __useFetchEpicQuery__
 *
 * To run a query within a React component, call `useFetchEpicQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEpicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEpicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchEpicQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchEpicQuery, IAPIFetchEpicQueryVariables> &
        ({ variables: IAPIFetchEpicQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchEpicDocument, options);
    return Apollo.useQuery<IAPIFetchEpicQuery, IAPIFetchEpicQueryVariables>(
        FetchEpicDocument,
        options
    );
}
export function useFetchEpicLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchEpicQuery, IAPIFetchEpicQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchEpicQuery, IAPIFetchEpicQueryVariables>(
        FetchEpicDocument,
        options
    );
}
export function useFetchEpicSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchEpicQuery, IAPIFetchEpicQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchEpicQuery, IAPIFetchEpicQueryVariables>(
        FetchEpicDocument,
        options
    );
}
export type FetchEpicQueryHookResult = ReturnType<typeof useFetchEpicQuery>;
export type FetchEpicLazyQueryHookResult = ReturnType<typeof useFetchEpicLazyQuery>;
export type FetchEpicSuspenseQueryHookResult = ReturnType<typeof useFetchEpicSuspenseQuery>;
export type FetchEpicQueryResult = Apollo.QueryResult<
    IAPIFetchEpicQuery,
    IAPIFetchEpicQueryVariables
>;
export const FetchEpicsDocument = gql`
    query FetchEpics($filter: EpicsFilter!) {
        epics(filter: $filter) {
            ...EpicFields
        }
    }
    ${EpicFieldsFragmentDoc}
`;

/**
 * __useFetchEpicsQuery__
 *
 * To run a query within a React component, call `useFetchEpicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEpicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEpicsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchEpicsQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchEpicsQuery, IAPIFetchEpicsQueryVariables> &
        ({ variables: IAPIFetchEpicsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchEpicsDocument, options);
    return Apollo.useQuery<IAPIFetchEpicsQuery, IAPIFetchEpicsQueryVariables>(
        FetchEpicsDocument,
        options
    );
}
export function useFetchEpicsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchEpicsQuery, IAPIFetchEpicsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchEpicsQuery, IAPIFetchEpicsQueryVariables>(
        FetchEpicsDocument,
        options
    );
}
export function useFetchEpicsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchEpicsQuery, IAPIFetchEpicsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchEpicsQuery, IAPIFetchEpicsQueryVariables>(
        FetchEpicsDocument,
        options
    );
}
export type FetchEpicsQueryHookResult = ReturnType<typeof useFetchEpicsQuery>;
export type FetchEpicsLazyQueryHookResult = ReturnType<typeof useFetchEpicsLazyQuery>;
export type FetchEpicsSuspenseQueryHookResult = ReturnType<typeof useFetchEpicsSuspenseQuery>;
export type FetchEpicsQueryResult = Apollo.QueryResult<
    IAPIFetchEpicsQuery,
    IAPIFetchEpicsQueryVariables
>;
export const CreateEpicDocument = gql`
    mutation CreateEpic($epic: EpicInput!) {
        updateEpic(epic: $epic) {
            ...EpicFields
        }
    }
    ${EpicFieldsFragmentDoc}
`;
export type IAPICreateEpicMutationFn = Apollo.MutationFunction<
    IAPICreateEpicMutation,
    IAPICreateEpicMutationVariables
>;

/**
 * __useCreateEpicMutation__
 *
 * To run a mutation, you first call `useCreateEpicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEpicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEpicMutation, { data, loading, error }] = useCreateEpicMutation({
 *   variables: {
 *      epic: // value for 'epic'
 *   },
 * });
 */
export function useCreateEpicMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateEpicMutation,
        IAPICreateEpicMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateEpicMutation, IAPICreateEpicMutationVariables>(
        CreateEpicDocument,
        options
    );
}
export type CreateEpicMutationHookResult = ReturnType<typeof useCreateEpicMutation>;
export type CreateEpicMutationResult = Apollo.MutationResult<IAPICreateEpicMutation>;
export type CreateEpicMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateEpicMutation,
    IAPICreateEpicMutationVariables
>;
export const RemoveEpicDocument = gql`
    mutation RemoveEpic($id: ID!) {
        removeEpic(id: $id, action: DELETE) {
            ...EpicFields
        }
    }
    ${EpicFieldsFragmentDoc}
`;
export type IAPIRemoveEpicMutationFn = Apollo.MutationFunction<
    IAPIRemoveEpicMutation,
    IAPIRemoveEpicMutationVariables
>;

/**
 * __useRemoveEpicMutation__
 *
 * To run a mutation, you first call `useRemoveEpicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEpicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEpicMutation, { data, loading, error }] = useRemoveEpicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEpicMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveEpicMutation,
        IAPIRemoveEpicMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveEpicMutation, IAPIRemoveEpicMutationVariables>(
        RemoveEpicDocument,
        options
    );
}
export type RemoveEpicMutationHookResult = ReturnType<typeof useRemoveEpicMutation>;
export type RemoveEpicMutationResult = Apollo.MutationResult<IAPIRemoveEpicMutation>;
export type RemoveEpicMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveEpicMutation,
    IAPIRemoveEpicMutationVariables
>;
export const UpdateEpicDocument = gql`
    mutation UpdateEpic($epic: EpicInput!) {
        updateEpic(epic: $epic) {
            ...EpicFields
        }
    }
    ${EpicFieldsFragmentDoc}
`;
export type IAPIUpdateEpicMutationFn = Apollo.MutationFunction<
    IAPIUpdateEpicMutation,
    IAPIUpdateEpicMutationVariables
>;

/**
 * __useUpdateEpicMutation__
 *
 * To run a mutation, you first call `useUpdateEpicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEpicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEpicMutation, { data, loading, error }] = useUpdateEpicMutation({
 *   variables: {
 *      epic: // value for 'epic'
 *   },
 * });
 */
export function useUpdateEpicMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateEpicMutation,
        IAPIUpdateEpicMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateEpicMutation, IAPIUpdateEpicMutationVariables>(
        UpdateEpicDocument,
        options
    );
}
export type UpdateEpicMutationHookResult = ReturnType<typeof useUpdateEpicMutation>;
export type UpdateEpicMutationResult = Apollo.MutationResult<IAPIUpdateEpicMutation>;
export type UpdateEpicMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateEpicMutation,
    IAPIUpdateEpicMutationVariables
>;
export const EpicRemovedDocument = gql`
    subscription EpicRemoved($workspaceId: ID) {
        epicRemoved(workspaceId: $workspaceId) {
            ...EpicFields
        }
    }
    ${EpicFieldsFragmentDoc}
`;

/**
 * __useEpicRemovedSubscription__
 *
 * To run a query within a React component, call `useEpicRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEpicRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEpicRemovedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useEpicRemovedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<
        IAPIEpicRemovedSubscription,
        IAPIEpicRemovedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(EpicRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIEpicRemovedSubscription,
            IAPIEpicRemovedSubscriptionVariables
        >(EpicRemovedDocument, options),
        connected,
    };
}
export type EpicRemovedSubscriptionHookResult = ReturnType<typeof useEpicRemovedSubscription>;
export type EpicRemovedSubscriptionResult = Apollo.SubscriptionResult<IAPIEpicRemovedSubscription>;
export const EpicUpdatedDocument = gql`
    subscription EpicUpdated($workspaceId: ID) {
        epicUpdated(workspaceId: $workspaceId) {
            ...EpicFields
        }
    }
    ${EpicFieldsFragmentDoc}
`;

/**
 * __useEpicUpdatedSubscription__
 *
 * To run a query within a React component, call `useEpicUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEpicUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEpicUpdatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useEpicUpdatedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<
        IAPIEpicUpdatedSubscription,
        IAPIEpicUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(EpicUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIEpicUpdatedSubscription,
            IAPIEpicUpdatedSubscriptionVariables
        >(EpicUpdatedDocument, options),
        connected,
    };
}
export type EpicUpdatedSubscriptionHookResult = ReturnType<typeof useEpicUpdatedSubscription>;
export type EpicUpdatedSubscriptionResult = Apollo.SubscriptionResult<IAPIEpicUpdatedSubscription>;
