import { IDBAttachment, IAttachment, ETables, IAPIAttachment } from '@ab-task/types';
import { normalize, ID2GUID, GUID2ID } from './core';

export function attachmentDB2JS(dbAttachment: IDBAttachment): IAttachment {
    return {
        workspaceId: dbAttachment.att_workspace_id,
        messageId: normalize(dbAttachment.att_message_id),
        filename: dbAttachment.att_filename,
        contentType: dbAttachment.att_content_type,
        s3Key: dbAttachment.att_s3_key,
        createdAt: dbAttachment.att_created_at,
    };
}

export function attachmentDB2API(dbAttachment: IDBAttachment): IAPIAttachment {
    return {
        __typename: 'Attachment',
        workspaceId: ID2GUID(ETables.workspaces, dbAttachment.att_workspace_id),
        messageId: ID2GUID(ETables.messages, dbAttachment.att_message_id),
        filename: dbAttachment.att_filename,
        contentType: dbAttachment.att_content_type,
        s3Key: dbAttachment.att_s3_key,
        createdAt: dbAttachment.att_created_at.toISOString(),
    };
}

export function attachmentAPI2JS(apiAttachment: IAPIAttachment): IAttachment {
    return {
        workspaceId: GUID2ID(apiAttachment.workspaceId)[1],
        messageId: GUID2ID(apiAttachment.messageId)[1],
        filename: apiAttachment.filename,
        contentType: apiAttachment.contentType,
        s3Key: apiAttachment.s3Key,
        createdAt: new Date(apiAttachment.createdAt),
    };
}
