import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import {
    MessageFieldsFragmentDoc,
    AttachmentFieldsFragmentDoc,
    SelectionFieldsFragmentDoc,
} from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchMessageQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchMessageQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | {
              __typename: 'Message';
              id: string;
              creatorId: string;
              authorIds: Array<string>;
              content?: string | null;
              version: number;
              position: Types.MessagePosition;
              isFirst: boolean;
              isLast: boolean;
              createdAt: string;
              updatedAt: string;
          }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Task' }
        | { __typename: 'Topic' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchMessagesQueryVariables = Types.Exact<{
    filter: Types.IAPIMessagesFilter;
}>;

export type IAPIFetchMessagesQuery = {
    __typename: 'Query';
    messages: Array<{
        __typename: 'Message';
        id: string;
        creatorId: string;
        authorIds: Array<string>;
        content?: string | null;
        version: number;
        position: Types.MessagePosition;
        isFirst: boolean;
        isLast: boolean;
        createdAt: string;
        updatedAt: string;
    }>;
    attachments: Array<{
        __typename: 'Attachment';
        workspaceId: string;
        messageId: string;
        filename: string;
        contentType: string;
        s3Key: string;
        createdAt: string;
    }>;
};

export type IAPIUpdateMessageMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    messageId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
    clientGuid: Types.Scalars['ID']['input'];
    version: Types.Scalars['Int']['input'];
    steps: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type IAPIUpdateMessageMutation = {
    __typename: 'Mutation';
    updateMessage: {
        __typename: 'UpdateMessageResult';
        channelId: string;
        messageId: string;
        creatorId: string;
        authorIds: Array<string>;
        clientGuid: string;
        steps: Array<string>;
        position: Types.MessagePosition;
        isFirst: boolean;
        createdAt: string;
        updatedAt: string;
    };
};

export type IAPIBroadcastSelectionUpdateMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    clientId: Types.Scalars['ID']['input'];
    selection: Types.IAPISelectionUpdateBroadcast;
}>;

export type IAPIBroadcastSelectionUpdateMutation = {
    __typename: 'Mutation';
    broadcastSelectionUpdate: {
        __typename: 'SelectionUpdateBroadcastResult';
        channelId: string;
        clientId: string;
        selection: {
            __typename: 'Selection';
            userId: string;
            userDisplayName: string;
            messageId?: string | null;
            messageVersion?: number | null;
            type?: Types.SelectionType | null;
            time?: number | null;
            anchor?: number | null;
            head?: number | null;
        };
    };
};

export type IAPIRemoveMessageMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    messageId: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveMessageMutation = {
    __typename: 'Mutation';
    removeMessage: {
        __typename: 'RemoveMessageResult';
        channelId: string;
        messageId: string;
        clientGuid: string;
    };
};

export type IAPIWatchMessagesMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    messageIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type IAPIWatchMessagesMutation = {
    __typename: 'Mutation';
    watchMessages: { __typename: 'WatchMessageResult'; unreads: number; hasMentions: boolean };
};

export type IAPIMentionUserMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    messageId: Types.Scalars['ID']['input'];
    userId: Types.Scalars['ID']['input'];
    mentionGuid: Types.Scalars['String']['input'];
}>;

export type IAPIMentionUserMutation = { __typename: 'Mutation'; mentionUser?: boolean | null };

export type IAPIUnmentionUserMutationVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
    messageId: Types.Scalars['ID']['input'];
    mentionGuid: Types.Scalars['String']['input'];
}>;

export type IAPIUnmentionUserMutation = { __typename: 'Mutation'; unmentionUser?: boolean | null };

export type IAPIMessageUpdatedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPIMessageUpdatedSubscription = {
    __typename: 'Subscription';
    messageUpdated?: {
        __typename: 'UpdateMessageResult';
        messageId: string;
        creatorId: string;
        authorIds: Array<string>;
        clientGuid: string;
        steps: Array<string>;
        position: Types.MessagePosition;
        isFirst: boolean;
        createdAt: string;
        updatedAt: string;
    } | null;
};

export type IAPISelectionUpdatedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPISelectionUpdatedSubscription = {
    __typename: 'Subscription';
    selectionUpdated?: {
        __typename: 'SelectionUpdateBroadcastResult';
        channelId: string;
        clientId: string;
        selection: {
            __typename: 'Selection';
            userId: string;
            userDisplayName: string;
            messageId?: string | null;
            messageVersion?: number | null;
            type?: Types.SelectionType | null;
            time?: number | null;
            anchor?: number | null;
            head?: number | null;
        };
    } | null;
};

export type IAPIMessageRemovedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPIMessageRemovedSubscription = {
    __typename: 'Subscription';
    messageRemoved?: {
        __typename: 'RemoveMessageResult';
        channelId: string;
        messageId: string;
        clientGuid: string;
    } | null;
};

export const FetchMessageDocument = gql`
    query FetchMessage($id: ID!) {
        node(id: $id) {
            ...MessageFields
        }
    }
    ${MessageFieldsFragmentDoc}
`;

/**
 * __useFetchMessageQuery__
 *
 * To run a query within a React component, call `useFetchMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchMessageQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchMessageQuery, IAPIFetchMessageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchMessageDocument, options);
    return Apollo.useQuery<IAPIFetchMessageQuery, IAPIFetchMessageQueryVariables>(
        FetchMessageDocument,
        options
    );
}
export function useFetchMessageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchMessageQuery, IAPIFetchMessageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchMessageQuery, IAPIFetchMessageQueryVariables>(
        FetchMessageDocument,
        options
    );
}
export type FetchMessageQueryHookResult = ReturnType<typeof useFetchMessageQuery>;
export type FetchMessageLazyQueryHookResult = ReturnType<typeof useFetchMessageLazyQuery>;
export type FetchMessageQueryResult = Apollo.QueryResult<
    IAPIFetchMessageQuery,
    IAPIFetchMessageQueryVariables
>;
export const FetchMessagesDocument = gql`
    query FetchMessages($filter: MessagesFilter!) {
        messages(filter: $filter) {
            ...MessageFields
        }
        attachments(filter: $filter) {
            ...AttachmentFields
        }
    }
    ${MessageFieldsFragmentDoc}
    ${AttachmentFieldsFragmentDoc}
`;

/**
 * __useFetchMessagesQuery__
 *
 * To run a query within a React component, call `useFetchMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMessagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMessagesQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchMessagesQuery, IAPIFetchMessagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchMessagesDocument, options);
    return Apollo.useQuery<IAPIFetchMessagesQuery, IAPIFetchMessagesQueryVariables>(
        FetchMessagesDocument,
        options
    );
}
export function useFetchMessagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchMessagesQuery,
        IAPIFetchMessagesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchMessagesQuery, IAPIFetchMessagesQueryVariables>(
        FetchMessagesDocument,
        options
    );
}
export type FetchMessagesQueryHookResult = ReturnType<typeof useFetchMessagesQuery>;
export type FetchMessagesLazyQueryHookResult = ReturnType<typeof useFetchMessagesLazyQuery>;
export type FetchMessagesQueryResult = Apollo.QueryResult<
    IAPIFetchMessagesQuery,
    IAPIFetchMessagesQueryVariables
>;
export const UpdateMessageDocument = gql`
    mutation UpdateMessage(
        $channelId: ID!
        $messageId: ID
        $clientGuid: ID!
        $version: Int!
        $steps: [String!]!
    ) {
        updateMessage(
            channelId: $channelId
            messageId: $messageId
            clientGuid: $clientGuid
            version: $version
            steps: $steps
        ) {
            channelId
            messageId
            creatorId
            authorIds
            clientGuid
            steps
            position
            isFirst
            createdAt
            updatedAt
        }
    }
`;
export type IAPIUpdateMessageMutationFn = Apollo.MutationFunction<
    IAPIUpdateMessageMutation,
    IAPIUpdateMessageMutationVariables
>;

/**
 * __useUpdateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageMutation, { data, loading, error }] = useUpdateMessageMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messageId: // value for 'messageId'
 *      clientGuid: // value for 'clientGuid'
 *      version: // value for 'version'
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useUpdateMessageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateMessageMutation,
        IAPIUpdateMessageMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateMessageMutation, IAPIUpdateMessageMutationVariables>(
        UpdateMessageDocument,
        options
    );
}
export type UpdateMessageMutationHookResult = ReturnType<typeof useUpdateMessageMutation>;
export type UpdateMessageMutationResult = Apollo.MutationResult<IAPIUpdateMessageMutation>;
export type UpdateMessageMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateMessageMutation,
    IAPIUpdateMessageMutationVariables
>;
export const BroadcastSelectionUpdateDocument = gql`
    mutation BroadcastSelectionUpdate(
        $channelId: ID!
        $clientId: ID!
        $selection: SelectionUpdateBroadcast!
    ) {
        broadcastSelectionUpdate(
            channelId: $channelId
            clientId: $clientId
            selection: $selection
        ) {
            channelId
            clientId
            selection {
                ...SelectionFields
            }
        }
    }
    ${SelectionFieldsFragmentDoc}
`;
export type IAPIBroadcastSelectionUpdateMutationFn = Apollo.MutationFunction<
    IAPIBroadcastSelectionUpdateMutation,
    IAPIBroadcastSelectionUpdateMutationVariables
>;

/**
 * __useBroadcastSelectionUpdateMutation__
 *
 * To run a mutation, you first call `useBroadcastSelectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastSelectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastSelectionUpdateMutation, { data, loading, error }] = useBroadcastSelectionUpdateMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      clientId: // value for 'clientId'
 *      selection: // value for 'selection'
 *   },
 * });
 */
export function useBroadcastSelectionUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIBroadcastSelectionUpdateMutation,
        IAPIBroadcastSelectionUpdateMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPIBroadcastSelectionUpdateMutation,
        IAPIBroadcastSelectionUpdateMutationVariables
    >(BroadcastSelectionUpdateDocument, options);
}
export type BroadcastSelectionUpdateMutationHookResult = ReturnType<
    typeof useBroadcastSelectionUpdateMutation
>;
export type BroadcastSelectionUpdateMutationResult =
    Apollo.MutationResult<IAPIBroadcastSelectionUpdateMutation>;
export type BroadcastSelectionUpdateMutationOptions = Apollo.BaseMutationOptions<
    IAPIBroadcastSelectionUpdateMutation,
    IAPIBroadcastSelectionUpdateMutationVariables
>;
export const RemoveMessageDocument = gql`
    mutation RemoveMessage($channelId: ID!, $messageId: ID!) {
        removeMessage(channelId: $channelId, messageId: $messageId) {
            channelId
            messageId
            clientGuid
        }
    }
`;
export type IAPIRemoveMessageMutationFn = Apollo.MutationFunction<
    IAPIRemoveMessageMutation,
    IAPIRemoveMessageMutationVariables
>;

/**
 * __useRemoveMessageMutation__
 *
 * To run a mutation, you first call `useRemoveMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMessageMutation, { data, loading, error }] = useRemoveMessageMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useRemoveMessageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveMessageMutation,
        IAPIRemoveMessageMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveMessageMutation, IAPIRemoveMessageMutationVariables>(
        RemoveMessageDocument,
        options
    );
}
export type RemoveMessageMutationHookResult = ReturnType<typeof useRemoveMessageMutation>;
export type RemoveMessageMutationResult = Apollo.MutationResult<IAPIRemoveMessageMutation>;
export type RemoveMessageMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveMessageMutation,
    IAPIRemoveMessageMutationVariables
>;
export const WatchMessagesDocument = gql`
    mutation WatchMessages($channelId: ID!, $messageIds: [ID!]!) {
        watchMessages(channelId: $channelId, messageIds: $messageIds) {
            unreads
            hasMentions
        }
    }
`;
export type IAPIWatchMessagesMutationFn = Apollo.MutationFunction<
    IAPIWatchMessagesMutation,
    IAPIWatchMessagesMutationVariables
>;

/**
 * __useWatchMessagesMutation__
 *
 * To run a mutation, you first call `useWatchMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWatchMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [watchMessagesMutation, { data, loading, error }] = useWatchMessagesMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messageIds: // value for 'messageIds'
 *   },
 * });
 */
export function useWatchMessagesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIWatchMessagesMutation,
        IAPIWatchMessagesMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIWatchMessagesMutation, IAPIWatchMessagesMutationVariables>(
        WatchMessagesDocument,
        options
    );
}
export type WatchMessagesMutationHookResult = ReturnType<typeof useWatchMessagesMutation>;
export type WatchMessagesMutationResult = Apollo.MutationResult<IAPIWatchMessagesMutation>;
export type WatchMessagesMutationOptions = Apollo.BaseMutationOptions<
    IAPIWatchMessagesMutation,
    IAPIWatchMessagesMutationVariables
>;
export const MentionUserDocument = gql`
    mutation MentionUser($channelId: ID!, $messageId: ID!, $userId: ID!, $mentionGuid: String!) {
        mentionUser(
            channelId: $channelId
            messageId: $messageId
            userId: $userId
            mentionGuid: $mentionGuid
        )
    }
`;
export type IAPIMentionUserMutationFn = Apollo.MutationFunction<
    IAPIMentionUserMutation,
    IAPIMentionUserMutationVariables
>;

/**
 * __useMentionUserMutation__
 *
 * To run a mutation, you first call `useMentionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentionUserMutation, { data, loading, error }] = useMentionUserMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messageId: // value for 'messageId'
 *      userId: // value for 'userId'
 *      mentionGuid: // value for 'mentionGuid'
 *   },
 * });
 */
export function useMentionUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIMentionUserMutation,
        IAPIMentionUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIMentionUserMutation, IAPIMentionUserMutationVariables>(
        MentionUserDocument,
        options
    );
}
export type MentionUserMutationHookResult = ReturnType<typeof useMentionUserMutation>;
export type MentionUserMutationResult = Apollo.MutationResult<IAPIMentionUserMutation>;
export type MentionUserMutationOptions = Apollo.BaseMutationOptions<
    IAPIMentionUserMutation,
    IAPIMentionUserMutationVariables
>;
export const UnmentionUserDocument = gql`
    mutation UnmentionUser($channelId: ID!, $messageId: ID!, $mentionGuid: String!) {
        unmentionUser(channelId: $channelId, messageId: $messageId, mentionGuid: $mentionGuid)
    }
`;
export type IAPIUnmentionUserMutationFn = Apollo.MutationFunction<
    IAPIUnmentionUserMutation,
    IAPIUnmentionUserMutationVariables
>;

/**
 * __useUnmentionUserMutation__
 *
 * To run a mutation, you first call `useUnmentionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmentionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmentionUserMutation, { data, loading, error }] = useUnmentionUserMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messageId: // value for 'messageId'
 *      mentionGuid: // value for 'mentionGuid'
 *   },
 * });
 */
export function useUnmentionUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUnmentionUserMutation,
        IAPIUnmentionUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUnmentionUserMutation, IAPIUnmentionUserMutationVariables>(
        UnmentionUserDocument,
        options
    );
}
export type UnmentionUserMutationHookResult = ReturnType<typeof useUnmentionUserMutation>;
export type UnmentionUserMutationResult = Apollo.MutationResult<IAPIUnmentionUserMutation>;
export type UnmentionUserMutationOptions = Apollo.BaseMutationOptions<
    IAPIUnmentionUserMutation,
    IAPIUnmentionUserMutationVariables
>;
export const MessageUpdatedDocument = gql`
    subscription MessageUpdated($channelId: ID!) {
        messageUpdated(channelId: $channelId) {
            messageId
            creatorId
            authorIds
            clientGuid
            steps
            position
            isFirst
            createdAt
            updatedAt
        }
    }
`;

/**
 * __useMessageUpdatedSubscription__
 *
 * To run a query within a React component, call `useMessageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageUpdatedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useMessageUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIMessageUpdatedSubscription,
        IAPIMessageUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(MessageUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIMessageUpdatedSubscription,
            IAPIMessageUpdatedSubscriptionVariables
        >(MessageUpdatedDocument, options),
        connected,
    };
}
export type MessageUpdatedSubscriptionHookResult = ReturnType<typeof useMessageUpdatedSubscription>;
export type MessageUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIMessageUpdatedSubscription>;
export const SelectionUpdatedDocument = gql`
    subscription SelectionUpdated($channelId: ID!) {
        selectionUpdated(channelId: $channelId) {
            channelId
            clientId
            selection {
                ...SelectionFields
            }
        }
    }
    ${SelectionFieldsFragmentDoc}
`;

/**
 * __useSelectionUpdatedSubscription__
 *
 * To run a query within a React component, call `useSelectionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSelectionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectionUpdatedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useSelectionUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPISelectionUpdatedSubscription,
        IAPISelectionUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(SelectionUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPISelectionUpdatedSubscription,
            IAPISelectionUpdatedSubscriptionVariables
        >(SelectionUpdatedDocument, options),
        connected,
    };
}
export type SelectionUpdatedSubscriptionHookResult = ReturnType<
    typeof useSelectionUpdatedSubscription
>;
export type SelectionUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPISelectionUpdatedSubscription>;
export const MessageRemovedDocument = gql`
    subscription MessageRemoved($channelId: ID!) {
        messageRemoved(channelId: $channelId) {
            channelId
            messageId
            clientGuid
        }
    }
`;

/**
 * __useMessageRemovedSubscription__
 *
 * To run a query within a React component, call `useMessageRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageRemovedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useMessageRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIMessageRemovedSubscription,
        IAPIMessageRemovedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(MessageRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIMessageRemovedSubscription,
            IAPIMessageRemovedSubscriptionVariables
        >(MessageRemovedDocument, options),
        connected,
    };
}
export type MessageRemovedSubscriptionHookResult = ReturnType<typeof useMessageRemovedSubscription>;
export type MessageRemovedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIMessageRemovedSubscription>;
