import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { GroupFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchGroupQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchGroupQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Epic' }
        | {
              __typename: 'Group';
              id: string;
              workspaceId: string;
              name: string;
              description?: string | null;
              memberIds?: Array<string> | null;
              createdAt: string;
              updatedAt: string;
              messagedAt?: string | null;
              unreads: number;
              hasMentions: boolean;
          }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Task' }
        | { __typename: 'Topic' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchGroupsQueryVariables = Types.Exact<{
    filter: Types.IAPIGroupsFilter;
    sort?: Types.InputMaybe<Array<Types.IAPIGroupsSort> | Types.IAPIGroupsSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchGroupsQuery = {
    __typename: 'Query';
    groups: {
        __typename: 'Groups';
        allIds?: Array<number> | null;
        groups: Array<{
            __typename: 'Group';
            id: string;
            workspaceId: string;
            name: string;
            description?: string | null;
            memberIds?: Array<string> | null;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        }>;
    };
};

export type IAPICreateGroupMutationVariables = Types.Exact<{
    group: Types.IAPIGroupInput;
}>;

export type IAPICreateGroupMutation = {
    __typename: 'Mutation';
    updateGroup: {
        __typename: 'Group';
        id: string;
        workspaceId: string;
        name: string;
        description?: string | null;
        memberIds?: Array<string> | null;
        createdAt: string;
        updatedAt: string;
        messagedAt?: string | null;
        unreads: number;
        hasMentions: boolean;
    };
};

export type IAPIUpdateGroupMutationVariables = Types.Exact<{
    group: Types.IAPIGroupInput;
}>;

export type IAPIUpdateGroupMutation = {
    __typename: 'Mutation';
    updateGroup: {
        __typename: 'Group';
        id: string;
        workspaceId: string;
        name: string;
        description?: string | null;
        memberIds?: Array<string> | null;
        createdAt: string;
        updatedAt: string;
        messagedAt?: string | null;
        unreads: number;
        hasMentions: boolean;
    };
};

export type IAPIGroupUpdatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPIGroupUpdatedSubscription = {
    __typename: 'Subscription';
    groupUpdated?: {
        __typename: 'Group';
        id: string;
        workspaceId: string;
        name: string;
        description?: string | null;
        memberIds?: Array<string> | null;
        createdAt: string;
        updatedAt: string;
        messagedAt?: string | null;
        unreads: number;
        hasMentions: boolean;
    } | null;
};

export const FetchGroupDocument = gql`
    query FetchGroup($id: ID!) {
        node(id: $id) {
            ...GroupFields
        }
    }
    ${GroupFieldsFragmentDoc}
`;

/**
 * __useFetchGroupQuery__
 *
 * To run a query within a React component, call `useFetchGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchGroupQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchGroupQuery, IAPIFetchGroupQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchGroupDocument, options);
    return Apollo.useQuery<IAPIFetchGroupQuery, IAPIFetchGroupQueryVariables>(
        FetchGroupDocument,
        options
    );
}
export function useFetchGroupLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchGroupQuery, IAPIFetchGroupQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchGroupQuery, IAPIFetchGroupQueryVariables>(
        FetchGroupDocument,
        options
    );
}
export type FetchGroupQueryHookResult = ReturnType<typeof useFetchGroupQuery>;
export type FetchGroupLazyQueryHookResult = ReturnType<typeof useFetchGroupLazyQuery>;
export type FetchGroupQueryResult = Apollo.QueryResult<
    IAPIFetchGroupQuery,
    IAPIFetchGroupQueryVariables
>;
export const FetchGroupsDocument = gql`
    query FetchGroups($filter: GroupsFilter!, $sort: [GroupsSort!], $pagination: Pagination) {
        groups(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            groups {
                ...GroupFields
            }
        }
    }
    ${GroupFieldsFragmentDoc}
`;

/**
 * __useFetchGroupsQuery__
 *
 * To run a query within a React component, call `useFetchGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchGroupsQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchGroupsQuery, IAPIFetchGroupsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchGroupsDocument, options);
    return Apollo.useQuery<IAPIFetchGroupsQuery, IAPIFetchGroupsQueryVariables>(
        FetchGroupsDocument,
        options
    );
}
export function useFetchGroupsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchGroupsQuery, IAPIFetchGroupsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchGroupsQuery, IAPIFetchGroupsQueryVariables>(
        FetchGroupsDocument,
        options
    );
}
export type FetchGroupsQueryHookResult = ReturnType<typeof useFetchGroupsQuery>;
export type FetchGroupsLazyQueryHookResult = ReturnType<typeof useFetchGroupsLazyQuery>;
export type FetchGroupsQueryResult = Apollo.QueryResult<
    IAPIFetchGroupsQuery,
    IAPIFetchGroupsQueryVariables
>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($group: GroupInput!) {
        updateGroup(group: $group) {
            ...GroupFields
        }
    }
    ${GroupFieldsFragmentDoc}
`;
export type IAPICreateGroupMutationFn = Apollo.MutationFunction<
    IAPICreateGroupMutation,
    IAPICreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useCreateGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateGroupMutation,
        IAPICreateGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateGroupMutation, IAPICreateGroupMutationVariables>(
        CreateGroupDocument,
        options
    );
}
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<IAPICreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateGroupMutation,
    IAPICreateGroupMutationVariables
>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($group: GroupInput!) {
        updateGroup(group: $group) {
            ...GroupFields
        }
    }
    ${GroupFieldsFragmentDoc}
`;
export type IAPIUpdateGroupMutationFn = Apollo.MutationFunction<
    IAPIUpdateGroupMutation,
    IAPIUpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useUpdateGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateGroupMutation,
        IAPIUpdateGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateGroupMutation, IAPIUpdateGroupMutationVariables>(
        UpdateGroupDocument,
        options
    );
}
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<IAPIUpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateGroupMutation,
    IAPIUpdateGroupMutationVariables
>;
export const GroupUpdatedDocument = gql`
    subscription GroupUpdated($workspaceId: ID!) {
        groupUpdated(workspaceId: $workspaceId) {
            ...GroupFields
        }
    }
    ${GroupFieldsFragmentDoc}
`;

/**
 * __useGroupUpdatedSubscription__
 *
 * To run a query within a React component, call `useGroupUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGroupUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupUpdatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGroupUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIGroupUpdatedSubscription,
        IAPIGroupUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(GroupUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIGroupUpdatedSubscription,
            IAPIGroupUpdatedSubscriptionVariables
        >(GroupUpdatedDocument, options),
        connected,
    };
}
export type GroupUpdatedSubscriptionHookResult = ReturnType<typeof useGroupUpdatedSubscription>;
export type GroupUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIGroupUpdatedSubscription>;
