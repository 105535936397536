import { flatten } from 'lodash';
import type * as React from 'react';
import { regEscape } from '.';

export function getArrayNodesReplaced(
    parts: React.ReactNode[],
    placeholder: string,
    replacement: React.ReactNode | ((match: string) => React.ReactNode),
    caseInsensitive?: boolean
) {
    if (placeholder.length === 0) return parts;

    const regExp = new RegExp(regEscape(placeholder), caseInsensitive ? 'ig' : 'g');

    if (parts.length === 1) {
        parts = flatten(parts);
    }

    const result: React.ReactNode[] = [];

    for (const part of parts) {
        if (typeof part === 'string') {
            const subResult: React.ReactNode[] = [];
            let match: RegExpExecArray | null;
            let startIndex = 0;

            while ((match = regExp.exec(part))) {
                const placeholderMatch = match[0];
                const placeholderIndex = match.index;

                subResult.push(part.substring(startIndex, placeholderIndex));
                subResult.push(
                    typeof replacement === 'function' ? replacement(placeholderMatch) : replacement
                );

                startIndex = placeholderIndex + placeholderMatch.length;
            }

            subResult.push(part.substring(startIndex));

            result.push(subResult);
        } else {
            result.push(part);
        }
    }

    return result;
}

export function isEmptyPayload(payload?: Partial<Record<string, any>> | null) {
    if (payload) {
        for (const value of Object.values(payload)) {
            if (typeof value !== 'undefined') {
                return false;
            }
        }
    }

    return true;
}

export function stableStringify(value: any): string {
    return value === undefined ? 'undefined' : JSON.stringify(value, stringifyReplacer);
}

function stringifyReplacer(_key: string, value: any): any {
    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
        value = Object.keys(value)
            .sort()
            .reduce(
                (copy, key) => {
                    copy[key] = value[key];
                    return copy;
                },
                {} as Record<string, any>
            );
    }

    return value;
}
