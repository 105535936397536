import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { UnreadFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchUnreadsQueryVariables = Types.Exact<{
    filter: Types.IAPIUnreadsFilter;
}>;

export type IAPIFetchUnreadsQuery = {
    __typename: 'Query';
    unreads: Array<{
        __typename: 'Unread';
        id: string;
        messageId: string;
        messageCreatedAt: string;
        messageUpdatedAt: string;
        wasMentionedSinceWatched: boolean;
    }>;
};

export const FetchUnreadsDocument = gql`
    query FetchUnreads($filter: UnreadsFilter!) {
        unreads(filter: $filter) {
            ...UnreadFields
        }
    }
    ${UnreadFieldsFragmentDoc}
`;

/**
 * __useFetchUnreadsQuery__
 *
 * To run a query within a React component, call `useFetchUnreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUnreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUnreadsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchUnreadsQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchUnreadsQuery, IAPIFetchUnreadsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchUnreadsDocument, options);
    return Apollo.useQuery<IAPIFetchUnreadsQuery, IAPIFetchUnreadsQueryVariables>(
        FetchUnreadsDocument,
        options
    );
}
export function useFetchUnreadsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchUnreadsQuery, IAPIFetchUnreadsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchUnreadsQuery, IAPIFetchUnreadsQueryVariables>(
        FetchUnreadsDocument,
        options
    );
}
export type FetchUnreadsQueryHookResult = ReturnType<typeof useFetchUnreadsQuery>;
export type FetchUnreadsLazyQueryHookResult = ReturnType<typeof useFetchUnreadsLazyQuery>;
export type FetchUnreadsQueryResult = Apollo.QueryResult<
    IAPIFetchUnreadsQuery,
    IAPIFetchUnreadsQueryVariables
>;
