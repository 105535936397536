import { IDBTalk, ITalk, ETables, IAPITalk, IAPITalkInput } from '@ab-task/types';
import { ID2GUID, ifValue, GUID2ID, ifValueNormalized, normalize } from './core';
import { creatorAlignmentsAPI2JS, creatorAlignmentsJS2API } from './creatorAlignments';
import { triggerMessageAPI2JS } from './triggerMessage';

export function talkDB2JS(dbTalk: IDBTalk): ITalk {
    return {
        id: dbTalk.t_id,
        workspaceId: dbTalk.t_workspace_id,
        order: dbTalk.t_order,
        name: dbTalk.t_name,
        createdAt: dbTalk.t_created_at,
        updatedAt: dbTalk.t_updated_at,
        creatorAlignments: normalize(dbTalk.t_creator_alignments),
        messagedAt: normalize(dbTalk.t_messaged_at),
        unreads: dbTalk.t_unreads,
        hasMentions: dbTalk.t_has_mentions,
        triggerMessage:
            typeof dbTalk.t_trigger_message_id === 'number'
                ? {
                      messageId: dbTalk.t_trigger_message_id,
                      head: normalize(dbTalk.t_trigger_message_head),
                      anchor: normalize(dbTalk.t_trigger_message_anchor),
                  }
                : undefined,
    };
}

export function talkDB2API(dbTalk: IDBTalk): IAPITalk {
    return {
        __typename: 'Talk',
        id: ID2GUID(ETables.talks, dbTalk.t_id),
        workspaceId: ID2GUID(ETables.workspaces, dbTalk.t_workspace_id),
        order: dbTalk.t_order,
        projectId: ifValue(dbTalk.t_project_id, v => ID2GUID(ETables.projects, v)),
        name: dbTalk.t_name,
        createdAt: dbTalk.t_created_at.toISOString(),
        updatedAt: dbTalk.t_updated_at.toISOString(),
        creatorAlignments: dbTalk.t_creator_alignments
            ? creatorAlignmentsJS2API(dbTalk.t_creator_alignments)
            : null,
        messagedAt: dbTalk.t_messaged_at ? dbTalk.t_messaged_at.toISOString() : null,
        unreads: dbTalk.t_unreads,
        hasMentions: dbTalk.t_has_mentions,
        triggerMessage:
            typeof dbTalk.t_trigger_message_id === 'number'
                ? {
                      __typename: 'TriggerMessage',
                      messageId: ID2GUID(ETables.messages, dbTalk.t_trigger_message_id),
                      head: normalize(dbTalk.t_trigger_message_head),
                      anchor: normalize(dbTalk.t_trigger_message_anchor),
                  }
                : null,
    };
}

export function talkAPI2JS(apiTalk: IAPITalk): ITalk {
    return {
        id: GUID2ID(apiTalk.id)[1],
        workspaceId: GUID2ID(apiTalk.workspaceId)[1],
        order: apiTalk.order,
        projectId: ifValueNormalized(apiTalk.projectId, v => (v ? GUID2ID(v)[1] : undefined)),
        name: apiTalk.name,
        createdAt: new Date(apiTalk.createdAt),
        updatedAt: new Date(apiTalk.updatedAt),
        creatorAlignments: apiTalk.creatorAlignments
            ? creatorAlignmentsAPI2JS(apiTalk.creatorAlignments)
            : undefined,
        messagedAt: apiTalk.messagedAt ? new Date(apiTalk.messagedAt) : undefined,
        unreads: apiTalk.unreads,
        hasMentions: apiTalk.hasMentions,
        triggerMessage: apiTalk.triggerMessage
            ? triggerMessageAPI2JS(apiTalk.triggerMessage)
            : undefined,
    };
}

export function talkJS2APIInput(talk: ITalk): IAPITalkInput {
    return {
        name: talk.name,
        projectId: talk.projectId ? ID2GUID(ETables.talks, talk.projectId) : undefined,
    };
}
