import { useTrackSubscription } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { AttachmentFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPICreateAttachmentMutationVariables = Types.Exact<{
    messageId: Types.Scalars['ID']['input'];
    filename: Types.Scalars['String']['input'];
    s3Key: Types.Scalars['String']['input'];
    contentType: Types.Scalars['String']['input'];
}>;

export type IAPICreateAttachmentMutation = {
    __typename: 'Mutation';
    createAttachment: {
        __typename: 'CreateAttachmentResult';
        channelId: string;
        messageId: string;
        uploadURL: string;
    };
};

export type IAPIUpdateAttachmentMutationVariables = Types.Exact<{
    s3Key: Types.Scalars['String']['input'];
    progress?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type IAPIUpdateAttachmentMutation = {
    __typename: 'Mutation';
    updateAttachment: {
        __typename: 'UpdateAttachmentResult';
        channelId: string;
        progress?: number | null;
        attachment: {
            __typename: 'Attachment';
            workspaceId: string;
            messageId: string;
            filename: string;
            contentType: string;
            s3Key: string;
            createdAt: string;
        };
    };
};

export type IAPIRemoveAttachmentMutationVariables = Types.Exact<{
    s3Key: Types.Scalars['String']['input'];
}>;

export type IAPIRemoveAttachmentMutation = {
    __typename: 'Mutation';
    removeAttachment: {
        __typename: 'RemoveAttachmentResult';
        s3Key: string;
        messageId: string;
        channelId: string;
    };
};

export type IAPIAttachmentUpdatedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPIAttachmentUpdatedSubscription = {
    __typename: 'Subscription';
    attachmentUpdated?: {
        __typename: 'UpdateAttachmentResult';
        channelId: string;
        progress?: number | null;
        attachment: {
            __typename: 'Attachment';
            workspaceId: string;
            messageId: string;
            filename: string;
            contentType: string;
            s3Key: string;
            createdAt: string;
        };
    } | null;
};

export type IAPIAttachmentRemovedSubscriptionVariables = Types.Exact<{
    channelId: Types.Scalars['ID']['input'];
}>;

export type IAPIAttachmentRemovedSubscription = {
    __typename: 'Subscription';
    attachmentRemoved?: {
        __typename: 'RemoveAttachmentResult';
        channelId: string;
        s3Key: string;
        messageId: string;
    } | null;
};

export const CreateAttachmentDocument = gql`
    mutation CreateAttachment(
        $messageId: ID!
        $filename: String!
        $s3Key: String!
        $contentType: String!
    ) {
        createAttachment(
            messageId: $messageId
            filename: $filename
            s3Key: $s3Key
            contentType: $contentType
        ) {
            channelId
            messageId
            uploadURL
        }
    }
`;
export type IAPICreateAttachmentMutationFn = Apollo.MutationFunction<
    IAPICreateAttachmentMutation,
    IAPICreateAttachmentMutationVariables
>;

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      filename: // value for 'filename'
 *      s3Key: // value for 's3Key'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useCreateAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateAttachmentMutation,
        IAPICreateAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateAttachmentMutation, IAPICreateAttachmentMutationVariables>(
        CreateAttachmentDocument,
        options
    );
}
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<IAPICreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateAttachmentMutation,
    IAPICreateAttachmentMutationVariables
>;
export const UpdateAttachmentDocument = gql`
    mutation UpdateAttachment($s3Key: String!, $progress: Int) {
        updateAttachment(s3Key: $s3Key, progress: $progress) {
            channelId
            attachment {
                ...AttachmentFields
            }
            progress
        }
    }
    ${AttachmentFieldsFragmentDoc}
`;
export type IAPIUpdateAttachmentMutationFn = Apollo.MutationFunction<
    IAPIUpdateAttachmentMutation,
    IAPIUpdateAttachmentMutationVariables
>;

/**
 * __useUpdateAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentMutation, { data, loading, error }] = useUpdateAttachmentMutation({
 *   variables: {
 *      s3Key: // value for 's3Key'
 *      progress: // value for 'progress'
 *   },
 * });
 */
export function useUpdateAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateAttachmentMutation,
        IAPIUpdateAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateAttachmentMutation, IAPIUpdateAttachmentMutationVariables>(
        UpdateAttachmentDocument,
        options
    );
}
export type UpdateAttachmentMutationHookResult = ReturnType<typeof useUpdateAttachmentMutation>;
export type UpdateAttachmentMutationResult = Apollo.MutationResult<IAPIUpdateAttachmentMutation>;
export type UpdateAttachmentMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateAttachmentMutation,
    IAPIUpdateAttachmentMutationVariables
>;
export const RemoveAttachmentDocument = gql`
    mutation RemoveAttachment($s3Key: String!) {
        removeAttachment(s3Key: $s3Key) {
            s3Key
            messageId
            channelId
        }
    }
`;
export type IAPIRemoveAttachmentMutationFn = Apollo.MutationFunction<
    IAPIRemoveAttachmentMutation,
    IAPIRemoveAttachmentMutationVariables
>;

/**
 * __useRemoveAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAttachmentMutation, { data, loading, error }] = useRemoveAttachmentMutation({
 *   variables: {
 *      s3Key: // value for 's3Key'
 *   },
 * });
 */
export function useRemoveAttachmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveAttachmentMutation,
        IAPIRemoveAttachmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveAttachmentMutation, IAPIRemoveAttachmentMutationVariables>(
        RemoveAttachmentDocument,
        options
    );
}
export type RemoveAttachmentMutationHookResult = ReturnType<typeof useRemoveAttachmentMutation>;
export type RemoveAttachmentMutationResult = Apollo.MutationResult<IAPIRemoveAttachmentMutation>;
export type RemoveAttachmentMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveAttachmentMutation,
    IAPIRemoveAttachmentMutationVariables
>;
export const AttachmentUpdatedDocument = gql`
    subscription AttachmentUpdated($channelId: ID!) {
        attachmentUpdated(channelId: $channelId) {
            channelId
            attachment {
                ...AttachmentFields
            }
            progress
        }
    }
    ${AttachmentFieldsFragmentDoc}
`;

/**
 * __useAttachmentUpdatedSubscription__
 *
 * To run a query within a React component, call `useAttachmentUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentUpdatedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useAttachmentUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIAttachmentUpdatedSubscription,
        IAPIAttachmentUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(AttachmentUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIAttachmentUpdatedSubscription,
            IAPIAttachmentUpdatedSubscriptionVariables
        >(AttachmentUpdatedDocument, options),
        connected,
    };
}
export type AttachmentUpdatedSubscriptionHookResult = ReturnType<
    typeof useAttachmentUpdatedSubscription
>;
export type AttachmentUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIAttachmentUpdatedSubscription>;
export const AttachmentRemovedDocument = gql`
    subscription AttachmentRemoved($channelId: ID!) {
        attachmentRemoved(channelId: $channelId) {
            channelId
            s3Key
            messageId
        }
    }
`;

/**
 * __useAttachmentRemovedSubscription__
 *
 * To run a query within a React component, call `useAttachmentRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentRemovedSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useAttachmentRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIAttachmentRemovedSubscription,
        IAPIAttachmentRemovedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(AttachmentRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIAttachmentRemovedSubscription,
            IAPIAttachmentRemovedSubscriptionVariables
        >(AttachmentRemovedDocument, options),
        connected,
    };
}
export type AttachmentRemovedSubscriptionHookResult = ReturnType<
    typeof useAttachmentRemovedSubscription
>;
export type AttachmentRemovedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIAttachmentRemovedSubscription>;
