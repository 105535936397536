import { TErrorCodes } from '@ab-task/types';
import addErrorCode from './addErrorCode';

export type TFrontendErrorCode = TErrorCodes<typeof FrontendError>;

export class FrontendError extends Error {
    public params?: Record<string, any>;

    private constructor(message: string, params?: Record<string, any>) {
        super();

        this.name = 'FrontendError';
        this.message = message;
        this.params = params;
    }

    @addErrorCode
    public static EDITOR_NOT_FOUND(key: string | number) {
        return new FrontendError(`Editor for key ${key} not found`);
    }

    @addErrorCode
    public static GAP_NOT_FOUND(guid: string) {
        return new FrontendError(`Gap with guid ${guid} not found`);
    }

    /*@addErrorCode
    public static ACTION_UNKNOWN(action: string) {
        return new FrontendError(`Unknown action ${action}`);
    }*/

    @addErrorCode
    public static ACTION_UNKNOWN() {
        return new FrontendError('Unknown action');
    }

    @addErrorCode
    public static RESULT_EXPECTED() {
        return new FrontendError(`Expected result but got undefined`);
    }

    @addErrorCode
    public static DOM_BROKEN(message: string = `Unexpected DOM structure`) {
        return new FrontendError(message);
    }

    @addErrorCode
    public static DATA_UNDEFINED_AFTER_LOADING_FINISHED() {
        return new FrontendError('Data is undefined after loading is finished');
    }

    @addErrorCode
    public static LANGUAGE_NOT_FOUND(locale: string) {
        return new FrontendError(`Can't get language from locale (${locale})`);
    }

    @addErrorCode
    public static CONFIGURATION_ERROR(params?: Record<string, any>) {
        return new FrontendError('Frontend was misconfigured', params);
    }

    @addErrorCode
    public static SUBSCRIPTION_FAILED(params?: Record<string, any>) {
        return new FrontendError('Subscription failed', params);
    }

    @addErrorCode
    public static PAYLOAD_MALFORMED(params?: Record<string, any>) {
        return new FrontendError('Malformed payload', params);
    }

    @addErrorCode
    public static GENERAL(message: string) {
        return new FrontendError(message);
    }
}
