import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIAddEmailFromLandingMutationVariables = Types.Exact<{
    email: Types.Scalars['AWSEmail']['input'];
}>;

export type IAPIAddEmailFromLandingMutation = {
    __typename: 'Mutation';
    addEmailFromLanding: boolean;
};

export const AddEmailFromLandingDocument = gql`
    mutation AddEmailFromLanding($email: AWSEmail!) {
        addEmailFromLanding(email: $email)
    }
`;
export type IAPIAddEmailFromLandingMutationFn = Apollo.MutationFunction<
    IAPIAddEmailFromLandingMutation,
    IAPIAddEmailFromLandingMutationVariables
>;

/**
 * __useAddEmailFromLandingMutation__
 *
 * To run a mutation, you first call `useAddEmailFromLandingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailFromLandingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailFromLandingMutation, { data, loading, error }] = useAddEmailFromLandingMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddEmailFromLandingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIAddEmailFromLandingMutation,
        IAPIAddEmailFromLandingMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IAPIAddEmailFromLandingMutation,
        IAPIAddEmailFromLandingMutationVariables
    >(AddEmailFromLandingDocument, options);
}
export type AddEmailFromLandingMutationHookResult = ReturnType<
    typeof useAddEmailFromLandingMutation
>;
export type AddEmailFromLandingMutationResult =
    Apollo.MutationResult<IAPIAddEmailFromLandingMutation>;
export type AddEmailFromLandingMutationOptions = Apollo.BaseMutationOptions<
    IAPIAddEmailFromLandingMutation,
    IAPIAddEmailFromLandingMutationVariables
>;
