import { GUID2ACTN } from '@ab-task/utils';
import { FieldFunctionOptions, TypePolicies } from '@apollo/client';
import { IAPIQueryTasksArgs, Maybe, TCacheDashboards, TCacheTasks } from '@ab-task/types';
import { rvExpandedDashboardIds } from './reactive-variables';

export const typePolicies: TypePolicies = {
    Query: {
        fields: {
            node: {
                read(existing, { args, toReference }) {
                    if (!args) return;

                    const { id: guid } = args;

                    return toReference({
                        __typename: GUID2ACTN(guid),
                        id: guid,
                    });
                },
            },
            dashboards: {
                merge(existing: Maybe<TCacheDashboards>, incoming: Maybe<TCacheDashboards>) {
                    return incoming;
                },
            },
            tasks: {
                keyArgs: (args, context) => {
                    if (!args) return false;

                    if (args.pagination?.type === 'SCROLL') {
                        return ['filter', 'sort', 'pagination', ['type']];
                    }

                    return Object.keys(args);
                },

                merge(existing: Maybe<TCacheTasks>, incoming: Maybe<TCacheTasks>, options) {
                    if (!existing) return incoming;
                    if (!incoming) return existing;

                    const { args } = options as FieldFunctionOptions<IAPIQueryTasksArgs>;

                    if (args?.pagination?.type === 'SCROLL') {
                        const tasks = [...existing.tasks];
                        const refs = new Set(tasks.map(s => s.__ref));

                        for (const task of incoming.tasks) {
                            if (!refs.has(task.__ref)) {
                                tasks.push(task);
                                refs.add(task.__ref);
                            }
                        }

                        return {
                            ...incoming,
                            allIds: incoming.allIds,
                            tasks,
                        };
                    }

                    return incoming;
                },
            },
        },
    },
    Dashboard: {
        fields: {
            expanded: {
                read(existing, { readField }) {
                    const guid = readField('id');
                    return typeof guid === 'string' && rvExpandedDashboardIds().includes(guid);
                },
            },
        },
    },
};
