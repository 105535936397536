/* eslint-disable */
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import {
    MessageFieldsFragmentDoc,
    AttachmentFieldsFragmentDoc,
    MessageServerBasicFieldsFragmentDoc,
    SelectionFieldsFragmentDoc,
} from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchFeedModelsQueryVariables = Types.Exact<{
    filter: Types.IAPIMessagesFilter;
}>;

export type IAPIFetchFeedModelsQuery = {
    __typename: 'Query';
    messages: Array<{
        __typename: 'Message';
        isIncoming?: boolean | null;
        isReadonly?: boolean | null;
        isBig?: boolean | null;
        attachmentS3Keys?: Array<string> | null;
        id: string;
        creatorId: string;
        workspaceId?: string | null;
        groupId?: string | null;
        talkId?: string | null;
        documentId?: string | null;
        projectId?: string | null;
        milestoneId?: string | null;
        epicId?: string | null;
        taskId?: string | null;
        authorIds: Array<string>;
        isFirst: boolean;
        isLast: boolean;
        createdAt: string;
        updatedAt: string;
        content?: string | null;
        version: number;
        watches: Array<{ __typename: 'MessageWatch'; userId: string; watchedAt: string }>;
        reactions: Array<{
            __typename: 'MessageReaction';
            userId: string;
            emojiId: string;
            reactedAt: string;
        }>;
        followUps: Array<{
            __typename: 'FollowUp';
            itemGuid: string;
            anchor?: number | null;
            head?: number | null;
        }>;
    }>;
    attachments: Array<{
        __typename: 'Attachment';
        messageId: string;
        filename: string;
        contentType: string;
        s3Key: string;
        createdAt: string;
        progress?: number | null;
    }>;
};

export const FetchFeedModelsDocument = gql`
    query FetchFeedModels($filter: MessagesFilter!) {
        messages(filter: $filter) {
            ...MessageFields
        }
        attachments(filter: $filter) {
            ...AttachmentFields
        }
    }
    ${MessageFieldsFragmentDoc}
    ${AttachmentFieldsFragmentDoc}
`;

/**
 * __useFetchFeedModelsQuery__
 *
 * To run a query within a React component, call `useFetchFeedModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFeedModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFeedModelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchFeedModelsQuery(
    baseOptions: Apollo.QueryHookOptions<
        IAPIFetchFeedModelsQuery,
        IAPIFetchFeedModelsQueryVariables
    > &
        ({ variables: IAPIFetchFeedModelsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchFeedModelsDocument, options);
    return Apollo.useQuery<IAPIFetchFeedModelsQuery, IAPIFetchFeedModelsQueryVariables>(
        FetchFeedModelsDocument,
        options
    );
}
export function useFetchFeedModelsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchFeedModelsQuery,
        IAPIFetchFeedModelsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchFeedModelsQuery, IAPIFetchFeedModelsQueryVariables>(
        FetchFeedModelsDocument,
        options
    );
}
export function useFetchFeedModelsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        IAPIFetchFeedModelsQuery,
        IAPIFetchFeedModelsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchFeedModelsQuery, IAPIFetchFeedModelsQueryVariables>(
        FetchFeedModelsDocument,
        options
    );
}
export type FetchFeedModelsQueryHookResult = ReturnType<typeof useFetchFeedModelsQuery>;
export type FetchFeedModelsLazyQueryHookResult = ReturnType<typeof useFetchFeedModelsLazyQuery>;
export type FetchFeedModelsSuspenseQueryHookResult = ReturnType<
    typeof useFetchFeedModelsSuspenseQuery
>;
export type FetchFeedModelsQueryResult = Apollo.QueryResult<
    IAPIFetchFeedModelsQuery,
    IAPIFetchFeedModelsQueryVariables
>;
