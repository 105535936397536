/* eslint-disable */
import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { TalkFieldsFragmentDoc, MessageServerFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchTalkQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
    withTriggerMessage: Types.Scalars['Boolean']['input'];
}>;

export type IAPIFetchTalkQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Emoji' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | {
              __typename: 'Talk';
              id: string;
              order: number;
              workspaceId: string;
              projectId?: string | null;
              name: string;
              createdAt: string;
              updatedAt: string;
              messagedAt?: string | null;
              unreads: number;
              hasMentions: boolean;
              triggerMessage?: {
                  __typename: 'TriggerMessage';
                  messageId: string;
                  anchor?: number | null;
                  head?: number | null;
                  message?: {
                      __typename: 'Message';
                      id: string;
                      creatorId: string;
                      workspaceId?: string | null;
                      groupId?: string | null;
                      talkId?: string | null;
                      documentId?: string | null;
                      projectId?: string | null;
                      milestoneId?: string | null;
                      epicId?: string | null;
                      taskId?: string | null;
                      authorIds: Array<string>;
                      isFirst: boolean;
                      isLast: boolean;
                      createdAt: string;
                      updatedAt: string;
                      content?: string | null;
                      version: number;
                      watches: Array<{
                          __typename: 'MessageWatch';
                          userId: string;
                          watchedAt: string;
                      }>;
                      reactions: Array<{
                          __typename: 'MessageReaction';
                          userId: string;
                          emojiId: string;
                          reactedAt: string;
                      }>;
                      followUps: Array<{
                          __typename: 'FollowUp';
                          itemGuid: string;
                          anchor?: number | null;
                          head?: number | null;
                      }>;
                  } | null;
              } | null;
              creatorAlignments?: {
                  __typename: 'CreatorAlignments';
                  left: Array<string>;
                  right: Array<string>;
              } | null;
          }
        | { __typename: 'Task' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchTalksQueryVariables = Types.Exact<{
    filter: Types.IAPITalksFilter;
    sort?: Types.InputMaybe<Array<Types.IAPITalksSort> | Types.IAPITalksSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchTalksQuery = {
    __typename: 'Query';
    talks: {
        __typename: 'Talks';
        allIds?: Array<number> | null;
        talks: Array<{
            __typename: 'Talk';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
        }>;
    };
};

export type IAPICreateTalkMutationVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    talk?: Types.InputMaybe<Types.IAPITalkInput>;
}>;

export type IAPICreateTalkMutation = {
    __typename: 'Mutation';
    createTalk: {
        __typename: 'TalkMutationResult';
        clientId: string;
        workspaceId: string;
        talk: {
            __typename: 'Talk';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
        };
    };
};

export type IAPIUpdateTalkMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
    talk?: Types.InputMaybe<Types.IAPITalkInput>;
}>;

export type IAPIUpdateTalkMutation = {
    __typename: 'Mutation';
    updateTalk: {
        __typename: 'TalkMutationResult';
        clientId: string;
        workspaceId: string;
        talk: {
            __typename: 'Talk';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
        };
    };
};

export type IAPIRemoveTalkMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveTalkMutation = {
    __typename: 'Mutation';
    removeTalk: {
        __typename: 'TalkMutationResult';
        clientId: string;
        workspaceId: string;
        talk: {
            __typename: 'Talk';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
        };
    };
};

export type IAPITalkCreatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPITalkCreatedSubscription = {
    __typename: 'Subscription';
    talkCreated?: {
        __typename: 'TalkMutationResult';
        clientId: string;
        talk: {
            __typename: 'Talk';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
        };
    } | null;
};

export type IAPITalkUpdatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPITalkUpdatedSubscription = {
    __typename: 'Subscription';
    talkUpdated?: {
        __typename: 'TalkMutationResult';
        clientId: string;
        talk: {
            __typename: 'Talk';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
        };
    } | null;
};

export type IAPITalkRemovedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPITalkRemovedSubscription = {
    __typename: 'Subscription';
    talkRemoved?: {
        __typename: 'TalkMutationResult';
        clientId: string;
        talk: {
            __typename: 'Talk';
            id: string;
            order: number;
            workspaceId: string;
            projectId?: string | null;
            name: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
            creatorAlignments?: {
                __typename: 'CreatorAlignments';
                left: Array<string>;
                right: Array<string>;
            } | null;
            triggerMessage?: {
                __typename: 'TriggerMessage';
                messageId: string;
                anchor?: number | null;
                head?: number | null;
            } | null;
        };
    } | null;
};

export const FetchTalkDocument = gql`
    query FetchTalk($id: ID!, $withTriggerMessage: Boolean!) {
        node(id: $id) {
            ... on Talk {
                ...TalkFields
                triggerMessage {
                    messageId
                    message @include(if: $withTriggerMessage) {
                        ...MessageServerFields
                    }
                    anchor
                    head
                }
            }
        }
    }
    ${TalkFieldsFragmentDoc}
    ${MessageServerFieldsFragmentDoc}
`;

/**
 * __useFetchTalkQuery__
 *
 * To run a query within a React component, call `useFetchTalkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTalkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTalkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withTriggerMessage: // value for 'withTriggerMessage'
 *   },
 * });
 */
export function useFetchTalkQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchTalkQuery, IAPIFetchTalkQueryVariables> &
        ({ variables: IAPIFetchTalkQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchTalkDocument, options);
    return Apollo.useQuery<IAPIFetchTalkQuery, IAPIFetchTalkQueryVariables>(
        FetchTalkDocument,
        options
    );
}
export function useFetchTalkLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchTalkQuery, IAPIFetchTalkQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchTalkQuery, IAPIFetchTalkQueryVariables>(
        FetchTalkDocument,
        options
    );
}
export function useFetchTalkSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchTalkQuery, IAPIFetchTalkQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchTalkQuery, IAPIFetchTalkQueryVariables>(
        FetchTalkDocument,
        options
    );
}
export type FetchTalkQueryHookResult = ReturnType<typeof useFetchTalkQuery>;
export type FetchTalkLazyQueryHookResult = ReturnType<typeof useFetchTalkLazyQuery>;
export type FetchTalkSuspenseQueryHookResult = ReturnType<typeof useFetchTalkSuspenseQuery>;
export type FetchTalkQueryResult = Apollo.QueryResult<
    IAPIFetchTalkQuery,
    IAPIFetchTalkQueryVariables
>;
export const FetchTalksDocument = gql`
    query FetchTalks($filter: TalksFilter!, $sort: [TalksSort!], $pagination: Pagination) {
        talks(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            talks {
                ...TalkFields
            }
        }
    }
    ${TalkFieldsFragmentDoc}
`;

/**
 * __useFetchTalksQuery__
 *
 * To run a query within a React component, call `useFetchTalksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTalksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTalksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchTalksQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchTalksQuery, IAPIFetchTalksQueryVariables> &
        ({ variables: IAPIFetchTalksQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchTalksDocument, options);
    return Apollo.useQuery<IAPIFetchTalksQuery, IAPIFetchTalksQueryVariables>(
        FetchTalksDocument,
        options
    );
}
export function useFetchTalksLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchTalksQuery, IAPIFetchTalksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchTalksQuery, IAPIFetchTalksQueryVariables>(
        FetchTalksDocument,
        options
    );
}
export function useFetchTalksSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchTalksQuery, IAPIFetchTalksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchTalksQuery, IAPIFetchTalksQueryVariables>(
        FetchTalksDocument,
        options
    );
}
export type FetchTalksQueryHookResult = ReturnType<typeof useFetchTalksQuery>;
export type FetchTalksLazyQueryHookResult = ReturnType<typeof useFetchTalksLazyQuery>;
export type FetchTalksSuspenseQueryHookResult = ReturnType<typeof useFetchTalksSuspenseQuery>;
export type FetchTalksQueryResult = Apollo.QueryResult<
    IAPIFetchTalksQuery,
    IAPIFetchTalksQueryVariables
>;
export const CreateTalkDocument = gql`
    mutation CreateTalk($workspaceId: ID!, $talk: TalkInput) {
        createTalk(workspaceId: $workspaceId, talk: $talk) {
            clientId
            workspaceId
            talk {
                ...TalkFields
            }
        }
    }
    ${TalkFieldsFragmentDoc}
`;
export type IAPICreateTalkMutationFn = Apollo.MutationFunction<
    IAPICreateTalkMutation,
    IAPICreateTalkMutationVariables
>;

/**
 * __useCreateTalkMutation__
 *
 * To run a mutation, you first call `useCreateTalkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTalkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTalkMutation, { data, loading, error }] = useCreateTalkMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      talk: // value for 'talk'
 *   },
 * });
 */
export function useCreateTalkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateTalkMutation,
        IAPICreateTalkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateTalkMutation, IAPICreateTalkMutationVariables>(
        CreateTalkDocument,
        options
    );
}
export type CreateTalkMutationHookResult = ReturnType<typeof useCreateTalkMutation>;
export type CreateTalkMutationResult = Apollo.MutationResult<IAPICreateTalkMutation>;
export type CreateTalkMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateTalkMutation,
    IAPICreateTalkMutationVariables
>;
export const UpdateTalkDocument = gql`
    mutation UpdateTalk($id: ID!, $talk: TalkInput) {
        updateTalk(id: $id, talk: $talk) {
            clientId
            workspaceId
            talk {
                ...TalkFields
            }
        }
    }
    ${TalkFieldsFragmentDoc}
`;
export type IAPIUpdateTalkMutationFn = Apollo.MutationFunction<
    IAPIUpdateTalkMutation,
    IAPIUpdateTalkMutationVariables
>;

/**
 * __useUpdateTalkMutation__
 *
 * To run a mutation, you first call `useUpdateTalkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalkMutation, { data, loading, error }] = useUpdateTalkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      talk: // value for 'talk'
 *   },
 * });
 */
export function useUpdateTalkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateTalkMutation,
        IAPIUpdateTalkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateTalkMutation, IAPIUpdateTalkMutationVariables>(
        UpdateTalkDocument,
        options
    );
}
export type UpdateTalkMutationHookResult = ReturnType<typeof useUpdateTalkMutation>;
export type UpdateTalkMutationResult = Apollo.MutationResult<IAPIUpdateTalkMutation>;
export type UpdateTalkMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateTalkMutation,
    IAPIUpdateTalkMutationVariables
>;
export const RemoveTalkDocument = gql`
    mutation RemoveTalk($id: ID!) {
        removeTalk(id: $id, action: DELETE) {
            clientId
            workspaceId
            talk {
                ...TalkFields
            }
        }
    }
    ${TalkFieldsFragmentDoc}
`;
export type IAPIRemoveTalkMutationFn = Apollo.MutationFunction<
    IAPIRemoveTalkMutation,
    IAPIRemoveTalkMutationVariables
>;

/**
 * __useRemoveTalkMutation__
 *
 * To run a mutation, you first call `useRemoveTalkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTalkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTalkMutation, { data, loading, error }] = useRemoveTalkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTalkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveTalkMutation,
        IAPIRemoveTalkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveTalkMutation, IAPIRemoveTalkMutationVariables>(
        RemoveTalkDocument,
        options
    );
}
export type RemoveTalkMutationHookResult = ReturnType<typeof useRemoveTalkMutation>;
export type RemoveTalkMutationResult = Apollo.MutationResult<IAPIRemoveTalkMutation>;
export type RemoveTalkMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveTalkMutation,
    IAPIRemoveTalkMutationVariables
>;
export const TalkCreatedDocument = gql`
    subscription TalkCreated($workspaceId: ID!) {
        talkCreated(workspaceId: $workspaceId) {
            clientId
            talk {
                ...TalkFields
            }
        }
    }
    ${TalkFieldsFragmentDoc}
`;

/**
 * __useTalkCreatedSubscription__
 *
 * To run a query within a React component, call `useTalkCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTalkCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalkCreatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useTalkCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPITalkCreatedSubscription,
        IAPITalkCreatedSubscriptionVariables
    > &
        ({ variables: IAPITalkCreatedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(TalkCreatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPITalkCreatedSubscription,
            IAPITalkCreatedSubscriptionVariables
        >(TalkCreatedDocument, options),
        connected,
    };
}
export type TalkCreatedSubscriptionHookResult = ReturnType<typeof useTalkCreatedSubscription>;
export type TalkCreatedSubscriptionResult = Apollo.SubscriptionResult<IAPITalkCreatedSubscription>;
export const TalkUpdatedDocument = gql`
    subscription TalkUpdated($workspaceId: ID!) {
        talkUpdated(workspaceId: $workspaceId) {
            clientId
            talk {
                ...TalkFields
            }
        }
    }
    ${TalkFieldsFragmentDoc}
`;

/**
 * __useTalkUpdatedSubscription__
 *
 * To run a query within a React component, call `useTalkUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTalkUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalkUpdatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useTalkUpdatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPITalkUpdatedSubscription,
        IAPITalkUpdatedSubscriptionVariables
    > &
        ({ variables: IAPITalkUpdatedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(TalkUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPITalkUpdatedSubscription,
            IAPITalkUpdatedSubscriptionVariables
        >(TalkUpdatedDocument, options),
        connected,
    };
}
export type TalkUpdatedSubscriptionHookResult = ReturnType<typeof useTalkUpdatedSubscription>;
export type TalkUpdatedSubscriptionResult = Apollo.SubscriptionResult<IAPITalkUpdatedSubscription>;
export const TalkRemovedDocument = gql`
    subscription TalkRemoved($workspaceId: ID!) {
        talkRemoved(workspaceId: $workspaceId) {
            clientId
            talk {
                ...TalkFields
            }
        }
    }
    ${TalkFieldsFragmentDoc}
`;

/**
 * __useTalkRemovedSubscription__
 *
 * To run a query within a React component, call `useTalkRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTalkRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalkRemovedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useTalkRemovedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPITalkRemovedSubscription,
        IAPITalkRemovedSubscriptionVariables
    > &
        ({ variables: IAPITalkRemovedSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(TalkRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPITalkRemovedSubscription,
            IAPITalkRemovedSubscriptionVariables
        >(TalkRemovedDocument, options),
        connected,
    };
}
export type TalkRemovedSubscriptionHookResult = ReturnType<typeof useTalkRemovedSubscription>;
export type TalkRemovedSubscriptionResult = Apollo.SubscriptionResult<IAPITalkRemovedSubscription>;
