import { ApolloClient, DefaultContext, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { FragmentMap } from '@apollo/client/utilities';
import { FieldNode, GraphQLError } from 'graphql';

export enum ETypeNames {
    'Dashboard' = 'Dashboard',
    'Epic' = 'Epic',
    'Group' = 'Group',
    'Message' = 'Message',
    'Project' = 'Project',
    'Task' = 'Task',
    'Topic' = 'Topic',
    'Document' = 'Document',
    'User' = 'User',
    'Workspace' = 'Workspace',
}

interface IBaseConfig {
    clientId: string;
    region: string;
    appSyncEndpoint: string;
}

export interface INodeConfig extends IBaseConfig {
    environment: 'node';
    appToken: string;
    workspaceId: string;
}

export interface IBrowserConfig extends IBaseConfig {
    environment: 'browser';
    identityPoolId: string;
}

export type TApolloClientConfig = INodeConfig | IBrowserConfig;

export function isNodeConfig(config: TApolloClientConfig): config is INodeConfig {
    return config.environment === 'node';
}

export function isBrowserConfig(config: TApolloClientConfig): config is IBrowserConfig {
    return config.environment === 'browser';
}

export interface ILocalResolvers {
    [key: string]: {
        [field: string]: ILocalResolver;
    };
}

export interface ILocalResolver<P = any, R = any, RV = unknown> {
    (
        rootValue: RV,
        payload: P,
        context: ILocalContext,
        info?: {
            field: FieldNode;
            fragmentMap: FragmentMap;
        }
    ): R;
}

export interface ILocalContext {
    cache: InMemoryCache;
}

export interface IOperationContext extends DefaultContext {
    client: ApolloClient<NormalizedCacheObject>;
}

export type TGQLError = GraphQLError & {
    errorType: string;
    errorInfo: Record<string, any>;
    handled?: true;
};

export type TNetworkError = NetworkError & {
    errors: Array<Error & { handled?: true }> | undefined;
};
