import defaultMainTranslation from './translations/en.json';
import defaultGenTranslation from './translations/en.gen.json';
import { firstLetterToUpperCase } from '@ab-task/utils';
import { FrontendError } from '@ab-task/errors';
import { ITranslationParams, ITranslations, TTranslationKeys } from './types';
import getTranslation from './utils/getTranslation';

const defaultTranslation = Object.assign({}, defaultMainTranslation, defaultGenTranslation);

export { default as getTimeZones } from './utils/getTimeZones';

// local "state"
let translations: ITranslations = {};
export let timeZone: string;
export let locale: string;

switchLocale('en-US');
switchTimezone('UTC');
// ----

// locale
export async function switchLocale(lc: string) {
    locale = lc;

    const lang = /^([a-z]+)-/.exec(locale);

    if (lang === null) {
        throw FrontendError.LANGUAGE_NOT_FOUND(locale);
    }

    switch (lang[1]) {
        case 'en':
            translations = defaultTranslation;
            break;

        default:
            translations = defaultTranslation;
    }
}

// datetime
export function switchTimezone(tz: string) {
    timeZone = tz;
}

export function d(dt: string | number | Date, options?: Intl.DateTimeFormatOptions) {
    const dateTime = new Date(dt);

    options = {
        ...options,
        timeZone,
    };

    return new Intl.DateTimeFormat(locale, options).format(dateTime);
}

// translations
export function tj(translationKey: string, params?: ITranslationParams) {
    if (translations[translationKey] !== undefined) {
        return getTranslation(locale, translations, translationKey, params);
    }

    if (defaultTranslation[translationKey as TTranslationKeys] !== undefined) {
        // fallback to EN
        console.warn(`Missing translation key "${translationKey}" in locale "${locale}"`);
        return [getTranslation(locale, defaultTranslation, translationKey, params)];
    }

    console.warn(`Missing translation key "${translationKey}"`);

    return [`[${translationKey}]`];
}

export function t(translationKey: string, params?: ITranslationParams) {
    return tj(translationKey, params).flat(3).join('');
}

export function td(translationKey: string, params?: ITranslationParams) {
    return t(translationKey, params) + '.';
}

export function T(translationKey: string, params?: ITranslationParams) {
    return firstLetterToUpperCase(t(translationKey, params));
}

export function Td(translationKey: string, params?: ITranslationParams) {
    return T(translationKey, params) + '.';
}

export function TT(translationKey: string, params?: ITranslationParams) {
    return t(translationKey, params).toUpperCase();
}

export function TTd(translationKey: string, params?: ITranslationParams) {
    return TT(translationKey, params) + '.';
}
