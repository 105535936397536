import { ETables, IDBMessage, IMessage, IAPIMessage } from '@ab-task/types';
import { normalize, ID2GUID, GUID2ID } from './core';
import { CoreError } from '@ab-task/errors';

export function messageDB2JS(dbMessage: IDBMessage): IMessage {
    return {
        id: dbMessage.m_id,

        content: normalize(dbMessage.m_content),
        version: dbMessage.m_version,
        position: dbMessage.m_position ? 'RIGHT' : 'LEFT',
        isFirst: dbMessage.m_first,
        isLast: dbMessage.m_first,

        creatorId: dbMessage.m_created_by,
        authorIds: dbMessage.m_author_ids.split(',').map(idStr => Number(idStr)),
        createdAt: dbMessage.m_created_at,
        updatedAt: dbMessage.m_updated_at,

        workspaceId: normalize(dbMessage.m_workspace_id),
        groupId: normalize(dbMessage.m_group_id),
        topicId: normalize(dbMessage.m_topic_id),
        documentId: normalize(dbMessage.m_document_id),
        projectId: normalize(dbMessage.m_project_id),
        milestoneId: normalize(dbMessage.m_milestone_id),
        epicId: normalize(dbMessage.m_epic_id),
        taskId: normalize(dbMessage.m_task_id),
    };
}

export function messageDB2API(dbMessage: IDBMessage): IAPIMessage {
    return {
        __typename: 'Message',
        id: ID2GUID(ETables.messages, dbMessage.m_id),
        creatorId: ID2GUID(ETables.users, dbMessage.m_created_by),
        authorIds: dbMessage.m_author_ids.split(',').map(id => ID2GUID(ETables.users, Number(id))),
        content: dbMessage.m_content,
        version: dbMessage.m_version,
        position: dbMessage.m_position ? 'RIGHT' : 'LEFT',
        isFirst: dbMessage.m_first,
        isLast: dbMessage.m_last,
        createdAt: dbMessage.m_created_at.toISOString(),
        updatedAt: dbMessage.m_updated_at.toISOString(),
    };
}

export function messageAPI2JS(apiMessage: IAPIMessage): IMessage {
    return {
        id: GUID2ID(apiMessage.id)[1],

        content: normalize(apiMessage.content),
        version: apiMessage.version,
        position: apiMessage.position,
        isFirst: apiMessage.isFirst,
        isLast: apiMessage.isLast,

        creatorId: GUID2ID(apiMessage.creatorId)[1],
        authorIds: apiMessage.authorIds.map(guid => GUID2ID(guid)[1]),
        createdAt: new Date(apiMessage.createdAt),
        updatedAt: new Date(apiMessage.updatedAt),
    };
}

export const message2ChannelGuid = (message: IMessage) => {
    const { workspaceId, epicId, groupId, projectId, taskId, topicId, documentId } = message;

    if (workspaceId) {
        return ID2GUID('workspaces', workspaceId);
    }
    if (epicId) {
        return ID2GUID('epics', epicId);
    }
    if (groupId) {
        return ID2GUID('groups', groupId);
    }
    if (projectId) {
        return ID2GUID('projects', projectId);
    }
    if (taskId) {
        return ID2GUID('tasks', taskId);
    }
    if (topicId) {
        return ID2GUID('topics', topicId);
    }
    if (documentId) {
        return ID2GUID('documents', documentId);
    }

    throw CoreError.TYPE_ADAPTOR_FAILED({ info: "ChannelId wasn't found in message" });
};
