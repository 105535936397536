import type { NodeSpec, MarkSpec, Schema } from 'prosemirror-model';

export type TNodeName =
    | 'doc'
    | 'paragraph'
    | 'blockquote'
    | 'horizontal_rule'
    | 'heading'
    | 'code_block'
    | 'text'
    | 'image'
    | 'hard_break'
    | 'attachment'
    | 'attached_image'
    | 'pending_attached_image'
    | 'mention'
    | 'ordered_list'
    | 'bullet_list'
    | 'list_item';

export type TNodes = { [name in TNodeName]: NodeSpec };

export type TMarkName = 'link' | 'em' | 'strong' | 'code';

export type TMarks = { [name in TMarkName]: MarkSpec };

export type TSchema = Schema<TNodeName, TMarkName>;
