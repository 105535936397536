import { useTrackSubscription } from '@ab-task/apollo';
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { ProjectFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchProjectQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchProjectQuery = {
    __typename: 'Query';
    node?:
        | { __typename: 'Bill' }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | {
              __typename: 'Project';
              id: string;
              workspaceId: string;
              name: string;
              status: Types.ProjectStatus;
              creatorId: string;
              createdAt: string;
              updatedAt: string;
              messagedAt?: string | null;
              unreads: number;
              hasMentions: boolean;
          }
        | { __typename: 'Role' }
        | { __typename: 'Task' }
        | { __typename: 'Topic' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchProjectsQueryVariables = Types.Exact<{
    filter: Types.IAPIProjectsFilter;
    sort?: Types.InputMaybe<Array<Types.IAPIProjectsSort> | Types.IAPIProjectsSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchProjectsQuery = {
    __typename: 'Query';
    projects: {
        __typename: 'Projects';
        allIds?: Array<number> | null;
        projects: Array<{
            __typename: 'Project';
            id: string;
            workspaceId: string;
            name: string;
            status: Types.ProjectStatus;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        }>;
    };
};

export type IAPICreateProjectMutationVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
    project?: Types.InputMaybe<Types.IAPIProjectInput>;
}>;

export type IAPICreateProjectMutation = {
    __typename: 'Mutation';
    createProject: {
        __typename: 'ProjectMutationResult';
        clientId: string;
        workspaceId: string;
        project: {
            __typename: 'Project';
            id: string;
            workspaceId: string;
            name: string;
            status: Types.ProjectStatus;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    };
};

export type IAPIUpdateProjectMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
    project?: Types.InputMaybe<Types.IAPIProjectInput>;
}>;

export type IAPIUpdateProjectMutation = {
    __typename: 'Mutation';
    updateProject: {
        __typename: 'ProjectMutationResult';
        clientId: string;
        workspaceId: string;
        project: {
            __typename: 'Project';
            id: string;
            workspaceId: string;
            name: string;
            status: Types.ProjectStatus;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    };
};

export type IAPIRemoveProjectMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIRemoveProjectMutation = {
    __typename: 'Mutation';
    removeProject: {
        __typename: 'ProjectMutationResult';
        clientId: string;
        workspaceId: string;
        project: {
            __typename: 'Project';
            id: string;
            workspaceId: string;
            name: string;
            status: Types.ProjectStatus;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    };
};

export type IAPIProjectCreatedSubscriptionVariables = Types.Exact<{
    workspaceId: Types.Scalars['ID']['input'];
}>;

export type IAPIProjectCreatedSubscription = {
    __typename: 'Subscription';
    projectCreated?: {
        __typename: 'ProjectMutationResult';
        clientId: string;
        project: {
            __typename: 'Project';
            id: string;
            workspaceId: string;
            name: string;
            status: Types.ProjectStatus;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    } | null;
};

export type IAPIProjectUpdatedSubscriptionVariables = Types.Exact<{
    workspaceId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type IAPIProjectUpdatedSubscription = {
    __typename: 'Subscription';
    projectUpdated?: {
        __typename: 'ProjectMutationResult';
        clientId: string;
        project: {
            __typename: 'Project';
            id: string;
            workspaceId: string;
            name: string;
            status: Types.ProjectStatus;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    } | null;
};

export type IAPIProjectRemovedSubscriptionVariables = Types.Exact<{
    workspaceId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type IAPIProjectRemovedSubscription = {
    __typename: 'Subscription';
    projectRemoved?: {
        __typename: 'ProjectMutationResult';
        clientId: string;
        project: {
            __typename: 'Project';
            id: string;
            workspaceId: string;
            name: string;
            status: Types.ProjectStatus;
            creatorId: string;
            createdAt: string;
            updatedAt: string;
            messagedAt?: string | null;
            unreads: number;
            hasMentions: boolean;
        };
    } | null;
};

export const FetchProjectDocument = gql`
    query FetchProject($id: ID!) {
        node(id: $id) {
            ...ProjectFields
        }
    }
    ${ProjectFieldsFragmentDoc}
`;

/**
 * __useFetchProjectQuery__
 *
 * To run a query within a React component, call `useFetchProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchProjectQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchProjectQuery, IAPIFetchProjectQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchProjectDocument, options);
    return Apollo.useQuery<IAPIFetchProjectQuery, IAPIFetchProjectQueryVariables>(
        FetchProjectDocument,
        options
    );
}
export function useFetchProjectLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchProjectQuery, IAPIFetchProjectQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchProjectQuery, IAPIFetchProjectQueryVariables>(
        FetchProjectDocument,
        options
    );
}
export type FetchProjectQueryHookResult = ReturnType<typeof useFetchProjectQuery>;
export type FetchProjectLazyQueryHookResult = ReturnType<typeof useFetchProjectLazyQuery>;
export type FetchProjectQueryResult = Apollo.QueryResult<
    IAPIFetchProjectQuery,
    IAPIFetchProjectQueryVariables
>;
export const FetchProjectsDocument = gql`
    query FetchProjects($filter: ProjectsFilter!, $sort: [ProjectsSort!], $pagination: Pagination) {
        projects(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            projects {
                ...ProjectFields
            }
        }
    }
    ${ProjectFieldsFragmentDoc}
`;

/**
 * __useFetchProjectsQuery__
 *
 * To run a query within a React component, call `useFetchProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchProjectsQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchProjectsQuery, IAPIFetchProjectsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchProjectsDocument, options);
    return Apollo.useQuery<IAPIFetchProjectsQuery, IAPIFetchProjectsQueryVariables>(
        FetchProjectsDocument,
        options
    );
}
export function useFetchProjectsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IAPIFetchProjectsQuery,
        IAPIFetchProjectsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchProjectsQuery, IAPIFetchProjectsQueryVariables>(
        FetchProjectsDocument,
        options
    );
}
export type FetchProjectsQueryHookResult = ReturnType<typeof useFetchProjectsQuery>;
export type FetchProjectsLazyQueryHookResult = ReturnType<typeof useFetchProjectsLazyQuery>;
export type FetchProjectsQueryResult = Apollo.QueryResult<
    IAPIFetchProjectsQuery,
    IAPIFetchProjectsQueryVariables
>;
export const CreateProjectDocument = gql`
    mutation CreateProject($workspaceId: ID!, $project: ProjectInput) {
        createProject(workspaceId: $workspaceId, project: $project) {
            clientId
            workspaceId
            project {
                ...ProjectFields
            }
        }
    }
    ${ProjectFieldsFragmentDoc}
`;
export type IAPICreateProjectMutationFn = Apollo.MutationFunction<
    IAPICreateProjectMutation,
    IAPICreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useCreateProjectMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPICreateProjectMutation,
        IAPICreateProjectMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPICreateProjectMutation, IAPICreateProjectMutationVariables>(
        CreateProjectDocument,
        options
    );
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<IAPICreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
    IAPICreateProjectMutation,
    IAPICreateProjectMutationVariables
>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: ID!, $project: ProjectInput) {
        updateProject(id: $id, project: $project) {
            clientId
            workspaceId
            project {
                ...ProjectFields
            }
        }
    }
    ${ProjectFieldsFragmentDoc}
`;
export type IAPIUpdateProjectMutationFn = Apollo.MutationFunction<
    IAPIUpdateProjectMutation,
    IAPIUpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useUpdateProjectMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIUpdateProjectMutation,
        IAPIUpdateProjectMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIUpdateProjectMutation, IAPIUpdateProjectMutationVariables>(
        UpdateProjectDocument,
        options
    );
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<IAPIUpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
    IAPIUpdateProjectMutation,
    IAPIUpdateProjectMutationVariables
>;
export const RemoveProjectDocument = gql`
    mutation RemoveProject($id: ID!) {
        removeProject(id: $id, action: DELETE) {
            clientId
            workspaceId
            project {
                ...ProjectFields
            }
        }
    }
    ${ProjectFieldsFragmentDoc}
`;
export type IAPIRemoveProjectMutationFn = Apollo.MutationFunction<
    IAPIRemoveProjectMutation,
    IAPIRemoveProjectMutationVariables
>;

/**
 * __useRemoveProjectMutation__
 *
 * To run a mutation, you first call `useRemoveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectMutation, { data, loading, error }] = useRemoveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProjectMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IAPIRemoveProjectMutation,
        IAPIRemoveProjectMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<IAPIRemoveProjectMutation, IAPIRemoveProjectMutationVariables>(
        RemoveProjectDocument,
        options
    );
}
export type RemoveProjectMutationHookResult = ReturnType<typeof useRemoveProjectMutation>;
export type RemoveProjectMutationResult = Apollo.MutationResult<IAPIRemoveProjectMutation>;
export type RemoveProjectMutationOptions = Apollo.BaseMutationOptions<
    IAPIRemoveProjectMutation,
    IAPIRemoveProjectMutationVariables
>;
export const ProjectCreatedDocument = gql`
    subscription ProjectCreated($workspaceId: ID!) {
        projectCreated(workspaceId: $workspaceId) {
            clientId
            project {
                ...ProjectFields
            }
        }
    }
    ${ProjectFieldsFragmentDoc}
`;

/**
 * __useProjectCreatedSubscription__
 *
 * To run a query within a React component, call `useProjectCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCreatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useProjectCreatedSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        IAPIProjectCreatedSubscription,
        IAPIProjectCreatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(ProjectCreatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIProjectCreatedSubscription,
            IAPIProjectCreatedSubscriptionVariables
        >(ProjectCreatedDocument, options),
        connected,
    };
}
export type ProjectCreatedSubscriptionHookResult = ReturnType<typeof useProjectCreatedSubscription>;
export type ProjectCreatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIProjectCreatedSubscription>;
export const ProjectUpdatedDocument = gql`
    subscription ProjectUpdated($workspaceId: ID) {
        projectUpdated(workspaceId: $workspaceId) {
            clientId
            project {
                ...ProjectFields
            }
        }
    }
    ${ProjectFieldsFragmentDoc}
`;

/**
 * __useProjectUpdatedSubscription__
 *
 * To run a query within a React component, call `useProjectUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectUpdatedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useProjectUpdatedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<
        IAPIProjectUpdatedSubscription,
        IAPIProjectUpdatedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(ProjectUpdatedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIProjectUpdatedSubscription,
            IAPIProjectUpdatedSubscriptionVariables
        >(ProjectUpdatedDocument, options),
        connected,
    };
}
export type ProjectUpdatedSubscriptionHookResult = ReturnType<typeof useProjectUpdatedSubscription>;
export type ProjectUpdatedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIProjectUpdatedSubscription>;
export const ProjectRemovedDocument = gql`
    subscription ProjectRemoved($workspaceId: ID) {
        projectRemoved(workspaceId: $workspaceId) {
            clientId
            project {
                ...ProjectFields
            }
        }
    }
    ${ProjectFieldsFragmentDoc}
`;

/**
 * __useProjectRemovedSubscription__
 *
 * To run a query within a React component, call `useProjectRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRemovedSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useProjectRemovedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<
        IAPIProjectRemovedSubscription,
        IAPIProjectRemovedSubscriptionVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    const connected = useTrackSubscription(ProjectRemovedDocument, options);
    return {
        ...Apollo.useSubscription<
            IAPIProjectRemovedSubscription,
            IAPIProjectRemovedSubscriptionVariables
        >(ProjectRemovedDocument, options),
        connected,
    };
}
export type ProjectRemovedSubscriptionHookResult = ReturnType<typeof useProjectRemovedSubscription>;
export type ProjectRemovedSubscriptionResult =
    Apollo.SubscriptionResult<IAPIProjectRemovedSubscription>;
