/* eslint-disable */
import { useTrackQuery } from '@ab-task/apollo';
import * as Types from '@ab-task/types/graphql.gen';

import { gql } from '@apollo/client';
import { BillFieldsFragmentDoc } from './fragments.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IAPIFetchBillQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type IAPIFetchBillQuery = {
    __typename: 'Query';
    node?:
        | {
              __typename: 'Bill';
              id: string;
              workspaceId: string;
              year: number;
              month: number;
              total: number;
              duration: number;
              requestsCount: number;
              storageUsage: number;
              storagePcplRequestsCount: number;
              storageOtherRequestsCount: number;
              storageDataTransfer: number;
          }
        | { __typename: 'BillPayable' }
        | { __typename: 'Dashboard' }
        | { __typename: 'Document' }
        | { __typename: 'Emoji' }
        | { __typename: 'Epic' }
        | { __typename: 'Group' }
        | { __typename: 'Message' }
        | { __typename: 'Project' }
        | { __typename: 'Role' }
        | { __typename: 'Talk' }
        | { __typename: 'Task' }
        | { __typename: 'Transaction' }
        | { __typename: 'Unread' }
        | { __typename: 'User' }
        | { __typename: 'Workspace' }
        | null;
};

export type IAPIFetchBillsQueryVariables = Types.Exact<{
    filter: Types.IAPIBillsFilter;
    sort?: Types.InputMaybe<Array<Types.IAPIBillsSort> | Types.IAPIBillsSort>;
    pagination?: Types.InputMaybe<Types.IAPIPagination>;
}>;

export type IAPIFetchBillsQuery = {
    __typename: 'Query';
    bills: {
        __typename: 'Bills';
        allIds?: Array<number> | null;
        bills: Array<{
            __typename: 'Bill';
            id: string;
            workspaceId: string;
            year: number;
            month: number;
            total: number;
            duration: number;
            requestsCount: number;
            storageUsage: number;
            storagePcplRequestsCount: number;
            storageOtherRequestsCount: number;
            storageDataTransfer: number;
        }>;
    };
};

export const FetchBillDocument = gql`
    query FetchBill($id: ID!) {
        node(id: $id) {
            ...BillFields
        }
    }
    ${BillFieldsFragmentDoc}
`;

/**
 * __useFetchBillQuery__
 *
 * To run a query within a React component, call `useFetchBillQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBillQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchBillQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchBillQuery, IAPIFetchBillQueryVariables> &
        ({ variables: IAPIFetchBillQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchBillDocument, options);
    return Apollo.useQuery<IAPIFetchBillQuery, IAPIFetchBillQueryVariables>(
        FetchBillDocument,
        options
    );
}
export function useFetchBillLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchBillQuery, IAPIFetchBillQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchBillQuery, IAPIFetchBillQueryVariables>(
        FetchBillDocument,
        options
    );
}
export function useFetchBillSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchBillQuery, IAPIFetchBillQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchBillQuery, IAPIFetchBillQueryVariables>(
        FetchBillDocument,
        options
    );
}
export type FetchBillQueryHookResult = ReturnType<typeof useFetchBillQuery>;
export type FetchBillLazyQueryHookResult = ReturnType<typeof useFetchBillLazyQuery>;
export type FetchBillSuspenseQueryHookResult = ReturnType<typeof useFetchBillSuspenseQuery>;
export type FetchBillQueryResult = Apollo.QueryResult<
    IAPIFetchBillQuery,
    IAPIFetchBillQueryVariables
>;
export const FetchBillsDocument = gql`
    query FetchBills($filter: BillsFilter!, $sort: [BillsSort!], $pagination: Pagination) {
        bills(filter: $filter, sort: $sort, pagination: $pagination) {
            allIds
            bills {
                ...BillFields
            }
        }
    }
    ${BillFieldsFragmentDoc}
`;

/**
 * __useFetchBillsQuery__
 *
 * To run a query within a React component, call `useFetchBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBillsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchBillsQuery(
    baseOptions: Apollo.QueryHookOptions<IAPIFetchBillsQuery, IAPIFetchBillsQueryVariables> &
        ({ variables: IAPIFetchBillsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    useTrackQuery(FetchBillsDocument, options);
    return Apollo.useQuery<IAPIFetchBillsQuery, IAPIFetchBillsQueryVariables>(
        FetchBillsDocument,
        options
    );
}
export function useFetchBillsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IAPIFetchBillsQuery, IAPIFetchBillsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<IAPIFetchBillsQuery, IAPIFetchBillsQueryVariables>(
        FetchBillsDocument,
        options
    );
}
export function useFetchBillsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<IAPIFetchBillsQuery, IAPIFetchBillsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<IAPIFetchBillsQuery, IAPIFetchBillsQueryVariables>(
        FetchBillsDocument,
        options
    );
}
export type FetchBillsQueryHookResult = ReturnType<typeof useFetchBillsQuery>;
export type FetchBillsLazyQueryHookResult = ReturnType<typeof useFetchBillsLazyQuery>;
export type FetchBillsSuspenseQueryHookResult = ReturnType<typeof useFetchBillsSuspenseQuery>;
export type FetchBillsQueryResult = Apollo.QueryResult<
    IAPIFetchBillsQuery,
    IAPIFetchBillsQueryVariables
>;
